import Search from 'assets/icons/Search';
import useTranslation from 'common/hooks/useTranslation';
import { cn } from 'common/utils';
import React from 'react';

const KanbanColumnEmptyState = () => {
  const { t } = useTranslation('offers\\details');

  return (
    <div
      className={cn(
        'flex flex-col w-ful h-full p-4',
        'justify-center items-center gap-4',
        'border-2 border-dashed border-bright-grey rounded',
      )}
    >
      <Search size='24px' />
      <div className='text-sm text-cool-grey text-center'>
        <h1 className='font-semibold'>{t('kanban.empty-column.title')}</h1>
        <p>{t('kanban.empty-column.description')}</p>
      </div>
    </div>
  );
};

export default KanbanColumnEmptyState;
