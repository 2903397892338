import { cn } from 'common/utils';
import React from 'react';

const PeopleTooltip = ({ size = 24, color = '#8D91A0', onClick }) => {
  return (
    <svg
      className={cn({ 'cursor-pointer': onClick })}
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 24 25'
      fill='none'
    >
      <path
        d='M22 4.62695H2V19.627H9.5L12 22.127L14.5 19.627H22V4.62695Z'
        stroke={color}
        strokeWidth='1.6'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12 12.627C13.3807 12.627 14.5 11.5077 14.5 10.127C14.5 8.74624 13.3807 7.62695 12 7.62695C10.6193 7.62695 9.5 8.74624 9.5 10.127C9.5 11.5077 10.6193 12.627 12 12.627Z'
        stroke={color}
        strokeWidth='1.6'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M16.5 16.627C16.5 14.4178 14.4853 12.627 12 12.627C9.5147 12.627 7.5 14.4178 7.5 16.627'
        stroke={color}
        strokeWidth='1.6'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default PeopleTooltip;
