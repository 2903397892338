import { cn } from 'common/utils';
import React from 'react';

const Check = ({ size = 24, color = '#8D91A0', onClick }) => {
  return (
    <svg
      className={cn({ 'cursor-pointer': onClick })}
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 17 17'
      fill='none'
      onClick={onClick}
    >
      <path
        d='M8.5 0.5C4.08172 0.5 0.5 4.08172 0.5 8.5C0.5 12.9183 4.08172 16.5 8.5 16.5C12.9183 16.5 16.5 12.9183 16.5 8.5C16.5 4.08172 12.9183 0.5 8.5 0.5ZM13.833 5.04L7.509 13.17C7.4031 13.3058 7.24393 13.3896 7.072 13.4H7.035C6.87558 13.4001 6.72266 13.3368 6.61 13.224L2.717 9.324C2.60433 9.21146 2.54103 9.05874 2.54103 8.8995C2.54103 8.74025 2.60433 8.58754 2.717 8.475L3.38 7.812C3.61425 7.57804 3.99375 7.57804 4.228 7.812L6.9 10.491L12.156 3.737C12.3611 3.47646 12.7386 3.43167 12.999 3.637L13.727 4.203C13.9856 4.40586 14.0329 4.77907 13.833 5.04Z'
        fill={color}
      />
    </svg>
  );
};

export default Check;
