import { cn } from 'common/utils';
import React from 'react';

const Add = ({ size = 24, color = '#8D91A0', onClick }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 16 17'
      fill='none'
      className={cn({ 'cursor-pointer': onClick })}
    >
      <g clipPath='url(#clip0_2244_47690)'>
        <path
          d='M8 5.83325V11.1666'
          stroke={color}
          strokeWidth='1.6'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M5.33398 8.5H10.6673'
          stroke={color}
          strokeWidth='1.6'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_2244_47690'>
          <rect
            width={size}
            height={size}
            fill='white'
            transform='translate(0 0.5)'
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Add;
