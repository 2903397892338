import { Dialog, Transition } from '@headlessui/react';
import Right from 'assets/icons/Right';
import Button from 'common/components/Button';
import LabelledInput from 'common/components/LabelledInput';
import useTranslation from 'common/hooks/useTranslation';
import { cn } from 'common/utils';
import React, { Fragment, useState } from 'react';

const NewTestModal = ({ isOpen, setIsOpen, tests, setTests }) => {
  const { t } = useTranslation('offers\\new');
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');

  const closeModal = () => {
    setIsOpen(false);
    setName('');
    setDescription('');
  };

  const addNewTest = async () => {
    setTests([
      ...tests,
      { name: name, description: description, source: 'manual' },
    ]);
    closeModal();
  };

  return (
    <Fragment>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as='div' className='relative z-50' onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='fixed inset-0 bg-black bg-opacity-25' />
          </Transition.Child>
          <div className='fixed inset-0 overflow-y-auto'>
            <div className='flex min-h-full items-center justify-center p-4 text-center'>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 scale-95'
                enterTo='opacity-100 scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 scale-100'
                leaveTo='opacity-0 scale-95'
              >
                <Dialog.Panel
                  className={cn(
                    'flex flex-col w-full max-w-md transform overflow-hidden',
                    'rounded bg-white p-10 text-left gap-6',
                    'align-middle shadow-xl transition-all relative',
                  )}
                >
                  <Dialog.Title
                    as='div'
                    className='flex flex-col w-full items-center gap-8'
                  >
                    <h1 className=' text-2xl font-semibold text-dark-charcoal'>
                      {t('modals.newTest.title')}
                    </h1>
                  </Dialog.Title>
                  <div className='flex flex-col gap-2'>
                    <LabelledInput
                      type='text'
                      label={t('name')}
                      name='name'
                      placeholder={t('name')}
                      onChange={(event) => setName(event.target.value)}
                    />
                  </div>
                  <div className='flex flex-col gap-2'>
                    <LabelledInput
                      type='textarea'
                      label={t('description')}
                      name='description'
                      placeholder={t('description')}
                      onChange={(event) => setDescription(event.target.value)}
                    />
                  </div>
                  <div className='w-full flex items-center justify-center gap-2'>
                    <Button variant='secondary' onClick={closeModal}>
                      {t('modals.newTest.buttons.cancel')}
                    </Button>
                    <Button
                      variant='primary'
                      disabled={name === ''}
                      onClick={addNewTest}
                    >
                      {t('modals.newTest.buttons.add')}
                      <Right size='16px' color='#FFFFFF' />
                    </Button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </Fragment>
  );
};

export default NewTestModal;
