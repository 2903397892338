import { cn } from 'common/utils';
import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';

import Header from './components/Header';
import Sidebar from './components/Sidebar';

const Layout = () => {
  const [collapsed, setCollapsed] = useState(false);

  return (
    <div
      className={cn({
        'grid h-screen': true,
        'grid-cols-sidebar': !collapsed,
        'grid-cols-sidebar-collapsed': collapsed,
        'transition-[grid-template-columns] duration-300 ': true,
      })}
    >
      <Sidebar
        isCollapsed={collapsed}
        setCollapsed={() => setCollapsed((prev) => !prev)}
      />
      <div className='flex flex-col w-full min-h-full bg-white overflow-hidden'>
        <Header />
        <div className='overflow-auto h-full'>
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Layout;
