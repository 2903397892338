import useNavigate from 'common/hooks/useNavigate';
import { cn } from 'common/utils';
import React from 'react';

const SidebarButton = ({
  icon,
  link,
  label,
  isSelected,
  isCollapsed,
  external,
}) => {
  const navigate = useNavigate();

  const commonStyles = cn(
    'h-14 w-full flex flex-row gap-2 items-center px-5',
    'hover:bg-ghost-white cursor-pointer',
    {
      'text-vibrant-orange': isSelected,
      'items-center justify-center': isCollapsed,
    },
  );

  return (
    <li className='w-full'>
      {external ? (
        <a
          href={link}
          target='_blank'
          rel='noreferrer'
          className={commonStyles}
        >
          {icon}
          {!isCollapsed && <h1 className='font-semibold'>{label}</h1>}
        </a>
      ) : (
        <button className={commonStyles} onClick={() => navigate(link)}>
          {icon}
          {!isCollapsed && <h1 className='font-semibold'>{label}</h1>}
        </button>
      )}
    </li>
  );
};

export default SidebarButton;
