import Right from 'assets/icons/Right';
import Button from 'common/components/Button';
import LabelledInput from 'common/components/LabelledInput';
import Loader from 'common/components/Loader';
import { useToast } from 'common/hooks/useToast';
import useTranslation from 'common/hooks/useTranslation';
import { selectCurrentCompany } from 'common/slices/auth.slice';
import {
  useGetCompanyDetailQuery,
  useUpdateCompanyMutation,
} from 'common/slices/companyApi.slice';
import { Form, Formik } from 'formik';
import React from 'react';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';

const DescriptionSettingsForm = () => {
  const toast = useToast();

  const { t } = useTranslation('settings\\index');

  const currentCompany = useSelector(selectCurrentCompany);

  const { data: company, isLoading: isLoadingCompany } =
    useGetCompanyDetailQuery({ slug: currentCompany.slug });

  const [updateCompany, { isLoading: isUpdatingCompany }] =
    useUpdateCompanyMutation();

  const initialValues = {
    about_us: company?.about_us ?? '',
    mission: company?.mission ?? '',
    bio: company?.bio ?? '',
  };

  const validationSchema = Yup.object({
    about_us: Yup.string().notRequired(),
    mission: Yup.string().notRequired(),
    bio: Yup.string().notRequired(),
  });

  const handleSubmit = async (values) => {
    const data = {
      about_us: values.about_us,
      mission: values.mission,
      bio: values.bio,
    };

    try {
      await updateCompany({
        companyId: currentCompany.id,
        data: data,
      });

      toast.newToast('positive', t('toasts.success'));
    } catch (err) {
      switch (err?.status) {
        case 403:
          toast.newToast('negative', t('toasts.forbidden'));
          break;
        default:
          toast.newToast('negative', t('toasts.something-wrong'));
      }
    }
  };

  return (
    <>
      {isLoadingCompany ? (
        <Loader />
      ) : (
        <>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            <Form
              id='company-data-form'
              className='flex flex-col w-full flex-grow gap-10 mt-6 relative'
            >
              <div className='w-1/2 mt-1.5 gap-6 flex flex-col'>
                <LabelledInput
                  name='about_us'
                  label={t('aboutUs')}
                  type='textarea'
                  height='h-[100px]'
                />
                <LabelledInput
                  name='mission'
                  label={t('mission')}
                  type='textarea'
                  height='h-[100px]'
                />
                <LabelledInput
                  name='bio'
                  label={t('bio')}
                  type='textarea'
                  height='h-[100px]'
                />
              </div>

              <div className='w-1/4'>
                <Button
                  type='submit'
                  variant='primary'
                  form='company-data-form'
                >
                  {t('save')}
                  <Right size='16px' color='#FFFFFF' />
                </Button>
              </div>
            </Form>
          </Formik>
          {isUpdatingCompany && <Loader />}
        </>
      )}
    </>
  );
};

export default DescriptionSettingsForm;
