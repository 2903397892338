import { cn } from 'common/utils';
import React from 'react';

const Inbox = ({ size = 24, color = '#8D91A0', onClick }) => {
  return (
    <svg
      className={cn({ 'cursor-pointer': onClick })}
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 25 25'
      fill='none'
    >
      <g clipPath='url(#clip0_2217_90351)'>
        <path
          d='M24.6484 0.573822H0.648438V24.5738H24.6484V0.573822Z'
          fill='white'
          fillOpacity='0.01'
        />
        <path
          d='M2.64844 15.5738L5.14844 3.57382H20.1484L22.6484 15.5738'
          stroke={color}
          strokeWidth='1.6'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M2.64844 15.5738H8.10298L9.01207 18.5738H16.2848L17.1939 15.5738H22.6484V22.0738H2.64844V15.5738Z'
          stroke={color}
          strokeWidth='1.6'
          strokeLinejoin='round'
        />
        <path
          d='M10.1484 7.57382H15.1484'
          stroke={color}
          strokeWidth='1.6'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M8.64844 11.5738H16.6484'
          stroke={color}
          strokeWidth='1.6'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_2217_90351'>
          <rect
            width={size}
            height={size}
            fill='white'
            transform='translate(0.648438 0.573822)'
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Inbox;
