import { cn } from 'common/utils';
import React from 'react';

const Pause = ({ size = 24, color = '#8D91A0', onClick }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='white'
      width={size}
      height={size}
      viewBox='0 0 24 24'
      className={cn({ 'cursor-pointer': onClick })}
    >
      <path d='M24 0H0V24H24V0Z' fill={color} fillOpacity='0.01' />
      <path
        d='M12 22C17.5229 22 22 17.5229 22 12C22 6.47715 17.5229 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5229 6.47715 22 12 22Z'
        stroke='#084D6E'
        strokeWidth='1.6'
        strokeLinejoin='round'
      />
      <path
        d='M9.5 9V15'
        stroke='#084D6E'
        strokeWidth='1.6'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M14.5 9V15'
        stroke='#084D6E'
        strokeWidth='1.6'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default Pause;
