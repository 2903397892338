import React from 'react';

const Reject = ({ size = 24, color = '#8D91A0', onClick }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
    >
      <path
        d='M23.7551 0H0.244873V23.5102H23.7551V0Z'
        fill='white'
        fillOpacity='0.01'
      />
      <path
        d='M23.7551 0.489746H0.244873V24H23.7551V0.489746Z'
        fill='white'
        fillOpacity='0.01'
      />
      <path
        d='M3.18372 4.53342L12.0043 1.95923L20.8164 4.53342V9.81247C20.8164 15.3611 17.2654 20.2871 12.0013 22.0411C6.73576 20.2871 3.18372 15.36 3.18372 9.81002V4.53342Z'
        stroke='#084D6E'
        strokeWidth='1.95918'
        strokeMiterlimit='0'
        strokeLinejoin='round'
      />
      <path
        d='M14.6939 9.01611L9.15247 14.5575'
        stroke='#084D6E'
        strokeWidth='1.95918'
        strokeMiterlimit='0'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9.15247 9.01611L14.6939 14.5575'
        stroke='#084D6E'
        strokeWidth='1.95918'
        strokeMiterlimit='0'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default Reject;
