import React from 'react';

const Favorite = ({ color, ...props }) => (
  <svg
    width='14'
    height='17'
    viewBox='0 0 14 17'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M1.375 0.945312H12.625V15.9453L7 11.981L1.375 15.9453V0.945312Z'
      fill={color}
      stroke={color}
      strokeWidth='1.6'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export default Favorite;
