import Divider from 'common/components/Divider';
import React from 'react';
import { ShowFeature } from 'split';

const OfferDetailsSkeleton = () => {
  return (
    <div className='animate-pulse flex flex-col'>
      <div className='flex items-center justify-between mb-4'>
        <div className='h-[36px] w-[350px] bg-bright-grey rounded' />
        <div className='flex items-center gap-6'>
          <div className='h-[20px] w-[250px] bg-bright-grey rounded' />
          <div className='h-[24px] w-20 bg-bright-grey rounded' />
        </div>
      </div>
      <ShowFeature featureFlag='SHOW_OFFER_ANALYSIS'>
        <div className='h-24 w-full bg-bright-grey rounded mb-5' />
      </ShowFeature>
      <div className='flex items-center justify-between mb-2 z-10'>
        <ul className='flex items-center gap-4'>
          <div className='w-20 h-[24px] bg-bright-grey rounded' />
          <div className='w-20 h-[24px] bg-bright-grey rounded' />
          <div className='w-20 h-[24px] bg-bright-grey rounded' />
          <div className='w-20 h-[24px] bg-bright-grey rounded' />
        </ul>
        <div className='flex items-center gap-4'>
          <div className='w-[38px] h-[38px] bg-bright-grey rounded' />
          <div className='w-[38px] h-[38px] bg-bright-grey rounded' />
          <div className='w-[38px] h-[38px] bg-bright-grey rounded' />
          <div className='w-[38px] h-[38px] bg-bright-grey rounded' />
        </div>
      </div>
      <Divider />
    </div>
  );
};

export default OfferDetailsSkeleton;
