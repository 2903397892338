import { useToast } from 'common/hooks/useToast';
import useTranslation from 'common/hooks/useTranslation';
import {
  useGetCurrentUserSettingsQuery,
  useUpdateCurrentUserSettingsMutation,
} from 'common/slices/userApi.slice';
import { isEmpty, isNull } from 'lodash';
import React, { createContext, useEffect } from 'react';

export const SettingsContext = createContext();

export const SettingsContextProvider = ({ children }) => {
  const toast = useToast();
  const { t, i18n } = useTranslation('settings\\index');

  const { data: userSettings, isLoading: isLoadingUserSettings } =
    useGetCurrentUserSettingsQuery();

  const [updateCurrentUserSettings, { isLoading: isUpdatingUserSettings }] =
    useUpdateCurrentUserSettingsMutation();

  const checkForLanguageSetting = async () => {
    if (!isEmpty(userSettings)) {
      const browserLanguage = localStorage.getItem('i18nextLng');
      const languageSetting = userSettings.find(
        (setting) => setting.name === 'language',
      );

      if (languageSetting.value === null && !isNull(browserLanguage)) {
        await updateCurrentUserSettings([
          { name: 'language', value: browserLanguage },
        ]);
      }

      if (languageSetting.value !== browserLanguage) {
        await i18n.changeLanguage(languageSetting.value);

        toast.newToast('positive', t('toasts.success'));
      }
    }
  };

  useEffect(() => {
    checkForLanguageSetting();
  }, [userSettings]);

  const loadingUserSettings = isLoadingUserSettings || isUpdatingUserSettings;

  return (
    <SettingsContext.Provider
      value={{
        userSettings,
        isLoadingUserSettings,
        isUpdatingUserSettings,
        loadingUserSettings,
        updateCurrentUserSettings,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};
