import { cn } from 'common/utils';
import React from 'react';

const Settings = ({ size = 24, color = '#8D91A0', onClick }) => {
  return (
    <svg
      className={cn({ 'cursor-pointer': onClick })}
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 25 25'
      fill='none'
    >
      <g clipPath='url(#clip0_2217_90375)'>
        <path
          d='M9.79034 21.6735C8.11479 21.1746 6.62334 20.2485 5.44237 19.0213C5.88294 18.4992 6.14844 17.8245 6.14844 17.0879C6.14844 15.431 4.80529 14.0879 3.14844 14.0879C3.04821 14.0879 2.94913 14.0928 2.85143 14.1024C2.71833 13.4517 2.64844 12.7779 2.64844 12.0879C2.64844 11.0426 2.80882 10.0347 3.10633 9.08759C3.12034 9.08779 3.13438 9.08789 3.14844 9.08789C4.80529 9.08789 6.14844 7.74474 6.14844 6.08789C6.14844 5.61224 6.03774 5.16244 5.84074 4.76289C6.99719 3.68758 8.40869 2.88284 9.97449 2.4494C10.4706 3.42193 11.4818 4.0879 12.6484 4.0879C13.8151 4.0879 14.8262 3.42193 15.3224 2.4494C16.8882 2.88284 18.2997 3.68758 19.4561 4.76289C19.2591 5.16244 19.1484 5.61224 19.1484 6.08789C19.1484 7.74474 20.4916 9.08789 22.1484 9.08789C22.1625 9.08789 22.1765 9.08779 22.1905 9.08759C22.488 10.0347 22.6484 11.0426 22.6484 12.0879C22.6484 12.7779 22.5785 13.4517 22.4454 14.1024C22.3477 14.0928 22.2487 14.0879 22.1484 14.0879C20.4916 14.0879 19.1484 15.431 19.1484 17.0879C19.1484 17.8245 19.4139 18.4992 19.8545 19.0213C18.6735 20.2485 17.1821 21.1746 15.5065 21.6735C15.1198 20.4638 13.9864 19.5879 12.6484 19.5879C11.3104 19.5879 10.177 20.4638 9.79034 21.6735Z'
          stroke={color}
          strokeWidth='1.6'
          strokeLinejoin='round'
        />
        <path
          d='M12.6484 15.5879C14.5814 15.5879 16.1484 14.0209 16.1484 12.0879C16.1484 10.1549 14.5814 8.58789 12.6484 8.58789C10.7154 8.58789 9.14844 10.1549 9.14844 12.0879C9.14844 14.0209 10.7154 15.5879 12.6484 15.5879Z'
          stroke={color}
          strokeWidth='1.6'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_2217_90375'>
          <rect
            width={size}
            height={size}
            fill='white'
            transform='translate(0.648438 0.0878906)'
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Settings;
