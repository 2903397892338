import {
  addToast,
  removeToast,
  selectCurrentToasts,
} from 'common/slices/toast.slice';
import React, { createContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ToastContainer from './ToastContainer';

export const ToastContext = createContext();

export const ToastContextProvider = ({ children }) => {
  const dispatch = useDispatch();
  const currentToasts = useSelector(selectCurrentToasts);

  const newToast = (type, message) => {
    const id = Math.floor(Math.random() * 10000000);
    dispatch(addToast({ id, type, message }));
  };

  const closeToast = (id, ref) => {
    ref?.current?.classList.add('animate-toast-out');

    ref?.current?.addEventListener('animationend', () => {
      dispatch(removeToast(id));
    });
  };

  return (
    <ToastContext.Provider value={{ newToast, closeToast }}>
      <ToastContainer toasts={currentToasts} />
      {children}
    </ToastContext.Provider>
  );
};
