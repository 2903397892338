import { cn } from 'common/utils';
import React from 'react';

const Facebook = ({ size = 24, color = '#8D91A0', onClick }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 25 25'
      fill='none'
      className={cn({ 'cursor-pointer': onClick })}
    >
      <path
        d='M19.125 0.0942383H6.375C3.2684 0.0942383 0.75 2.61264 0.75 5.71924V18.4692C0.75 21.5758 3.2684 24.0942 6.375 24.0942H19.125C22.2316 24.0942 24.75 21.5758 24.75 18.4692V5.71924C24.75 2.61264 22.2316 0.0942383 19.125 0.0942383Z'
        fill='white'
      />
      <path
        d='M19.125 0.0942383H6.375C3.2684 0.0942383 0.75 2.61264 0.75 5.71924V18.4692C0.75 21.5758 3.2684 24.0942 6.375 24.0942H19.125C22.2316 24.0942 24.75 21.5758 24.75 18.4692V5.71924C24.75 2.61264 22.2316 0.0942383 19.125 0.0942383Z'
        fill={color}
      />
      <path
        d='M19.4599 8.66402C19.4702 8.8128 19.4702 8.96158 19.4702 9.11176C19.4702 13.6865 15.9876 18.9625 9.61941 18.9625V18.9597C7.73814 18.9625 5.89585 18.4237 4.3125 17.4077C4.58606 17.4406 4.86094 17.457 5.13656 17.4577C6.69578 17.4589 8.21014 16.9359 9.43631 15.9728C8.71387 15.9591 8.01376 15.72 7.43385 15.289C6.85394 14.8579 6.42323 14.2564 6.20194 13.5685C6.72063 13.6686 7.25537 13.6482 7.76494 13.5089C6.14981 13.1826 4.98778 11.7635 4.98778 10.1154V10.0715C5.46927 10.3397 6.00814 10.4883 6.55903 10.5048C5.03784 9.48808 4.56891 7.4643 5.48747 5.88198C6.35665 6.95158 7.44112 7.82636 8.67042 8.44952C9.89972 9.07267 11.2464 9.43026 12.6229 9.49905C12.4856 8.90827 12.5059 8.29182 12.6817 7.71133C12.8574 7.13085 13.1825 6.60669 13.6244 6.19127C15.0188 4.88045 17.2119 4.94767 18.5228 6.34136C19.2981 6.18829 20.0416 5.90405 20.7213 5.50089C20.4628 6.30251 19.922 6.98296 19.1993 7.41564C19.8857 7.33478 20.5559 7.15107 21.1875 6.87067C20.7229 7.56648 20.1378 8.17372 19.4599 8.66402Z'
        fill='white'
      />
    </svg>
  );
};

export default Facebook;
