import Attention from 'assets/icons/Attention';
import Right from 'assets/icons/Right';
import Trash from 'assets/icons/Trash';
import Avatar from 'common/components/Avatar';
import Button from 'common/components/Button';
import { useToast } from 'common/hooks/useToast';
import useTranslation from 'common/hooks/useTranslation';
import { selectUser } from 'common/slices/auth.slice';
import {
  useDeleteSubmissionNoteMutation,
  useUpdateNotesMutation,
} from 'common/slices/submissionApi.slice';
import { cn, getDateLocale } from 'common/utils';
import { formatRelative } from 'date-fns';
import { Field, Form, Formik } from 'formik';
import { isEmpty } from 'lodash';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import TipTapEditor from '../TipTapEditor';

const sanitizeHTML = (html) => {
  const tempDiv = document.createElement('div');
  tempDiv.innerHTML = html;

  const unwantedTags = ['script', 'iframe'];
  unwantedTags.forEach((tag) => {
    const elements = tempDiv.getElementsByTagName(tag);
    while (elements.length > 0) {
      elements[0].parentNode.removeChild(elements[0]);
    }
  });

  return tempDiv.innerHTML;
};

const truncateContent = (html, wordLimit) => {
  const words = html.split(' ');
  if (words.length > wordLimit) {
    const truncated = words.slice(0, wordLimit).join(' ');
    return { truncated, isTruncated: true };
  }
  return { truncated: html, isTruncated: false };
};

const CandidateNotes = ({ submission, setSubmission }) => {
  const toast = useToast();
  const { t, i18n } = useTranslation('offers\\details');
  const [isLoading, setIsLoading] = useState(false);
  const [expandedNotes, setExpandedNotes] = useState({});

  const initialValues = {
    content: '',
  };

  const [updateSubmission] = useUpdateNotesMutation();
  const [deleteSubmissionNote] = useDeleteSubmissionNoteMutation();

  const handleSubmit = async (values, { resetForm }) => {
    setIsLoading(true);

    const data = {
      notes: values.content,
    };

    try {
      const response = await updateSubmission({
        submissionId: submission?.id,
        data: data,
      }).unwrap();

      setSubmission((prevSubmission) => ({
        ...prevSubmission,
        notes: response.notes,
      }));

      resetForm();
      toast.newToast('positive', t('tabNotes.toastSuccessNewNoteAdded'));
    } catch (err) {
      switch (err?.status) {
        case 403:
          toast.newToast('negative', t('toasts.forbidden'));
          break;
        default:
          toast.newToast('negative', t('toasts.something-wrong'));
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleRemove = async (id) => {
    try {
      const response = await deleteSubmissionNote({
        submissionId: submission?.id,
        noteId: id,
      }).unwrap();

      setSubmission((prevSubmission) => ({
        ...prevSubmission,
        notes: response.notes,
      }));

      toast.newToast('positive', t('tabNotes.toastSuccessNoteDeleted'));
    } catch (err) {
      toast.newToast('negative', t('toasts.something-wrong'));
    }
  };

  const toggleReadMore = (index) => {
    setExpandedNotes((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const renderHTMLContent = (html, isExpanded, wordLimit) => {
    const sanitizedContent = sanitizeHTML(html);
    const { truncated, isTruncated } = truncateContent(
      sanitizedContent,
      wordLimit,
    );
    return {
      __html: isExpanded || !isTruncated ? sanitizedContent : `${truncated}...`,
    };
  };

  const { full_name } = useSelector(selectUser);

  return (
    <>
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        <Form
          id='company-settings-form'
          className='flex flex-col w-full flex-grow gap-5 relative bg-white p-4 rounded-md shadow-md'
        >
          <div className='flex flex-col pt-3'>
            <label
              htmlFor='content'
              className={cn(
                'text-xs font-semibold uppercase text-weldon-blue mb-2',
              )}
            >
              {t('tabNotes.newNote')}
            </label>
            <Field
              name='content'
              className={'p-2'}
              component={(props) => <TipTapEditor {...props} />}
            />
          </div>
          <div className='flex items-end justify-end mt-4'>
            <Button
              type='submit'
              variant='primary'
              form='company-settings-form'
              className='ml-auto'
              disabled={isLoading}
            >
              {t('tabNotes.buttonAddNote')}
              <Right size='16px' color='#FFFFFF' />
            </Button>
          </div>
        </Form>
      </Formik>

      <div className='flex flex-col w-full flex-grow gap-5 mt-6 bg-ghost-white p-4 rounded-md shadow-md'>
        <label className='text-xs font-semibold uppercase text-weldon-blue mb-2'>
          {t('tabNotes.notes')}
        </label>
        {isEmpty(submission.notes) ? (
          <div className='w-full h-full bg-white p-4 flex flex-col items-center justify-center rounded-md shadow-md'>
            <Attention size='64px' color='#F16347' />
            <h1 className='text-dark-charcoal text-xl font-semibold mt-4'>
              {t('tabNotes.noNotes')}
            </h1>
          </div>
        ) : (
          <div className='w-full h-full bg-white p-4 flex flex-col divide-y rounded-md shadow-md'>
            {[...submission.notes].reverse().map((entry, index) => {
              const isExpanded = expandedNotes[index];
              const content = renderHTMLContent(entry.content, isExpanded, 50);
              const isTruncated = entry.content.split(' ').length > 50;

              return (
                <div
                  key={entry.id || index}
                  className='flex flex-col w-full gap-4 px-2 py-4 break-words'
                >
                  <div className='text-sm break-words prose'>
                    <span dangerouslySetInnerHTML={content} />
                    {isTruncated && (
                      <button
                        className='text-vibrant-orange text-md font-bold'
                        onClick={() => toggleReadMore(index)}
                      >
                        {isExpanded ? t('read-less') : t('read-more')}
                      </button>
                    )}
                  </div>
                  <div className='flex justify-between items-center'>
                    <div className='flex items-center gap-2'>
                      <Avatar name={full_name} />
                      <div className='flex flex-col'>
                        <h4 className='text-cool-grey text-xs'>
                          {entry.user.full_name}
                        </h4>
                        <p className='text-xs text-cool-grey'>
                          {formatRelative(
                            new Date(entry.updated_at),
                            new Date(),
                            {
                              locale: getDateLocale(i18n.language),
                              addSuffix: true,
                            },
                          )}
                        </p>
                      </div>
                    </div>
                    <Trash
                      size='24px'
                      color='#7A7A7A'
                      onClick={() => handleRemove(entry.id)}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </>
  );
};

export default CandidateNotes;
