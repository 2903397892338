import { Listbox, Transition } from '@headlessui/react';
import Down from 'assets/icons/Down';
import Up from 'assets/icons/Up';
import useTranslation from 'common/hooks/useTranslation';
import { cn } from 'common/utils';
import { useField } from 'formik';
import React, { Fragment } from 'react';

import Avatar from './Avatar';

const Select = ({
  name,
  label,
  options,
  isWithAvatar,
  translationPath,
  isDisabled,
}) => {
  const [field] = useField({ name });
  const { t } = useTranslation(translationPath);
  return (
    <Listbox
      value={t(`tones.${field.value}`)}
      onChange={(value) => {
        field.onChange({ target: { value, name } });
      }}
      name='language'
      disabled={isDisabled}
    >
      {({ open }) => (
        <div className='relative'>
          <Listbox.Label className='uppercase text-xs font-semibold text-weldon-blue'>
            {label}
          </Listbox.Label>
          <Listbox.Button
            className={cn(
              'rounded-md px-4 py-2 w-full flex justify-between items-center mt-1.5',
              'text-dark-blue border border-dark-blue outline-dark-blue',
              'disabled:border-soft-blue disabled:text-soft-blue disabled:bg-white disabled:cursor-not-allowed',
            )}
          >
            <div className='flex items-center gap-2.5 py-1'>
              {isWithAvatar && <Avatar size='small' name={field.value.label} />}
              {t(`${field.name}.${field.value.label}`)}
            </div>
            {open ? <Up /> : <Down />}
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave='transition ease-in duration-100'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <Listbox.Options
              className={cn(
                'mt-1 w-full rounded-md bg-white text-base shadow-lg',
                'ring-1 ring-black ring-opacity-5 focus:outline-none',
                'max-h-[180px] absolute',
                'overflow-y-auto scrollbar scrollbar-w-1',
                'scrollbar-thumb-vibrant-orange scrollbar-thumb-rounded',
                'scrollbar-track-rounded z-50',
              )}
            >
              {options.map((option, index) => (
                <Listbox.Option
                  key={index}
                  className={({ active }) =>
                    cn(
                      'flex w-full items-center',
                      'px-4 py-4 text-sm gap-2.5',
                      'select-none rounded-t-md',
                      {
                        'bg-vibrant-orange bg-opacity-10': active,
                      },
                    )
                  }
                  value={option}
                >
                  <div className='flex items-center gap-2.5 py-1'>
                    {isWithAvatar && (
                      <Avatar size='small' name={option.label} />
                    )}
                    {t(`${field.name}.${option.label}`)}
                  </div>
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      )}
    </Listbox>
  );
};

export default Select;
