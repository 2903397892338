import { cn } from 'common/utils';
import React from 'react';

const Fireworks = ({ size = 24, color = '#8D91A0', onClick }) => {
  return (
    <svg
      className={cn({ 'cursor-pointer': onClick })}
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 24 25'
      fill='none'
    >
      <path
        d='M24 0.160156H0V24.1602H24V0.160156Z'
        fill='white'
        fillOpacity='0.01'
      />
      <path
        d='M3 21.16L7.33705 8.79199L15.5 17.1792L3 21.16Z'
        stroke={color}
        strokeWidth='1.6'
        strokeLinejoin='round'
      />
      <path
        d='M11.5 9.66016L14 7.16016C15.3333 5.82682 15.5 4.66016 14.5 3.66016'
        stroke={color}
        strokeWidth='1.6'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M14.5 12.6602L17 10.1602C18.6667 8.49349 20.3333 8.49349 22 10.1602'
        stroke={color}
        strokeWidth='1.6'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10 3.66016C10.5523 3.66016 11 3.21244 11 2.66016C11 2.10787 10.5523 1.66016 10 1.66016C9.44772 1.66016 9 2.10787 9 2.66016C9 3.21244 9.44772 3.66016 10 3.66016Z'
        fill={color}
      />
      <path
        d='M21 3.16016C21.5523 3.16016 22 2.71244 22 2.16016C22 1.60787 21.5523 1.16016 21 1.16016C20.4477 1.16016 20 1.60787 20 2.16016C20 2.71244 20.4477 3.16016 21 3.16016Z'
        fill={color}
      />
      <path
        d='M21 14.6602C21.5523 14.6602 22 14.2124 22 13.6602C22 13.1079 21.5523 12.6602 21 12.6602C20.4477 12.6602 20 13.1079 20 13.6602C20 14.2124 20.4477 14.6602 21 14.6602Z'
        fill={color}
      />
      <path
        d='M19.5 19.1602C20.0523 19.1602 20.5 18.7124 20.5 18.1602C20.5 17.6079 20.0523 17.1602 19.5 17.1602C18.9477 17.1602 18.5 17.6079 18.5 18.1602C18.5 18.7124 18.9477 19.1602 19.5 19.1602Z'
        fill={color}
      />
    </svg>
  );
};

export default Fireworks;
