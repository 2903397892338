import { cn } from 'common/utils';
import React from 'react';

const Import = ({ size = 24, color = '#8D91A0', onClick }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 25 25'
      fill='none'
      className={cn({ 'cursor-pointer': onClick })}
    >
      <path
        d='M3 15.7568L5.5 3.75684H19.5L22 15.7568'
        stroke={color}
        strokeWidth='1.6'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M3 15.7568H7.95455L8.86365 18.7568H16.1363L17.0455 15.7568H22V22.2568H3V15.7568Z'
        stroke={color}
        strokeWidth='1.6'
        strokeLinejoin='round'
      />
      <path
        d='M9.5 10.7568L12.5 13.7568L15.5 10.7568'
        stroke={color}
        strokeWidth='1.6'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12.5 13.7568V7.75684'
        stroke={color}
        strokeWidth='1.6'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default Import;
