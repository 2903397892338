import { Disclosure } from '@headlessui/react';
import Down from 'assets/icons/Down';
import Trash from 'assets/icons/Trash';
import Up from 'assets/icons/Up';
import useTranslation from 'common/hooks/useTranslation';
import React from 'react';

export const OfferQuestionItem = ({
  name,
  description,
  dataType,
  answer,
  isRemovable,
  onDeleteClick,
}) => {
  const { t } = useTranslation('offers\\index');
  return (
    <Disclosure defaultOpen={true}>
      {({ open }) => (
        <div className='py-2'>
          <Disclosure.Button className='flex w-full text-left'>
            <span className='w-full text-sm font-semibold'>{name}</span>
            {open ? <Up /> : <Down />}
            {isRemovable && (
              <span onClick={onDeleteClick}>
                <Trash />
              </span>
            )}
          </Disclosure.Button>
          <Disclosure.Panel className='flex flex-col text-xs font-normal pr-4 gap-y-2'>
            <div className='flex flex-row gap-1 w-full'>
              <span className='font-semibold w-1/6'>{t('description')}</span>
              <span className='w-full mr-auto pr-2'>
                {description ? description : t('no-description')}
              </span>
            </div>
            <div className='flex flex-row gap-1 w-full'>
              <span className='font-semibold w-1/6'>{t('dataType')}</span>
              <span className='w-full mr-auto pr-2'>{dataType}</span>
            </div>
            {/*Uncomment this when the questions modal starts to accept answers*/}
            {/*<div className="flex flex-row gap-1 w-full">*/}
            {/*  <span className="font-semibold w-1/6g">{t('answer')}</span><span*/}
            {/*  className="w-full mr-auto pr-2">{answer}</span>*/}
            {/*</div>*/}
          </Disclosure.Panel>
        </div>
      )}
    </Disclosure>
  );
};
