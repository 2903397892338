import Danger from 'assets/icons/Danger';
import Left from 'assets/icons/Left';
import Right from 'assets/icons/Right';
import Button from 'common/components/Button';
import Card from 'common/components/Card';
import LabelledInput from 'common/components/LabelledInput';
import Loader from 'common/components/Loader';
import useNavigate from 'common/hooks/useNavigate';
import useTranslation from 'common/hooks/useTranslation';
import { setUserEmail } from 'common/slices/auth.slice';
import { useForgotPasswordMutation } from 'common/slices/authApi.slice';
import { cn } from 'common/utils';
import { Form, Formik } from 'formik';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import * as Yup from 'yup';

const PasswordAssistanceForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const { t } = useTranslation('auth\\password-assitance-form');

  const [forgotPassword, { isLoading }] = useForgotPasswordMutation();

  const initialValues = {
    email: location.state?.email || '',
  };

  const validationSchema = Yup.object({
    email: Yup.string()
      .email(t('status.invalid-email'))
      .required(t('status.email-required')),
  });

  const handleSubmit = async (values, { setStatus, setSubmitting }) => {
    setStatus('');
    try {
      const { email } = values;

      await forgotPassword(email)
        .unwrap()
        .then(() => {
          dispatch(setUserEmail({ email }));
          navigate('/new-password');
        });
    } catch (error) {
      switch (error?.status) {
        default:
          setStatus(t('status.something-wrong'));
      }
    }

    setSubmitting(false);
  };

  const handleBack = (values) => {
    navigate('/login', false, {
      state: { email: values.email },
    });
  };

  return (
    <Card shadowed>
      <div className='bg-vibrant-yellow rounded-t-lg h-2 w-full' />
      <div className='flex flex-col justify-center pt-10 md:px-[72px] px-5 pb-12 w-full relative'>
        <div className='w-[300px]'>
          <h1 className='text-center font-semibold text-3xl text-dark-blue pb-6'>
            {t('password-assistance-title')}
          </h1>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ values, status }) => (
              <Form className='flex flex-col gap-4 w-full'>
                <LabelledInput
                  type='email'
                  label={t('enter-your-email')}
                  name='email'
                  placeholder={t('email-placeholder')}
                />
                {status && (
                  <div
                    className={cn(
                      'text-bittersweet-shimmer text-xs font-semibold',
                      'flex justify-start items-center gap-1',
                    )}
                  >
                    <Danger size='14px' color='#C2514B' />
                    {status}
                  </div>
                )}
                <Button variant='primary'>
                  {t('next')}
                  <Right size='16px' color='#FFFFFF' />
                </Button>
                <Button variant='secondary' onClick={() => handleBack(values)}>
                  {t('back')}
                  <Left size='16px' color='#8D91A0' />
                </Button>
              </Form>
            )}
          </Formik>
        </div>
        {isLoading && <Loader />}
      </div>
    </Card>
  );
};

export default PasswordAssistanceForm;
