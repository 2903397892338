import { cn } from 'common/utils';
import React from 'react';

const Refresh = ({ size = 24, color = '#8D91A0', onClick }) => {
  return (
    <div className={cn({ 'cursor-pointer': onClick })}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width={size}
        height={size}
        viewBox='0 0 25 24'
        fill='none'
      >
        <path
          d='M20.9595 8C19.4868 5.03655 16.4287 3 12.895 3C9.36126 3 6.37211 5.03655 4.89941 8'
          stroke={color}
          strokeWidth='2'
          strokeLinecap='round'
        />
        <path
          d='M4.89941 4V8'
          stroke={color}
          strokeWidth='2'
          strokeLinecap='round'
        />
        <path
          d='M8.28956 8H4.89948'
          stroke={color}
          strokeWidth='2'
          strokeLinecap='round'
        />
        <path
          d='M4.89941 16C6.37211 18.9634 9.43016 21 12.9639 21C16.4976 21 19.4868 18.9634 20.9595 16'
          stroke={color}
          strokeWidth='2'
          strokeLinecap='round'
        />
        <path
          d='M20.9595 20V16'
          stroke={color}
          strokeWidth='2'
          strokeLinecap='round'
        />
        <path
          d='M17.5693 16H20.9594'
          stroke={color}
          strokeWidth='2'
          strokeLinecap='round'
        />
      </svg>
    </div>
  );
};

export default Refresh;
