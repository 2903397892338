import { cn } from 'common/utils';
import React from 'react';

const Page = ({ size = 24, color = '#8D91A0', onClick }) => {
  return (
    <svg
      className={cn({ 'cursor-pointer': onClick })}
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 24 25'
      fill='none'
    >
      <g clipPath='url(#clip0_2186_19737)'>
        <path
          d='M24 0.809814H0V24.8098H24V0.809814Z'
          fill='white'
          fillOpacity='0.01'
        />
        <path
          d='M20.5 2.80981H3.5C2.67157 2.80981 2 3.48139 2 4.30981V21.3098C2 22.1382 2.67157 22.8098 3.5 22.8098H20.5C21.3284 22.8098 22 22.1382 22 21.3098V4.30981C22 3.48139 21.3284 2.80981 20.5 2.80981Z'
          stroke={color}
          strokeWidth='1.6'
          strokeLinejoin='round'
        />
        <path
          d='M2 9.47656H22'
          stroke={color}
          strokeWidth='1.6'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M8.66602 22.8099V9.47656'
          stroke={color}
          strokeWidth='1.6'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_2186_19737'>
          <rect
            width={size}
            height={size}
            fill='white'
            transform='translate(0 0.809814)'
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Page;
