import React from 'react';

const TopEllipse = () => (
  <svg
    id='top-elipse'
    width='202'
    height='73'
    viewBox='0 0 202 73'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className='right-[68px] top-[207px] absolute z-10'
  >
    <path
      d='M200.296 33.4507C200.455 37.9993 198.038 42.5568 193.23 46.9224C188.427 51.284 181.338 55.3534 172.44 58.8935C154.651 65.9702 129.84 70.8486 102.231 71.8127C74.6227 72.7768 49.5313 69.6411 31.2924 63.8224C22.1686 60.9117 14.8136 57.3467 9.71758 53.3308C4.61692 49.3113 1.88825 44.9334 1.72941 40.3848C1.57057 35.8362 3.98721 31.2787 8.79506 26.9132C13.5986 22.5516 20.6869 18.4821 29.5854 14.9421C47.374 7.86534 72.1856 2.98695 99.7941 2.02283C127.403 1.05872 152.494 4.19447 170.733 10.0131C179.857 12.9238 187.212 16.4889 192.308 20.5048C197.408 24.5243 200.137 28.9021 200.296 33.4507Z'
      stroke='#8DAEB5'
      strokeWidth='2'
    />
  </svg>
);

export default TopEllipse;
