import {
  SplitContext,
  SplitFactory,
  SplitSdk,
  useTreatments,
} from '@splitsoftware/splitio-react';
import React, { useContext } from 'react';

const factory = SplitSdk({
  core: {
    authorizationKey: process.env.REACT_APP_SPLIT_IO_AUTH_KEY,
    key: 'key',
  },
  features: {
    LINKED_IN_LOG_IN: 'off',
    SHOW_SIGN_UP: 'on',
    WEB_NO_ACTION_UI: 'off',
    SHOW_OFFER_ANALYSIS: 'on',
    SUBMISSION_AI_INFO_EXTRA: 'on',
  },
});

const useFeatureFlag = (featureFlag) => {
  const { isReady } = useContext(SplitContext);
  const { treatment } = useTreatments([featureFlag])[featureFlag];

  return isReady && treatment === 'on';
};

const ShowFeature = ({ children, featureFlag }) => {
  const { isReady } = useContext(SplitContext);
  const { treatment } = useTreatments([featureFlag])[featureFlag];

  return <>{isReady && treatment === 'on' ? children : null}</>;
};

const FeatureFlagFactory = ({ children }) => {
  return <SplitFactory factory={factory}>{children}</SplitFactory>;
};

export { useFeatureFlag, ShowFeature, FeatureFlagFactory };
