import { createSlice } from '@reduxjs/toolkit';

const offerSlice = createSlice({
  name: 'offer',
  initialState: {
    openOffers: [],
  },
  reducers: {
    setOpenOffers: (state, action) => {
      state.openOffers = action.payload;
    },
    addOpenOffer: (state, action) => {
      state.openOffers.push(action.payload);
    },
  },
});

export const { setOpenOffers, addOpenOffer } = offerSlice.actions;

export default offerSlice.reducer;

export const selectOpenOfferById = (state, offerId) => {
  return state.offer?.openOffers?.find(
    (offer) => offer.id.toString() === offerId,
  );
};
