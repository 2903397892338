import { Menu, Transition } from '@headlessui/react';
import Down from 'assets/icons/Down';
import { cn } from 'common/utils';
import React, { Fragment, useState } from 'react';

export default function Dropdown({ label, placeholder, options, onChange }) {
  const [selectedOption, setSelectedOption] = useState('');

  const chooseOption = (option) => {
    setSelectedOption(option);
    onChange(option);
  };

  return (
    <div className='flex flex-col flex-1 gap-2'>
      <label
        className={cn('text-xs font-semibold uppercase', 'text-weldon-blue')}
      >
        {label}
      </label>
      <Menu as='div' className='relative inline-block text-left w-full'>
        {({ open }) => (
          <>
            <div>
              <Menu.Button
                className={cn(
                  'inline-flex items-center justify-between w-full h-[42] text-left rounded-md',
                  open ? 'border-2' : 'border',
                  'border-dark-blue outline-dark-blue',
                  'px-4 py-2',
                  selectedOption ? 'text-dark-blue' : 'text-gray-400',
                )}
              >
                {selectedOption || placeholder}
                <Down size='16px' color='#9CA3AF' />
              </Menu.Button>
            </div>
            <Transition
              as={Fragment}
              enter='transition ease-out duration-100'
              enterFrom='transform opacity-0 scale-95'
              enterTo='transform opacity-100 scale-100'
              leave='transition ease-in duration-75'
              leaveFrom='transform opacity-100 scale-100'
              leaveTo='transform opacity-0 scale-95'
            >
              <Menu.Items className='absolute right-0 mt-2 w-full z-10 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none'>
                <div className='px-1 py-1 '>
                  {options.map((option, index) => (
                    <Menu.Item key={index}>
                      {({ active }) => (
                        <button
                          onClick={() => chooseOption(option)}
                          className={`${
                            active
                              ? 'bg-vibrant-orange text-white'
                              : 'text-gray-900'
                          } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                        >
                          {option}
                        </button>
                      )}
                    </Menu.Item>
                  ))}
                </div>
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>
    </div>
  );
}
