import { apiSlice } from 'common/slices/api.slice';

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (formData) => ({
        url: '/auth/login',
        method: 'POST',
        body: formData,
      }),
    }),
    signup: builder.mutation({
      query: (data) => ({
        url: '/auth/signup',
        method: 'POST',
        body: data,
      }),
    }),
    forgotPassword: builder.mutation({
      query: (email) => ({
        url: `/auth/forgot-password`,
        method: 'POST',
        body: { email: email },
      }),
    }),
    resetPassword: builder.mutation({
      query: ({ resetCode, password }) => ({
        url: `/auth/reset-password`,
        method: 'POST',
        body: {
          reset_code: resetCode,
          password: password,
        },
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useSignupMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation,
} = authApiSlice;
