import { cn } from 'common/utils';
import React from 'react';

import Breadcrumbs from './Breadcrumbs';
import ProfileDropdown from './ProfileDropdown';

const Header = () => {
  return (
    <div
      className={cn(
        'flex justify-between items-center',
        'min-h-[74px] w-full bg-white shadow',
        'px-8 z-10',
      )}
    >
      <Breadcrumbs />
      <ProfileDropdown />
    </div>
  );
};

export default Header;
