import React from 'react';

export const TeamTableItemSkeleton = () => {
  return (
    <tr className='h-[72px] border-b border-bright-grey'>
      <td className='whitespace-nowrap'>
        <div className='flex items-center gap-2'>
          <div className='h-[40px] w-[40px] bg-bright-grey rounded-full flex-shrink-0' />
          <div className='h-[16px] w-[225px] bg-bright-grey rounded' />
        </div>
      </td>
      <td className='whitespace-nowrap'>
        <div className='flex flex-col'>
          <div className='h-[18px] w-[185px] bg-bright-grey rounded' />
          <div className='h-[12px] w-[76px] bg-bright-grey rounded mt-2' />
        </div>
      </td>
      <td className='whitespace-nowrap'>
        <div className='h-[18px] w-[80px] bg-bright-grey rounded' />
      </td>
    </tr>
  );
};
