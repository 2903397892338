import { cn } from 'common/utils';
import React from 'react';

const Danger = ({ size = 24, color = '#8D91A0', onClick }) => {
  return (
    <svg
      className={cn({ 'cursor-pointer': onClick })}
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 15 15'
      fill='none'
    >
      <g clipPath='url(#clip0_2057_96126)'>
        <path
          d='M7.75781 9.10205H6.59115V6.18538H7.75781M7.75781 11.4354H6.59115V10.2687H7.75781M0.757812 13.1854H13.5911L7.17448 2.10205L0.757812 13.1854Z'
          fill={color}
        />
      </g>
      <defs>
        <clipPath id='clip0_2057_96126'>
          <rect
            width='14'
            height='14'
            fill='white'
            transform='translate(0.173828 0.935303)'
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Danger;
