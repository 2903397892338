import Button from 'common/components/Button';
import useTranslation from 'common/hooks/useTranslation';
import { cn } from 'common/utils';
import { isEmpty } from 'lodash';
import React from 'react';
import { Droppable } from 'react-beautiful-dnd';

import KanbanColumnEmptyState from './KanbanColumnEmptyState';
import KanbanColumnSkeleton from './KanbanColumnSkeleton';
import KanbanItem from './KanbanItem';

const KanbanColumn = ({
  col: { list, total, id, isDropDisabled, page, pages },
  offer,
  isLoading,
  itemsCompact,
  handleHired,
  handleLoadMore,
  handleFavoriteChanged,
}) => {
  const { t } = useTranslation('offers\\details');

  const shouldShowLoadMoreButton = page < pages;

  return (
    <>
      {isLoading ? (
        <KanbanColumnSkeleton />
      ) : (
        <Droppable droppableId={id} isDropDisabled={isDropDisabled}>
          {(provided, snapshot) => (
            <div
              className={cn(
                'w-full min-w-[287px] flex flex-col',
                'bg-ghost-white rounded-md px-3 py-5',
                'transition-all ease-in-out relative',
                {
                  'bg-vibrant-orange bg-opacity-10':
                    snapshot.isDraggingOver && !isDropDisabled,
                },
              )}
            >
              <div className='flex justify-between items-center mb-4'>
                <div className='flex items-center gap-2.5'>
                  <h1 className='text-sm text-dark-liver font-semibold uppercase'>
                    {t(`kanban.${id}`)}
                  </h1>
                  <p className='text-xs text-sonic-silver'>{total}</p>
                </div>
              </div>
              <div
                className={cn(
                  'flex flex-col flex-1 gap-4',
                  'overflow-y-auto oversc scrollbar scrollbar-w-2',
                  'scrollbar-thumb-sonic-silver scrollbar-thumb-rounded',
                  'scrollbar-track-rounded',
                )}
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {isEmpty(list) ? (
                  <KanbanColumnEmptyState />
                ) : (
                  <>
                    {list?.map((item, index) => (
                      <KanbanItem
                        key={item.candidate?.id}
                        index={index}
                        columnId={id}
                        offer={offer}
                        compact={itemsCompact}
                        handleHired={handleHired}
                        submission={item}
                        handleFavoriteChanged={handleFavoriteChanged}
                      />
                    ))}
                    {id === 'interview' && (
                      <div
                        className={cn(
                          'flex flex-col w-full h-[100px] p-4',
                          'justify-center items-center gap-4',
                          'border-2 border-dashed border-bright-grey rounded',
                        )}
                      >
                        <p className='text-sm text-cool-grey text-center'>
                          {t('kanban.interview-column.description')}
                        </p>
                      </div>
                    )}
                    {shouldShowLoadMoreButton && (
                      <Button variant='secondary' onClick={handleLoadMore}>
                        {t('load-more')}
                      </Button>
                    )}
                  </>
                )}
              </div>
              {provided.placeholder}
              <div
                className={cn(
                  'absolute bg-ghost-white opacity-0 inset-0',
                  'transition-all ease-in-out rounded-md hidden',
                  {
                    'opacity-50 block': isDropDisabled,
                  },
                )}
              />
            </div>
          )}
        </Droppable>
      )}
    </>
  );
};

export default KanbanColumn;
