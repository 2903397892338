const isLocal = process.env.NODE_ENV === 'development';

const sentryDSN = isLocal
  ? undefined
  : 'https://e8d96d76c7134974a5075015b26365cd@o4505568196362240.ingest.sentry.io/4505573703417856';

const sentryConfig = {
  dsn: sentryDSN,
  environment: process.env.REACT_APP_SENTRY_ENV || 'localhost',
};

export default sentryConfig;
