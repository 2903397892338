import { cva } from 'class-variance-authority';
import { cn } from 'common/utils';
import React from 'react';

const chipVariants = cva(
  'inline-flex items-center gap-1 rounded-md px-2.5 py-1 text-xs font-semibold cursor-default',
  {
    variants: {
      variant: {
        primary: 'text-vibrant-orange bg-vibrant-orange/20',
        grey: 'text-cool-grey bg-cool-grey/20',
        secondary: '',
        success: 'text-success-green bg-success-green/20',
        warning: 'text-warning-yellow bg-warning-yellow/20',
        'primary-outlined':
          'border border-vibrant-orange text-vibrant-orange bg-vibrant-orange/5',
        'grey-outlined':
          'border border-cool-grey text-cool-grey bg-cool-grey/5',
        'secondary-outlined':
          'border border-soft-blue text-soft-blue bg-soft-blue/5',
        'success-outlined':
          'border border-success-green text-success-green bg-success-green/5',
        'warning-outlined':
          'border border-warning-yellow text-warning-yellow bg-warning-yellow/5',
      },
    },
    defaultVariants: {
      variant: 'primary',
    },
  },
);

const Chip = ({ className, variant, ...props }) => {
  return (
    <div className={cn(chipVariants({ variant }), className)} {...props} />
  );
};

export default Chip;
