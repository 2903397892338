import { Dialog, Transition } from '@headlessui/react';
import Import from 'assets/icons/Import';
import Right from 'assets/icons/Right';
import Button from 'common/components/Button';
import Loader from 'common/components/Loader';
import { useToast } from 'common/hooks/useToast';
import useTranslation from 'common/hooks/useTranslation';
import { useImportSubmissionsMutation } from 'common/slices/offerApi.slice';
import { cn } from 'common/utils';
import React, { Fragment, useEffect, useState } from 'react';

import DragAndDrop from './DragAndDrop';

const ImportModal = ({
  offerId,
  externalSourceId,
  isOpen,
  setIsOpen,
  refetchChatFn,
}) => {
  const toast = useToast();
  const [file, setFile] = useState(null);
  const [sourceId, setSourceId] = useState('');
  const [canImport, setCanImport] = useState(false);
  const [importSubmissions, { isLoading }] = useImportSubmissionsMutation();

  useEffect(() => {
    setSourceId(externalSourceId);
  }, [externalSourceId]);

  const { t } = useTranslation('offers\\details');

  const closeModal = () => {
    setIsOpen(false);
    setFile(null);
  };

  const importFile = async () => {
    try {
      const formData = new FormData();

      formData.append('submissions_file', file);

      if (sourceId) formData.append('external_source_id', sourceId);

      await importSubmissions({ offerId, formData }).unwrap();

      refetchChatFn();

      toast.newToast('positive', t('modals.import.toasts.success'));
    } catch (err) {
      switch (err?.status) {
        case 403:
          toast.newToast('negative', t('modals.import.toasts.forbidden'));
          break;
        default:
          toast.newToast('negative', t('modals.import.toasts.something-wrong'));
      }
    } finally {
      closeModal();
    }
  };

  return (
    <Fragment>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as='div'
          className='relative z-50'
          onClose={isLoading ? () => null : closeModal}
        >
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='fixed inset-0 bg-black bg-opacity-25' />
          </Transition.Child>
          <div className='fixed inset-0 overflow-y-auto'>
            <div className='flex min-h-full items-center justify-center p-4 text-center'>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 scale-95'
                enterTo='opacity-100 scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 scale-100'
                leaveTo='opacity-0 scale-95'
              >
                <Dialog.Panel
                  className={cn(
                    'flex flex-col w-full max-w-md transform overflow-hidden',
                    'rounded bg-white p-10 text-left gap-6',
                    'align-middle shadow-xl transition-all relative',
                  )}
                >
                  <Dialog.Title
                    as='div'
                    className='flex flex-col w-full items-center gap-8'
                  >
                    <Import size='64px' color='#084D6E' />
                    <h1 className=' text-2xl font-semibold text-dark-charcoal'>
                      {t('modals.import.title')}
                    </h1>
                  </Dialog.Title>
                  <DragAndDrop
                    file={file}
                    setFile={setFile}
                    setCanImport={setCanImport}
                  />
                  <div className='flex flex-col gap-2'>
                    <label
                      htmlFor='source-id'
                      className={cn(
                        'text-xs font-semibold uppercase',
                        'text-weldon-blue',
                      )}
                    >
                      {t('external-source-id')}
                    </label>
                    <div className='flex items-center relative'>
                      <input
                        className='text-input border rounded-md px-4 py-2 w-full outline-none'
                        type='text'
                        value={sourceId}
                        placeholder={t('external-source-id')}
                        onChange={(event) => setSourceId(event.target.value)}
                      />
                    </div>
                  </div>
                  <div className='w-full flex items-center justify-center gap-2'>
                    <Button variant='secondary' onClick={closeModal}>
                      {t('modals.import.buttons.cancel')}
                    </Button>
                    <Button
                      variant='primary'
                      disabled={!canImport || !file}
                      onClick={importFile}
                    >
                      {t('modals.import.buttons.import')}
                      <Right size='16px' color='#FFFFFF' />
                    </Button>
                  </div>
                  {isLoading && <Loader />}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </Fragment>
  );
};

export default ImportModal;
