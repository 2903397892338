import LabelledInput from 'common/components/LabelledInput';
import useTranslation from 'common/hooks/useTranslation';
import { useFormikContext } from 'formik';
import React from 'react';

export const InformationStep = () => {
  const formik = useFormikContext();
  const { t } = useTranslation('offers\\new');

  const handleSalaryChange = (e) => {
    const { name, value } = e.target;
    // Keep only digits
    const numericsOnly = value.replace(/[^\d]/g, '');

    // Set the field value without formatting
    formik.setFieldValue(name, numericsOnly);
  };

  const formatNumber = (value) => {
    if (!value) return '';
    // Format number with thousands separators
    return new Intl.NumberFormat('en-US', {
      maximumFractionDigits: 2,
    }).format(Number(value));
  };

  return (
    <React.Fragment>
      <div className='w-1/2'>
        <LabelledInput
          label={t('position-name')}
          name='positionName'
          type='text'
          placeholder={t('position-name')}
          value={formik.values.positionName || ''}
          onChange={(e) => formik.setFieldValue('positionName', e.target.value)}
        />
        <div className='w-full flex flex-col mt-10'>
          <div className='w-full flex gap-10'>
            <div className='w-1/2'>
              <LabelledInput
                label={t('minimum-salary')}
                name='minSalary'
                type='text' // Changed to text
                placeholder='00,00$'
                onChange={handleSalaryChange}
                value={formatNumber(formik.values.minSalary)}
              />
            </div>
            <div className='w-1/2'>
              <LabelledInput
                label={t('maximum-salary')}
                name='maxSalary'
                type='text' // Changed to text
                placeholder='00,00$'
                onChange={handleSalaryChange}
                value={formatNumber(formik.values.maxSalary)}
              />
            </div>
          </div>
        </div>
      </div>
      <div className='w-1/2'>
        <LabelledInput
          label={t('number-vacancies')}
          name='vacancies'
          type='text'
          placeholder='1'
          value={formik.values.vacancies || ''}
          onChange={(e) => formik.setFieldValue('vacancies', e.target.value)}
        />
      </div>
    </React.Fragment>
  );
};
