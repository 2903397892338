import { cn } from 'common/utils';
import React from 'react';

const Link = ({ size = 24, color = '#8D91A0', onClick }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      fill='none'
      className={cn({ 'cursor-pointer': onClick })}
      onClick={onClick}
    >
      <path
        d='M10.3713 8.12868C9.19975 6.95711 7.30025 6.95711 6.12868 8.12868L3.12868 11.1287C1.95711 12.3003 1.95711 14.1997 3.12868 15.3713C4.30025 16.5429 6.19975 16.5429 7.37132 15.3713L8.19749 14.5451M7.62868 10.8713C8.80025 12.0429 10.6997 12.0429 11.8713 10.8713L14.8713 7.87132C16.0429 6.69975 16.0429 4.80025 14.8713 3.62868C13.6997 2.45711 11.8003 2.45711 10.6287 3.62868L9.80397 4.45339'
        stroke={color}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default Link;
