import React from 'react';

import { TalentTableItemSkeleton } from './TalentTableItemSkeleton';

export const TalentTableSkeleton = () => {
  return (
    <div className='animate-pulse'>
      <table className='w-full table-auto'>
        <thead className='border-b border-bright-grey'>
          <tr>
            <th className='pb-5 whitespace-nowrap min-w-fit'>
              <div className='h-[20px] w-[48px] bg-bright-grey rounded' />
            </th>
            <th className='pb-5 whitespace-nowrap min-w-fit'>
              <div className='h-[20px] w-[68px] bg-bright-grey rounded' />
            </th>
            <th className='pb-5 whitespace-nowrap min-w-fit'>
              <div className='h-[20px] w-[54px] bg-bright-grey rounded' />
            </th>
            <th className='pb-5 whitespace-nowrap min-w-fit'>
              <div className='h-[20px] w-[48px] bg-bright-grey rounded' />
            </th>
            <th className='pb-5 whitespace-nowrap min-w-fit'>
              <div className='h-[20px] w-[100px] bg-bright-grey rounded' />
            </th>
            <th />
          </tr>
        </thead>
        <tbody>
          {[1, 2, 3, 4, 5].map((item) => {
            return <TalentTableItemSkeleton key={item} />;
          })}
        </tbody>
      </table>
    </div>
  );
};
