import { cn } from 'common/utils';
import React from 'react';

const Search = ({ size = 24, color = '#8D91A0', onClick }) => {
  return (
    <div className={cn({ 'cursor-pointer': onClick })}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width={size}
        height={size}
        viewBox='0 0 17 18'
        fill='none'
        onClick={onClick}
      >
        <path
          d='M7.64815 14.6269C11.3198 14.6269 14.2963 11.6504 14.2963 7.97871C14.2963 4.30704 11.3198 1.33057 7.64815 1.33057C3.97648 1.33057 1 4.30704 1 7.97871C1 11.6504 3.97648 14.6269 7.64815 14.6269Z'
          stroke={color}
          strokeWidth='1.66204'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M15.9587 16.2892L12.3438 12.6743'
          stroke={color}
          strokeWidth='1.66204'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    </div>
  );
};

export default Search;
