import useTranslation from 'common/hooks/useTranslation';
import { useGetSubmissionsQuery } from 'common/slices/submissionApi.slice';
import { isEmpty, isNil } from 'lodash';
import React from 'react';
import { ShowFeature } from 'split';

import TalentEmptyState from './TalentEmptyState';
import TalentTableItem from './TalentTableItem';
import { TalentTableSkeleton } from './TalentTableSkeleton';

const TalentTable = () => {
  const { t } = useTranslation('dashboard\\talent-table');

  const { data: submissions, isLoading } = useGetSubmissionsQuery({ size: 10 });

  return (
    <div className='w-full h-full relative'>
      {isLoading ? (
        <TalentTableSkeleton />
      ) : isNil(submissions) || isEmpty(submissions?.items) ? (
        <TalentEmptyState isLoading={isLoading} />
      ) : (
        <div className='w-full pb-6'>
          <table className='w-full table-auto'>
            <thead className='text-sm text-weldon-blue font-semibold uppercase border-b border-bright-grey'>
              <tr>
                <th className='pb-5 whitespace-nowrap min-w-fit'>
                  <div className='font-semibold text-left'>{t('name')}</div>
                </th>
                <th className='pb-5 whitespace-nowrap min-w-fit'>
                  <div className='font-semibold text-left'>{t('position')}</div>
                </th>
                <ShowFeature featureFlag='WEB_NO_ACTION_UI'>
                  <th className='pb-5 whitespace-nowrap min-w-fit'>
                    <div className='font-semibold text-left'>
                      {t('experience')}
                    </div>
                  </th>
                </ShowFeature>
                <th className='pb-5 whitespace-nowrap min-w-fit'>
                  <div className='font-semibold text-left'>{t('phase')}</div>
                </th>
                <th className='pb-5 whitespace-nowrap min-w-fit'>
                  <div className='font-semibold text-left'>{t('sent')}</div>
                </th>
                <th className='pb-5 whitespace-nowrap min-w-fit'>
                  <div className='font-semibold text-left'>
                    {t('last-updated')}
                  </div>
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {submissions?.items?.map((submission) => {
                const candidateName = submission?.candidate?.last_name
                  ? `${submission?.candidate?.first_name} ${submission?.candidate?.last_name}`
                  : submission?.candidate?.first_name;

                return (
                  <TalentTableItem
                    key={submission.id}
                    submission={submission}
                    candidateName={candidateName}
                  />
                );
              })}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default TalentTable;
