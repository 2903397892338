import { cn } from 'common/utils';
import React from 'react';

const Left = ({ size = 24, color = '#8D91A0', onClick }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      onClick={onClick}
      className={cn({ 'cursor-pointer': onClick })}
    >
      <g id='left' clipPath='url(#clip0_745_4470)'>
        <path
          id='Vector'
          d='M24 0H0V24H24V0Z'
          fill='white'
          fillOpacity='0.01'
        />
        <path
          id='Vector_2'
          d='M15.5 18L9.5 12L15.5 6'
          stroke={color}
          strokeWidth='1.6'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
    </svg>
  );
};

export default Left;
