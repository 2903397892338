import { cn } from 'common/utils';
import React from 'react';

const SidebarClose = ({ size = 24, color = '#8D91A0', onClick }) => {
  return (
    <svg
      className={cn({ 'cursor-pointer': onClick })}
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      stroke={color}
      strokeWidth='1.6'
      strokeLinecap='round'
      strokeLinejoin='round'
      onClick={onClick}
    >
      <rect x='3' y='3' width='18' height='18' rx='2' ry='2' />
      <path d='M9 3v18' />
      <path d='m16 15-3-3 3-3' />
    </svg>
  );
};

export default SidebarClose;
