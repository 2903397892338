import { PlaceholderEmptyState } from 'common/components/PlaceholderEmptyState';
import useTranslation from 'common/hooks/useTranslation';
import React from 'react';

import { OfferTestItem } from '../../components/OfferTestItem';

export const TestsTab = ({ tests }) => {
  const { t } = useTranslation('offers\\details');

  return tests.length > 0 ? (
    <div className='grid grid-cols-1 gap-4'>
      <div className='w-1/2'>
        {tests.map((test, index) => (
          <OfferTestItem
            key={index}
            name={test.name}
            description={test.description}
          />
        ))}
      </div>
    </div>
  ) : (
    <PlaceholderEmptyState className='pt-4' text={t('noTests')} />
  );
};
