import React from 'react';

const CardSkeleton = () => (
  <div className='min-h-[130px] border border-bright-grey rounded-lg'>
    <div className='w-[224px] flex flex-col'>
      <div className='h-1 w-full' />
      <div className='flex flex-col px-6 py-2.5 gap-3'>
        <div className='h-7 flex gap-2 items-center justify-start'>
          <div className='h-[28px] w-[96px] bg-bright-grey rounded' />
          <div className='h-[16px] w-[54px] bg-bright-grey rounded' />
        </div>
        <div className='flex flex-col gap-4'>
          <div className='h-[24px] w-[176px] bg-bright-grey rounded' />
          <div className='h-[24px] w-[160px] bg-bright-grey rounded' />
        </div>
      </div>
    </div>
  </div>
);

const OpenOffersSkeleton = () => {
  return (
    <div className='animate-pulse flex gap-3'>
      <CardSkeleton />
      <CardSkeleton />
      <CardSkeleton />
      <CardSkeleton />
    </div>
  );
};

export default OpenOffersSkeleton;
