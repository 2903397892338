import { cn } from 'common/utils';
import React from 'react';

export const PlaceholderEmptyState = ({ text }) => {
  return (
    <div
      className={cn(
        'flex flex-col w-full h-full',
        'bg-white rounded-lg',
        'border border-bright-grey',
      )}
    >
      <div className='h-full flex justify-center items-center mx-6'>
        <p className='text-raisin-black'>{text}</p>
      </div>
    </div>
  );
};
