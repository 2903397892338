import { cn } from 'common/utils';
import React from 'react';

const Whatsapp = ({ size = 24, color = '#084D6E', onClick }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={cn({ 'cursor-pointer': onClick })}
    >
      <path
        d='M21.99 6.547C21.9817 6.11797 21.9473 5.68985 21.887 5.265C21.8152 4.8871 21.6931 4.52052 21.524 4.175C21.1539 3.4443 20.5605 2.85057 19.83 2.48C19.4867 2.31171 19.1225 2.18997 18.747 2.118C18.3189 2.05648 17.8874 2.02142 17.455 2.013C17.272 2.006 17.035 2.003 16.925 2.003L7.077 2C6.967 2 6.73 2.003 6.547 2.01C6.11797 2.01827 5.68984 2.05266 5.265 2.113C4.8871 2.18484 4.52052 2.30692 4.175 2.476C3.44434 2.8462 2.85063 3.43956 2.48 4.17C2.31176 4.51332 2.19002 4.87752 2.118 5.253C2.0562 5.68109 2.0208 6.11257 2.012 6.545C2.006 6.728 2.002 6.965 2.002 7.075L2 16.923C2 17.033 2.003 17.27 2.01 17.453C2.01827 17.882 2.05266 18.3102 2.113 18.735C2.18485 19.1129 2.30693 19.4795 2.476 19.825C2.8462 20.5557 3.43956 21.1494 4.17 21.52C4.51332 21.6882 4.87753 21.81 5.253 21.882C5.6811 21.9435 6.11258 21.9786 6.545 21.987C6.728 21.994 6.965 21.997 7.075 21.997L16.923 21.999C17.033 21.999 17.27 21.996 17.453 21.989C17.882 21.9807 18.3102 21.9463 18.735 21.886C19.1129 21.8141 19.4795 21.692 19.825 21.523C20.556 21.153 21.1501 20.5596 21.521 19.829C21.6893 19.4857 21.811 19.1215 21.883 18.746C21.9448 18.3179 21.9802 17.8864 21.989 17.454C21.995 17.271 21.999 17.034 21.999 16.924L22 7.077C22 6.967 21.997 6.73 21.99 6.547ZM12.217 18.957H12.214C11.0237 18.9568 9.85235 18.6583 8.807 18.089L5.027 19.08L6.039 15.387C5.25545 14.0273 4.94237 12.4471 5.1483 10.8914C5.35423 9.33566 6.06765 7.89133 7.17797 6.78233C8.28829 5.67333 9.73347 4.96162 11.2894 4.75755C12.8454 4.55347 14.4252 4.86843 15.784 5.65359C17.1427 6.43876 18.2045 7.65026 18.8046 9.10026C19.4048 10.5503 19.5098 12.1578 19.1034 13.6735C18.697 15.1893 17.8018 16.5286 16.5568 17.4839C15.3117 18.4391 13.7863 18.9569 12.217 18.957Z'
        fill={color}
      />
      <path
        d='M12.22 5.9009C11.1611 5.90059 10.1214 6.18398 9.20912 6.72159C8.29683 7.2592 7.54523 8.0314 7.03247 8.95789C6.51972 9.88438 6.26454 10.9313 6.29347 11.9898C6.32241 13.0484 6.63439 14.0798 7.19699 14.9769L7.33799 15.2009L6.73899 17.3869L8.98199 16.7989L9.19799 16.9269C10.1111 17.4677 11.1528 17.753 12.214 17.7529H12.217C13.7888 17.7532 15.2963 17.129 16.408 16.0178C17.5196 14.9065 18.1442 13.3992 18.1445 11.8274C18.1448 10.2556 17.5206 8.74806 16.4094 7.63644C15.2981 6.52482 13.7908 5.90017 12.219 5.8999L12.22 5.9009ZM15.704 14.3709C15.5729 14.5874 15.3985 14.7744 15.1916 14.9201C14.9847 15.0659 14.7499 15.1673 14.502 15.2179C14.1276 15.2821 13.7435 15.2581 13.38 15.1479C13.0353 15.0405 12.6965 14.915 12.365 14.7719C11.1347 14.1545 10.0861 13.2279 9.32199 12.0829C8.90517 11.5529 8.65215 10.9127 8.59399 10.2409C8.58919 9.96362 8.64219 9.68837 8.74963 9.4327C8.85706 9.17703 9.01657 8.94653 9.21799 8.7559C9.27752 8.688 9.35044 8.63313 9.43219 8.59476C9.51393 8.55638 9.60272 8.53532 9.69299 8.5329C9.81099 8.5329 9.92999 8.5329 10.034 8.5389C10.144 8.5439 10.29 8.4969 10.434 8.8449C10.584 9.2009 10.94 10.0779 10.984 10.1669C11.0122 10.2136 11.0284 10.2665 11.031 10.321C11.0336 10.3755 11.0226 10.4297 10.999 10.4789C10.9541 10.5859 10.8942 10.6859 10.821 10.7759C10.731 10.8799 10.634 11.0079 10.554 11.0879C10.464 11.1769 10.372 11.2729 10.475 11.4509C10.7425 11.909 11.0765 12.3248 11.466 12.6849C11.8886 13.0606 12.3736 13.3598 12.899 13.5689C13.077 13.6589 13.181 13.6429 13.285 13.5239C13.389 13.4049 13.73 13.0039 13.849 12.8259C13.968 12.6479 14.086 12.6779 14.249 12.7369C14.412 12.7959 15.289 13.2269 15.467 13.3169C15.645 13.4069 15.764 13.4499 15.808 13.5239C15.8568 13.8103 15.8206 14.1048 15.704 14.3709Z'
        fill={color}
      />
    </svg>
  );
};

export default Whatsapp;
