import { cn } from 'common/utils';
import React from 'react';

const Download = ({ size = 24, color = '#8D91A0', onClick }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 16 16'
      fill='none'
      onClick={onClick}
      className={cn({
        'cursor-pointer': onClick,
        'pointer-events-none': !onClick,
      })}
    >
      <path
        d='M2 8.00277V14H14V8'
        stroke={color}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11 7.6665L8 10.6665L5 7.6665'
        stroke={color}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M7.99731 2V10.6667'
        stroke={color}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default Download;
