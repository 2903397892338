import React from 'react';

import { OfferTableItemSkeleton } from './OfferTableItemSkeleton';

export const OfferTableSkeleton = () => {
  return (
    <div className='animate-pulse'>
      <div className='w-full h-full relative'>
        <div className='w-full py-6'>
          <table className='w-full table-auto'>
            <thead className='border-b border-bright-grey'>
              <tr>
                <th className='pb-5 whitespace-nowrap min-w-fit w-[189px]'>
                  <div className='h-[20px] w-[31px] bg-bright-grey rounded' />
                </th>
                <th className='pb-5 whitespace-nowrap min-w-fit w-[234px]'>
                  <div className='h-[20px] w-[47px] bg-bright-grey rounded' />
                </th>
                <th className='pb-5 whitespace-nowrap min-w-fit w-[231px]'>
                  <div className='h-[20px] w-[70px] bg-bright-grey rounded' />
                </th>
                <th className='pb-5 whitespace-nowrap min-w-fit'>
                  <div className='h-[20px] w-[47px] bg-bright-grey rounded' />
                </th>
                <th className='pb-5 whitespace-nowrap min-w-fit'>
                  <div className='h-[20px] w-[26px] bg-bright-grey rounded' />
                </th>
                <th className='pb-5 whitespace-nowrap min-w-fit'>
                  <div className='h-[20px] w-[19px] bg-bright-grey rounded' />
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {[1, 2, 3, 4, 5].map((item) => {
                return <OfferTableItemSkeleton key={item} />;
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
