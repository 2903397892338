import {
  selectCurrentCompany,
  selectCurrentToken,
  selectUserCompanies,
} from 'common/slices/auth.slice';
import { isEmpty } from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

const RequireAuth = () => {
  const location = useLocation();

  const token = useSelector(selectCurrentToken);
  const userCompanies = useSelector(selectUserCompanies);
  const currentCompany = useSelector(selectCurrentCompany);

  if (token && currentCompany) {
    return <Outlet />;
  }

  if (!token || isEmpty(userCompanies)) {
    return <Navigate to='/login' state={{ from: location }} replace />;
  }

  return <Navigate to='/company-selector' state={{ from: location }} replace />;
};

export default RequireAuth;
