import { cn } from 'common/utils';
import React from 'react';

const LineChart = ({ size = 24, color = '#8D91A0', onClick }) => {
  return (
    <svg
      className={cn({ 'cursor-pointer': onClick })}
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 25 25'
      fill='none'
    >
      <path
        d='M16.6484 12.3538L20.8884 5.02382L22.6184 6.02382L17.3884 15.0738L10.8784 11.3238L6.10844 19.5738H22.6484V21.5738H2.64844V3.57382H4.64844V18.1138L10.1484 8.57382L16.6484 12.3538Z'
        fill={color}
      />
    </svg>
  );
};

export default LineChart;
