import * as Sentry from '@sentry/react';
import LoginForm from 'auth/components/LoginForm';
import NewPasswordForm from 'auth/components/NewPasswordForm';
import PasswordAssistanceForm from 'auth/components/PasswordAssistanceForm';
import SignUpForm from 'auth/components/SignUpForm';
import CompanySelectorForm from 'auth/components/company-selector';
import ErrorFallback from 'common/components/ErrorFallback';
import RequireAuth from 'common/components/RequireAuth';
import { ToastContextProvider } from 'common/components/toasts/ToastContext';
import { SettingsContextProvider } from 'common/contexts/SettingsContext';
import insightsTracker from 'common/insights_tracker';
import { selectUser } from 'common/slices/auth.slice';
import OfferDetails from 'offers/details';
import NewOffer from 'offers/new';
import OfferUpdate from 'offers/update';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider as ReduxProvider, useSelector } from 'react-redux';
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useNavigationType } from 'react-router-dom';
import { createRoutesFromChildren } from 'react-router-dom';
import { matchRoutes } from 'react-router-dom';

import Auth from './auth';
import Layout from './common/components/layout';
import Dashboard from './dashboard';
import './i18n';
import './index.css';
import Messages from './messages';
import Offers from './offers';
import Onboarding from './onboarding';
import sentryConfig from './sentry';
import Settings from './settings';
import { FeatureFlagFactory } from './split';
import store from './store';
import Talent from './talent';

if (sentryConfig.dsn) {
  Sentry.init({
    dsn: sentryConfig.dsn,
    environment: sentryConfig.environment,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        ),
      }),
      new Sentry.Replay(),
    ],
    tracesSampleRace: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

const TrackLocation = () => {
  const location = useLocation();
  const currentUser = useSelector(selectUser);

  React.useEffect(() => {
    insightsTracker.init();
  }, []);

  React.useEffect(() => {
    insightsTracker.doPageView(location, currentUser);
  }, [location]);

  return null;
};

const App = () => {
  return (
    <Router>
      <TrackLocation></TrackLocation>
      <SentryRoutes>
        <Route element={<RequireAuth />}>
          <Route
            element={
              <SettingsContextProvider>
                <Layout />
              </SettingsContextProvider>
            }
            path=':companySlug'
          >
            <Route index element={<Navigate to='dashboard' replace />} />

            <Route element={<Dashboard />} path='dashboard' />
            <Route path='offers'>
              <Route index element={<Offers />} />
              <Route element={<NewOffer />} path='new-offer' />
              <Route element={<OfferDetails />} path=':offerSlug' />
              <Route element={<OfferUpdate />} path=':offerSlug/update' />
              <Route
                element={<OfferDetails />}
                path=':offerSlug/submissions/:submissionSlug'
              />
            </Route>
            <Route element={<Talent />} path='talent' />
            <Route element={<Messages />} path='messages' />
            <Route element={<Settings />} path='settings' />
          </Route>
          <Route
            element={
              <SettingsContextProvider>
                <Onboarding />
              </SettingsContextProvider>
            }
            path=':companySlug/onboarding'
          />
        </Route>
        <Route element={<Auth />}>
          <Route element={<LoginForm />} path='login' />
          <Route element={<SignUpForm />} path='signup' />
          <Route element={<CompanySelectorForm />} path='company-selector' />
          <Route
            element={<PasswordAssistanceForm />}
            path='password-assistance'
          />
          <Route element={<NewPasswordForm />} path='new-password' />
        </Route>
        <Route element={<Navigate to='/login' />} path='*' />
      </SentryRoutes>
    </Router>
  );
};

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <Sentry.ErrorBoundary fallback={<ErrorFallback />}>
    <FeatureFlagFactory>
      <ReduxProvider store={store}>
        <ToastContextProvider>
          <App />
        </ToastContextProvider>
      </ReduxProvider>
    </FeatureFlagFactory>
  </Sentry.ErrorBoundary>,
);
