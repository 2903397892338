import { cn } from 'common/utils';
import React from 'react';

const LinkedIn = ({ size = 24, color = '#8D91A0', onClick }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 25 25'
      fill='none'
      className={cn({ 'cursor-pointer': onClick })}
    >
      <path
        d='M19.125 0.905518H6.375C3.2684 0.905518 0.75 3.42392 0.75 6.53052V19.2805C0.75 22.3871 3.2684 24.9055 6.375 24.9055H19.125C22.2316 24.9055 24.75 22.3871 24.75 19.2805V6.53052C24.75 3.42392 22.2316 0.905518 19.125 0.905518Z'
        fill='white'
      />
      <path
        d='M19.125 0.905518H6.375C3.2684 0.905518 0.75 3.42392 0.75 6.53052V19.2805C0.75 22.3871 3.2684 24.9055 6.375 24.9055H19.125C22.2316 24.9055 24.75 22.3871 24.75 19.2805V6.53052C24.75 3.42392 22.2316 0.905518 19.125 0.905518Z'
        fill={color}
      />
      <path
        d='M18.067 21.3135H20.8111C20.9105 21.3135 21.0059 21.274 21.0762 21.2037C21.1465 21.1334 21.1861 21.038 21.1861 20.9386L21.1875 15.1409C21.1875 12.1106 20.5345 9.78139 16.9933 9.78139C15.6472 9.73133 14.3777 10.4253 13.6927 11.584C13.6893 11.5896 13.6842 11.594 13.6781 11.5965C13.6721 11.5989 13.6654 11.5993 13.659 11.5976C13.6527 11.5959 13.6471 11.5922 13.6431 11.587C13.6391 11.5818 13.6369 11.5755 13.6369 11.5689V10.4361C13.6369 10.3367 13.5974 10.2413 13.527 10.171C13.4567 10.1007 13.3613 10.0611 13.2619 10.0611H10.6578C10.5583 10.0611 10.4629 10.1007 10.3926 10.171C10.3223 10.2413 10.2828 10.3367 10.2828 10.4361V20.938C10.2828 21.0375 10.3223 21.1329 10.3926 21.2032C10.4629 21.2735 10.5583 21.313 10.6578 21.313H13.4017C13.5011 21.313 13.5965 21.2735 13.6668 21.2032C13.7371 21.1329 13.7767 21.0375 13.7767 20.938V15.7468C13.7767 14.279 14.0551 12.8574 15.8749 12.8574C17.6688 12.8574 17.692 14.537 17.692 15.8419V20.9385C17.692 21.0379 17.7315 21.1333 17.8019 21.2037C17.8722 21.274 17.9676 21.3135 18.067 21.3135ZM4.3125 6.49555C4.3125 7.60789 5.22816 8.52308 6.3405 8.52308C7.45256 8.52299 8.36766 7.60724 8.36766 6.49517C8.36747 5.38311 7.45228 4.46802 6.34012 4.46802C5.22769 4.46802 4.3125 5.3833 4.3125 6.49555ZM4.96491 21.3135H7.71244C7.81189 21.3135 7.90728 21.274 7.9776 21.2037C8.04793 21.1333 8.08744 21.0379 8.08744 20.9385V10.4361C8.08744 10.3367 8.04793 10.2413 7.9776 10.171C7.90728 10.1007 7.81189 10.0611 7.71244 10.0611H4.96491C4.86545 10.0611 4.77007 10.1007 4.69974 10.171C4.62942 10.2413 4.58991 10.3367 4.58991 10.4361V20.9385C4.58991 21.0379 4.62942 21.1333 4.69974 21.2037C4.77007 21.274 4.86545 21.3135 4.96491 21.3135Z'
        fill='white'
      />
    </svg>
  );
};

export default LinkedIn;
