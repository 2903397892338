import useTranslation from 'common/hooks/useTranslation';
import { isEmpty, isNil } from 'lodash';
import React from 'react';

import TalentEmptyState from './TalentEmptyState';
import { TalentTableItem } from './TalentTableItem';
import { TalentTablePagination } from './TalentTablePagination';

export const TalentTable = ({
  submissions,
  isLoading,
  pageIndex,
  handlePageChange,
  pageSize,
  totalPages,
  total,
}) => {
  const { t } = useTranslation('talent\\index');

  return (
    <div className='w-full h-full relative mt-6'>
      {isNil(submissions) || isEmpty(submissions?.items) ? (
        <TalentEmptyState isLoading={isLoading} />
      ) : (
        <div className='w-full pb-6'>
          <table className='w-full table-auto'>
            <thead className='text-sm text-weldon-blue font-semibold uppercase border-b border-bright-grey'>
              <tr>
                <th className='pb-5 whitespace-nowrap min-w-fit'>
                  <div className='font-semibold text-left'>{t('name')}</div>
                </th>
                <th className='pb-5 whitespace-nowrap min-w-fit'>
                  <div className='font-semibold text-left'>{t('position')}</div>
                </th>
                <th className='pb-5 whitespace-nowrap min-w-fit'>
                  <div className='font-semibold text-left'>{t('phase')}</div>
                </th>
                <th className='pb-5 whitespace-nowrap min-w-fit'>
                  <div className='font-semibold text-left'>{t('sent')}</div>
                </th>
                <th className='pb-5 whitespace-nowrap min-w-fit'>
                  <div className='font-semibold text-left'>
                    {t('last-updated')}
                  </div>
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {submissions?.items?.map((submission) => {
                return (
                  <TalentTableItem
                    key={submission.id}
                    submission={submission}
                  />
                );
              })}
            </tbody>
          </table>
          <TalentTablePagination
            pageIndex={pageIndex}
            handlePageChange={handlePageChange}
            pageSize={pageSize}
            totalPages={totalPages}
            total={total}
          />
        </div>
      )}
    </div>
  );
};
