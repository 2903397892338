import { cn } from 'common/utils';
import React from 'react';

const Trash = ({ size = 24, color = '#8D91A0', onClick }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 25 25'
      fill='none'
      className={cn({ 'cursor-pointer': onClick })}
      onClick={onClick}
    >
      <g clipPath='url(#clip0_3270_28308)'>
        <path
          d='M24.5 0.756836H0.5V24.7568H24.5V0.756836Z'
          fill='white'
          fillOpacity='0.01'
        />
        <path
          d='M5 5.75684V22.7568H20V5.75684H5Z'
          stroke={color}
          strokeWidth='1.6'
          strokeLinejoin='round'
        />
        <path
          d='M10.5 10.7568V17.2568'
          stroke={color}
          strokeWidth='1.6'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M14.5 10.7568V17.2568'
          stroke={color}
          strokeWidth='1.6'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M2.5 5.75684H22.5'
          stroke={color}
          strokeWidth='1.6'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M8.5 5.75684L10.1445 2.75684H14.8885L16.5 5.75684H8.5Z'
          stroke={color}
          strokeWidth='1.6'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_3270_28308'>
          <rect
            width='24'
            height='24'
            fill='white'
            transform='translate(0.5 0.756836)'
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Trash;
