import { cn } from 'common/utils';
import React from 'react';

const Chat = ({ size = 33, onClick }) => {
  return (
    <div className={cn({ 'cursor-pointer': onClick })} onClick={onClick}>
      <svg
        width={size}
        height={size}
        viewBox='0 0 34 33'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M16.7676 4.38867C24.3301 4.38867 30.5177 9.31119 30.5177 15.3887C30.5177 21.4662 24.3301 26.3887 16.7676 26.3887C15.0626 26.3887 13.4264 26.1412 11.9139 25.7012C7.89884 29.1387 3.01758 29.1387 3.01758 29.1387C6.22134 25.935 6.73009 23.7762 6.79884 22.9512C4.46133 20.985 3.01758 18.3175 3.01758 15.3887C3.01758 9.31119 9.2051 4.38867 16.7676 4.38867Z'
          fill='white'
        />
        <path
          d='M21.5882 12.9333C21.5696 13.368 21.4185 13.8187 21.1588 14.2308C21.0393 14.4172 20.9061 14.5838 20.7735 14.7265C20.5772 14.9336 20.3524 15.1154 20.1062 15.2613C19.781 15.4345 19.3072 15.6228 18.7999 15.6426C18.7851 15.6429 18.7702 15.643 18.7552 15.643C18.6006 15.643 18.4594 15.6308 18.33 15.6085C17.8774 15.521 17.4113 15.259 17.0079 14.6706C17.6313 13.9418 17.9745 13.2253 18.0379 12.5517C18.1055 11.8339 17.8527 11.1908 17.3343 10.6699C17.389 10.6475 17.4418 10.6202 17.4916 10.5887C18.4155 10.0035 20.2401 10.108 21.3286 11.8839C21.5211 12.2445 21.5957 12.5977 21.5882 12.9333Z'
          fill='#F16347'
        />
        <path
          d='M17.3128 15.643C17.5657 15.8271 17.8733 15.9677 18.2536 16.0337C18.3629 16.0545 18.4721 16.0673 18.5807 16.0729C18.6586 16.0769 18.7358 16.0773 18.8121 16.0744C19.3076 16.0649 19.7733 15.9308 20.1878 15.7108C20.4845 15.9509 21.0106 16.3133 21.6575 16.4115C21.6674 16.4133 21.6773 16.415 21.6873 16.4166C21.5018 16.8902 21.2114 17.4419 20.7405 17.8008C20.2063 18.208 19.3934 18.4002 18.1111 17.8189C17.7241 17.409 17.0399 16.8198 16.2885 16.3C15.5676 15.8014 14.756 15.346 14.0696 15.1897C13.971 14.9247 13.8376 14.6461 13.6589 14.3925C13.439 14.0646 13.1143 13.7423 12.6093 13.58C12.5794 13.5703 12.549 13.5613 12.5182 13.553C12.3885 13.5178 12.2475 13.4929 12.0942 13.4805L12.0833 13.4796L12.0724 13.4798C11.6264 13.489 11.2609 13.567 10.9621 13.6903C10.914 13.3589 10.9557 12.9583 11.1857 12.6114C11.4689 12.1841 12.0733 11.7859 13.2972 11.733L13.2994 11.7337C13.3185 11.7397 13.3459 11.7488 13.3805 11.7612C13.4568 11.7887 13.5682 11.8328 13.7039 11.8977C13.7669 11.9278 13.835 11.9624 13.9073 12.0019C14.1724 12.1508 14.4403 12.3396 14.7008 12.5538L14.7209 12.5708C14.8102 12.6487 14.8946 12.7236 14.9752 12.7966C15.4778 13.2654 16.0226 13.9066 16.5532 14.7637C16.7292 15.0571 16.9618 15.3874 17.3128 15.643Z'
          fill='#F16347'
        />
        <path
          d='M21.4775 14.5333C21.4934 14.5094 21.509 14.4853 21.5243 14.461C21.562 14.402 21.5985 14.3414 21.6333 14.2793C21.8488 13.8947 22.006 13.4436 22.0199 12.9555C22.0359 12.606 21.9809 12.258 21.8396 11.932C21.9125 11.9258 21.9972 11.9231 22.0905 11.927C22.3276 11.9368 22.5998 11.9878 22.8504 12.1104C23.0987 12.2318 23.3267 12.4233 23.4845 12.7203C23.6431 13.0186 23.7391 13.4389 23.6955 14.0298C23.6082 15.2145 23.0968 15.7886 22.4975 15.9584C22.4216 15.9799 22.343 15.9954 22.262 16.0048H21.9886C21.9038 16.0048 21.8205 15.9985 21.7391 15.9869C21.3647 15.9223 20.9681 15.75 20.5872 15.4721C20.7469 15.3561 20.9255 15.199 21.1008 15.0088C21.2405 14.8598 21.3667 14.7002 21.4775 14.5333Z'
          fill='#F16347'
        />
        <path
          d='M14.9888 18.235L17.4758 18.2172C17.1034 18.6111 16.6339 19.1126 16.2176 19.5585C15.9359 19.8602 15.6784 20.1368 15.4912 20.338L15.1872 20.6649C15.1822 20.6704 15.1773 20.6762 15.1728 20.6822L15.1722 20.683L15.169 20.6872C15.166 20.6911 15.1613 20.6971 15.1551 20.7049C15.1427 20.7205 15.1243 20.7432 15.1012 20.7702C15.0547 20.8249 14.9907 20.8954 14.919 20.9623C14.8459 21.0305 14.7726 21.0875 14.7077 21.1219C14.6442 21.1554 14.6173 21.1526 14.6146 21.1524C14.5807 21.1423 14.5431 21.1122 14.5026 21.0284C14.4727 20.9662 14.4547 20.9066 14.4348 20.8404C14.4256 20.8098 14.416 20.7779 14.4046 20.7436C14.2998 20.4292 14.1591 19.9903 14.0443 19.6291C13.9869 19.4487 13.9362 19.288 13.8998 19.1724L13.8853 19.1266L14.9888 18.235Z'
          fill='#F16347'
        />
        <path
          d='M14.776 17.8515L13.4947 18.8829C12.7545 19.3827 12.2867 19.3468 11.9953 19.1589C11.6849 18.9586 11.4814 18.5321 11.3947 18.0439C11.4588 17.7418 11.6056 17.3032 11.8179 16.8969C11.9171 16.7105 12.0322 16.5277 12.1631 16.3599C12.1829 16.3346 12.2029 16.3098 12.2233 16.2853C12.2856 16.2136 12.3467 16.1466 12.4079 16.0844C12.6383 15.8589 12.9022 15.6901 13.2018 15.6119C13.3859 15.5715 13.6039 15.5603 13.8718 15.5904C14.7058 15.7728 16.0747 16.5191 17.4486 17.7854L14.9102 17.8036C14.8615 17.8039 14.814 17.821 14.776 17.8515Z'
          fill='#F16347'
        />
        <path
          d='M10.1717 16.5643C10.2763 16.9914 10.5115 17.437 11.0265 17.7287C11.1115 17.4147 11.2476 17.0486 11.4374 16.6923C11.5673 16.4444 11.7207 16.205 11.8959 16.0034C11.9549 15.933 12.0169 15.8648 12.0822 15.7996C12.2586 15.6182 12.4545 15.454 12.6941 15.3353C12.8138 15.276 12.9419 15.2292 13.0808 15.1964C13.2316 15.1559 13.3909 15.133 13.5587 15.1315C13.4845 14.9636 13.3991 14.7819 13.2841 14.6111C13.1533 14.4317 12.9989 14.2742 12.8178 14.1554C12.4018 13.8826 11.8003 13.7861 10.912 14.1957C10.7332 14.3 10.5984 14.4237 10.4956 14.5504C10.2286 14.8796 10.1535 15.2586 10.1269 15.4669L10.1266 15.4693C10.1087 15.61 10.0507 16.0699 10.1717 16.5643Z'
          fill='#F16347'
        />
        <path
          d='M15.1485 10.4561C15.1579 10.4548 15.1672 10.4535 15.1766 10.4523C15.2685 10.4412 15.3594 10.4354 15.4476 10.4351C15.598 10.4347 15.7421 10.4396 15.8805 10.4542C16.3352 10.5157 16.7477 10.6942 17.0237 10.9702C17.4627 11.4092 17.6626 11.9302 17.6078 12.5112C17.5583 13.0373 17.2978 13.633 16.7761 14.2742C16.5993 13.9787 16.3646 13.6537 16.0918 13.33C15.9461 13.1529 15.7978 12.9907 15.638 12.8304C15.5909 12.7823 15.5432 12.7346 15.4949 12.6874C15.3348 12.5311 15.1667 12.3786 14.9929 12.2348C14.8008 12.068 14.6111 11.9287 14.4332 11.8132C14.1423 11.6197 13.8746 11.4822 13.6423 11.3925C13.6499 11.3798 13.6578 11.3668 13.6659 11.3536C13.72 11.2656 13.779 11.1767 13.836 11.1021C14.1622 10.743 14.6365 10.5273 15.1485 10.4561Z'
          fill='#F16347'
        />
      </svg>
    </div>
  );
};

export default Chat;
