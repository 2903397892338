import { selectCurrentCompany } from 'common/slices/auth.slice';
import { useSelector } from 'react-redux';
import { useNavigate as useN } from 'react-router-dom';

const useNavigate = () => {
  const navigate = useN();
  const currentCompany = useSelector(selectCurrentCompany);

  const basename = !!currentCompany ? `/${currentCompany.slug}` : '';

  const customNavigate = (to, companyChanged, options) => {
    if (companyChanged) {
      navigate(to, options);
    } else {
      navigate(basename + to, options);
    }
  };

  return customNavigate;
};

export default useNavigate;
