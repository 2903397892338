import Add from 'assets/icons/Add';
import EditProcess from 'assets/icons/EditProcess';
import Button from 'common/components/Button';
import useNavigate from 'common/hooks/useNavigate';
import useTranslation from 'common/hooks/useTranslation';
import React, { useState } from 'react';

import { useGetSubmissionsQuery } from '../common/slices/submissionApi.slice';
import { TalentSkeleton } from './components/TalentSkeleton';
import { TalentTable } from './components/TalentTable';
import { TalentTableSkeleton } from './components/TalentTableSkeleton';

const Talent = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('talent\\index');

  const pageSize = 7;
  const [pageIndex, setPageIndex] = useState(1);

  const {
    data: submissions,
    isLoading,
    refetch,
  } = useGetSubmissionsQuery({
    page: pageIndex,
    size: pageSize,
  });

  const handlePageChange = (idx) => {
    idx > 0 && idx <= submissions.pages && setPageIndex(idx);
  };

  React.useEffect(() => {
    refetch({ page: pageIndex, size: pageSize });
  }, [pageIndex, refetch]);

  return (
    <div className='flex flex-col h-full w-full max-w-full p-6'>
      {isLoading ? (
        <>
          <TalentSkeleton />
          <TalentTableSkeleton />
        </>
      ) : (
        <div className='flex flex-col w-full h-full'>
          <div className='flex items-center justify-between mb-4'>
            <div className='flex items-center gap-6'>
              <h1 className='text-3xl text-dark-liver font-semibold'>
                {t('title')}
              </h1>
              <div className='flex items-center gap-1.5'>
                <EditProcess size='20px' color='#7A7A7A' />
                <p className='text-sm text-sonic-silver'>
                  {submissions?.total}
                </p>
              </div>
            </div>
            <Button
              variant='primary'
              onClick={() => navigate('/talent/new-submission')}
            >
              <Add size='16px' color='#FFFFFF' />
              {t('add-submission')}
            </Button>
          </div>
          {/*<div className='flex items-center justify-between mb-3.5 z-10'>*/}
          {/*  <TalentTab filter={filter} setFilter={setFilter} />*/}
          {/*</div>*/}
          {/*<Divider />*/}
          <TalentTable
            submissions={submissions}
            isLoading={isLoading}
            pageIndex={pageIndex}
            handlePageChange={handlePageChange}
            pageSize={pageSize}
            totalPages={submissions?.pages}
            total={submissions?.total}
          />
        </div>
      )}
    </div>
  );
};

export default Talent;
