import clsx from 'clsx';
import React from 'react';
import { FiLink, FiList } from 'react-icons/fi';
import { TfiListOl } from 'react-icons/tfi';

const iconSize = 17;

const Toolbar = ({ editor, setLink }) => {
  if (!editor) {
    return null;
  }

  const isTextSelected = !editor.state.selection.empty;

  return (
    <div className='flex items-center space-x-2 mb-2 border-b border-gray-300 pb-2'>
      {/* Bold Button */}
      <button
        type='button'
        onClick={() => editor.chain().focus().toggleBold().run()}
        disabled={!editor.can().chain().focus().toggleBold().run()}
        className='flex items-center justify-center w-10 h-10 rounded-md border border-gray-300 bg-white hover:bg-gray-100'
      >
        <strong className={`text-[${iconSize}px]`}>B</strong>
      </button>

      {/* Italic Button */}
      <button
        type='button'
        onClick={() => editor.chain().focus().toggleItalic().run()}
        disabled={!editor.can().chain().focus().toggleItalic().run()}
        className='flex items-center justify-center w-10 h-10 rounded-md border border-gray-300 bg-white hover:bg-gray-100'
      >
        <em className={`text-[${iconSize}px]`}>I</em>
      </button>

      {/* Underline Button */}
      <button
        type='button'
        onClick={() => editor.chain().focus().toggleUnderline().run()}
        disabled={!editor.can().chain().focus().toggleUnderline().run()}
        className='flex items-center justify-center w-10 h-10 rounded-md border border-gray-300 bg-white hover:bg-gray-100'
      >
        <u className={`text-[${iconSize}px]`}>U</u>
      </button>

      {/* Bullet List Button */}
      <button
        type='button'
        onClick={() => editor.chain().focus().toggleBulletList().run()}
        className='flex items-center justify-center w-10 h-10 rounded-md border border-gray-300 bg-white hover:bg-gray-100'
      >
        <FiList className={`text-[${iconSize}px]`} />
      </button>

      {/* Ordered List Button */}
      <button
        type='button'
        onClick={() => editor.chain().focus().toggleOrderedList().run()}
        className='flex items-center justify-center w-10 h-10 rounded-md border border-gray-300 bg-white hover:bg-gray-100'
      >
        <TfiListOl className={`text-[${iconSize}px]`} />
      </button>

      {/* Link Button */}
      <button
        type='button'
        onClick={setLink}
        disabled={!isTextSelected}
        className={clsx(
          'flex items-center justify-center w-10 h-10 rounded-md border border-gray-300',
          isTextSelected ? 'bg-white hover:bg-gray-100' : 'bg-gray-200',
        )}
      >
        <FiLink className={`text-[${iconSize}px]`} />
      </button>
    </div>
  );
};

export default Toolbar;
