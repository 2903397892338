import Left from 'assets/icons/Left';
import Right from 'assets/icons/Right';
import useTranslation from 'common/hooks/useTranslation';
import React from 'react';

export const TalentTablePagination = ({
  pageIndex,
  handlePageChange,
  pageSize,
  totalPages,
  total,
}) => {
  const { t } = useTranslation('talent\\index');

  const pageMin = pageSize * pageIndex - pageSize + 1;
  const pageMax =
    pageMin + pageSize - 1 > total ? total : pageMin + pageSize - 1;

  const getPageNumbers = () => {
    const delta = 2;
    const range = [];
    const rangeWithDots = [];
    let l;

    range.push(1);
    for (let i = pageIndex - delta; i <= pageIndex + delta; i++) {
      if (i >= 2 && i < totalPages) {
        range.push(i);
      }
    }
    range.push(totalPages);

    for (let i of range) {
      if (l) {
        if (i - l === 2) {
          rangeWithDots.push(l + 1);
        } else if (i - l !== 1) {
          rangeWithDots.push('...');
        }
      }
      rangeWithDots.push(i);
      l = i;
    }

    return rangeWithDots;
  };

  const pageNumbers = getPageNumbers();

  return (
    <div className='flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6'>
      <div className='hidden sm:flex sm:flex-1 sm:items-center sm:justify-between'>
        <div>
          <p className='text-sm text-sonic-silver'>
            {t('pagination', {
              pageIndex: pageMin,
              pageSize: pageMax,
              total: total,
            })}
          </p>
        </div>
        <div>
          <nav
            className='isolate inline-flex -space-x-px rounded-md shadow-sm'
            aria-label='Pagination'
          >
            <button
              onClick={() => handlePageChange(pageIndex + -1)}
              className='relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 disabled:pointer-events-none disabled:opacity-50'
              disabled={pageIndex === 1}
            >
              <Left />
            </button>

            {pageNumbers.map((page, index) => (
              <button
                key={index}
                onClick={() =>
                  typeof page === 'number' && handlePageChange(page)
                }
                aria-current={pageIndex === page ? 'page' : undefined}
                className={`relative inline-flex items-center px-4 py-2 text-sm font-semibold ${
                  pageIndex === page
                    ? 'z-10 bg-vibrant-orange text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
                    : 'text-sonic-silver ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0'
                }`}
                disabled={page === '...'}
              >
                {page}
              </button>
            ))}

            <button
              onClick={() => handlePageChange(pageIndex + 1)}
              className='relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0  disabled:pointer-events-none disabled:opacity-50'
              disabled={pageIndex * pageSize >= total}
            >
              <Right />
            </button>
          </nav>
        </div>
      </div>
    </div>
  );
};
