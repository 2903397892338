import { Dialog, Transition } from '@headlessui/react';
import Right from 'assets/icons/Right';
import Button from 'common/components/Button';
import Dropdown from 'common/components/Dropdown';
import LabelledInput from 'common/components/LabelledInput';
import useTranslation from 'common/hooks/useTranslation';
import { cn } from 'common/utils';
import React, { Fragment, useState } from 'react';

const DATA_TYPES = ['boolean', 'number', 'text'];

const QuestionsModal = ({ isOpen, setIsOpen, questions, setQuestions }) => {
  const { t } = useTranslation('offers\\new');
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [dataType, setDataType] = useState('');
  // const [answer, setAnswer] = useState('');

  const closeModal = () => {
    setIsOpen(false);
    setName('');
    setDescription('');
    setDataType('');
    // setAnswer('');
  };

  const AddNewQuestion = async () => {
    setQuestions([
      ...questions,
      {
        name: name,
        description: description,
        data_type: dataType,
        // answer: answer,
        source: 'manual',
      },
    ]);
    closeModal();
  };

  return (
    <Fragment>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as='div' className='relative z-50' onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='fixed inset-0 bg-black bg-opacity-25' />
          </Transition.Child>
          <div className='fixed inset-0 overflow-y-auto'>
            <div className='flex min-h-full items-center justify-center p-4 text-center'>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 scale-95'
                enterTo='opacity-100 scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 scale-100'
                leaveTo='opacity-0 scale-95'
              >
                <Dialog.Panel
                  className={cn(
                    'flex flex-col w-full overflow-visible max-w-md transform',
                    'rounded bg-white p-10 text-left gap-6',
                    'align-middle shadow-xl transition-all relative',
                  )}
                >
                  <Dialog.Title
                    as='div'
                    className='flex flex-col w-full items-center gap-8'
                  >
                    <h1 className=' text-2xl font-semibold text-dark-charcoal'>
                      {t('modals.newQuestion.title')}
                    </h1>
                  </Dialog.Title>
                  <Dialog.Description
                    as='div'
                    className='flex flex-col w-full items-center gap-8'
                  >
                    <h1 className='text-dark-charcoal text-center'>
                      {t('modals.newQuestion.description')}
                    </h1>
                  </Dialog.Description>
                  <LabelledInput
                    type='text'
                    label={t('modals.newQuestion.fields.name.title')}
                    name='name'
                    placeholder={t(
                      'modals.newQuestion.fields.name.placeholder',
                    )}
                    onChange={(event) => setName(event.target.value)}
                  />
                  <LabelledInput
                    type='textarea'
                    label={t('modals.newQuestion.fields.description.title')}
                    name='description'
                    placeholder={t(
                      'modals.newQuestion.fields.description.placeholder',
                    )}
                    onChange={(event) => setDescription(event.target.value)}
                  />
                  <Dropdown
                    options={DATA_TYPES}
                    label={t('modals.newQuestion.fields.dataType.title')}
                    placeholder={t(
                      'modals.newQuestion.fields.dataType.placeholder',
                    )}
                    onChange={(option) => setDataType(option)}
                  />
                  <div className='w-full flex items-center justify-center gap-2'>
                    <Button variant='secondary' onClick={closeModal}>
                      {t('modals.newQuestion.buttons.cancel')}
                    </Button>
                    <Button
                      variant='primary'
                      onClick={AddNewQuestion}
                      disabled={name === '' || dataType === ''}
                    >
                      {t('modals.newQuestion.buttons.add')}
                      <Right size='16px' color='#FFFFFF' />
                    </Button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </Fragment>
  );
};

export default QuestionsModal;
