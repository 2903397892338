import Left from 'assets/icons/Left';
import Right from 'assets/icons/Right';
import Button from 'common/components/Button';
import Divider from 'common/components/Divider';
import Loader from 'common/components/Loader';
import StepIndicator from 'common/components/layout/components/StepIndicator';
import { OnboardingStatus } from 'common/enums';
import useNavigate from 'common/hooks/useNavigate';
import { useToast } from 'common/hooks/useToast';
import useTranslation from 'common/hooks/useTranslation';
import {
  selectCurrentCompany,
  setCurrentCompany,
} from 'common/slices/auth.slice';
import {
  useGetCompanyDetailQuery,
  useUpdateCompanyMutation,
} from 'common/slices/companyApi.slice';
import { Form, Formik } from 'formik';
import { isEmpty } from 'lodash';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import { CompanyDataStep } from './components/CompanyDataStep';
import { CompanyDescriptionStep } from './components/CompanyDescriptionStep';
import Header from './components/Header';
import { WelcomeStep } from './components/WelcomeStep';

const Onboarding = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { t } = useTranslation('onboarding\\index');

  const onboardingSteps = ['welcome', 'data', 'description'];

  const [currentStep, setCurrentStep] = useState(onboardingSteps[0]);
  const isFirstStep = currentStep === onboardingSteps[0];
  const isLastStep =
    currentStep === onboardingSteps[onboardingSteps.length - 1];

  const currentCompany = useSelector(selectCurrentCompany);

  const { data: company, isLoading: isLoadingCompany } =
    useGetCompanyDetailQuery({ slug: currentCompany?.slug });

  const [updateCompany] = useUpdateCompanyMutation();

  const initialValues = {
    name: company?.name,
    webpage: company?.webpage,
    location: company?.location,
    sector: company?.sector,
    n_employees: company?.n_employees,
    email: company?.email,
    phone: company?.phone,
    about_us: company?.about_us,
    mission: company?.mission,
    bio: company?.bio,
  };

  const validationSchema = Yup.object({
    name: Yup.string().required(t('errors.required-company-name')),
    webpage: Yup.string()
      .url(t('errors.invalid-company-webpage'))
      .notRequired(),
    location: Yup.string().notRequired(),
    sector: Yup.string().notRequired(),
    n_employees: Yup.number()
      .integer(t('errors.employees-must-be-integer'))
      .notRequired(),
    email: Yup.string().email(t('errors.invalid-company-email')).notRequired(),
    phone: Yup.string().notRequired(),
    about_us: Yup.string().notRequired(),
    mission: Yup.string().notRequired(),
    bio: Yup.string().notRequired(),
  });

  const handleNext = () => {
    const lastStepIndex = onboardingSteps.length - 1;
    const currentStepIndex = onboardingSteps.indexOf(currentStep);

    if (currentStepIndex < lastStepIndex) {
      const nextStep = onboardingSteps[currentStepIndex + 1];
      setCurrentStep(nextStep);
    }
  };

  const handleBack = () => {
    const currentStepIndex = onboardingSteps.indexOf(currentStep);

    if (currentStepIndex > 0) {
      const previousStep = onboardingSteps[currentStepIndex - 1];
      setCurrentStep(previousStep);
    }
  };

  const handleSubmit = async (values) => {
    try {
      const data = {
        name: values.name,
        webpage: values.webpage,
        location: values.location,
        sector: values.sector,
        n_employees: values.n_employees,
        email: values.email,
        phone: values.phone,
        about_us: values.about_us,
        mission: values.mission,
        bio: values.bio,
        onboarding_status: OnboardingStatus.done,
      };

      const updatedCompany = await updateCompany({
        companyId: currentCompany.id,
        data: data,
      }).unwrap();
      dispatch(setCurrentCompany(updatedCompany));
      toast.newToast('positive', t('toasts.success'));

      navigate('/dashboard');
    } catch (err) {
      switch (err?.status) {
        case 403:
          toast.newToast('negative', t('toasts.forbidden'));
          break;
        default:
          toast.newToast('negative', t('toasts.something-wrong'));
      }
    }
  };

  const handleStep = (setTouched) => {
    setTouched({
      name: true,
      webpage: true,
      sector: true,
      n_employees: true,
      email: true,
      phone: true,
      about_us: true,
      mission: true,
      bio: true,
    }).then((errors) => {
      if (isEmpty(errors)) {
        handleNext();
      }
    });
  };

  const handleSkip = async () => {
    try {
      const data = {
        onboarding_status: OnboardingStatus.skipped,
      };

      const updatedCompany = await updateCompany({
        companyId: currentCompany.id,
        data: data,
      }).unwrap();
      dispatch(setCurrentCompany(updatedCompany));

      navigate('/dashboard');
    } catch (err) {
      // Even when getting an error while trying to skip, do not block the user, go to dashboard
      navigate('/dashboard');
    }
  };

  return (
    <div className='bg-lighter-blue flex flex-col items-center w-full min-h-screen overflow-hidden'>
      <Header firstCrumb={t('onboarding')} secondCrumb={t('companyProfile')} />
      <div className='bg-white w-5/6 h-[580px] mt-6 shadow-md'>
        <div className='px-20 py-5 flex flex-col h-full'>
          <div className='h-[50px] flex flex-row items-start justify-between'>
            <span className='text-3xl font-semibold decoration-2 decoration-vibrant-orange underline underline-offset-[13px]'>
              {t(currentStep)}
            </span>
            <StepIndicator
              currentStep={onboardingSteps.indexOf(currentStep) + 1}
              totalSteps={onboardingSteps.length}
            />
          </div>

          <Divider />

          {isLoadingCompany ? (
            <Loader />
          ) : (
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ setTouched }) => (
                <div className='flex flex-col items-center mt-6 h-full'>
                  {currentStep === 'welcome' && <WelcomeStep />}
                  <Form
                    id='onboarding-form'
                    className='flex flex-col w-full flex-grow gap-10 mt-6 relative'
                  >
                    {currentStep === 'data' && <CompanyDataStep />}
                    {currentStep === 'description' && (
                      <CompanyDescriptionStep />
                    )}
                  </Form>

                  <div className='mt-auto w-full flex flex-row justify-between'>
                    {/* Dummy div to make buttons centered */}
                    <div></div>
                    <div className='flex flex-row gap-4'>
                      {isFirstStep ? (
                        <Button variant='primary' onClick={handleNext}>
                          {t('next')}
                          <Right size='16px' color='#FFFFFF' />
                        </Button>
                      ) : (
                        <>
                          <Button variant='secondary' onClick={handleBack}>
                            {t('back')}
                            <Left size='16px' color='#8D91A0' />
                          </Button>
                          {isLastStep ? (
                            <Button
                              variant='primary'
                              key='submit-button'
                              form='onboarding-form'
                            >
                              {t('finish')}
                              <Right size='16px' color='#FFFFFF' />
                            </Button>
                          ) : (
                            <Button
                              key='next-button'
                              variant='primary'
                              onClick={() => handleStep(setTouched)}
                            >
                              {t('next')}
                              <Right size='16px' color='#FFFFFF' />
                            </Button>
                          )}
                        </>
                      )}
                    </div>
                    {isLastStep ? (
                      <span></span>
                    ) : (
                      <span
                        className='flex items-end text-slate-400 underline underline-offset-4 cursor-pointer'
                        onClick={handleSkip}
                      >
                        {t('skip')}
                      </span>
                    )}
                  </div>
                </div>
              )}
            </Formik>
          )}
        </div>
      </div>
    </div>
  );
};

export default Onboarding;
