import { cn } from 'common/utils';
import React from 'react';

const Mail = ({ size = 24, color = '#084D6E', onClick }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={cn({ 'cursor-pointer': onClick })}
    >
      <path d='M24 0H0V24H24V0Z' fill='white' fillOpacity='0.01' />
      <path
        d='M2 19.5H22V12V4.5H12H2V12V19.5Z'
        stroke={color}
        strokeWidth='1.6'
        strokeLinejoin='round'
      />
      <path
        d='M2 4.5L12 12L22 4.5'
        stroke={color}
        strokeWidth='1.6'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12 4.5H2V12'
        stroke={color}
        strokeWidth='1.6'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M22 12V4.5H12'
        stroke={color}
        strokeWidth='1.6'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default Mail;
