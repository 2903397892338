import { cva } from 'class-variance-authority';
import { cn } from 'common/utils';
import React, { useMemo } from 'react';

const Avatar = ({ className, size, name = '', ...props }) => {
  const getInitials = (displayName) => {
    const [firstName = '', lastName = ''] = (displayName || '').split(' ');
    return `${firstName.charAt(0)}${lastName.charAt(0)}`.toUpperCase();
  };

  const backgroundColor = useMemo(() => {
    const colors = [
      'bg-rose-700',
      'bg-cyan-700',
      'bg-emerald-700',
      'bg-orange-700',
      'bg-purple-700',
    ];

    let hash = 0;

    for (let i = 0; i < name.length; i++) {
      hash = name.charCodeAt(i) + ((hash << colors.length) - hash);
    }

    const index = Math.abs(hash % colors.length);

    return colors[index];
  }, [name]);

  const avatarVariants = cva(
    [
      'inline-flex items-center justify-center rounded-full',
      'text-white font-semibold pointer-events-none bg-',
      backgroundColor,
    ],
    {
      variants: {
        size: {
          small: 'h-6 w-6 text-xs',
          medium: 'h-10 w-10 text-md',
          large: 'h-12 w-12 text-lg',
          profile:
            'h-[88px] w-[88px] text-4xl border-2 border-ghost-white shadow',
        },
      },
      defaultVariants: {
        size: 'small',
      },
    },
  );

  return (
    <div className={cn(avatarVariants({ size, className }))} {...props}>
      {getInitials(name)}
    </div>
  );
};

export default Avatar;
