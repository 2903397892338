import { Menu, Transition } from '@headlessui/react';
import Down from 'assets/icons/Down';
import Up from 'assets/icons/Up';
import Avatar from 'common/components/Avatar';
import useNavigate from 'common/hooks/useNavigate';
import useTranslation from 'common/hooks/useTranslation';
import { apiSlice } from 'common/slices/api.slice';
import { cn } from 'common/utils';
import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { logOut, selectUser } from '../../../slices/auth.slice';

const ProfileDropdown = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation('common\\profile-dropdown');

  const { full_name, role } = useSelector(selectUser);

  const handleLogout = () => {
    dispatch(logOut());
    dispatch(apiSlice.util.resetApiState());
    navigate('/login');
  };

  return (
    <Menu as='div' className='relative inline-block'>
      {({ open }) => (
        <>
          <Menu.Button className='select-none'>
            <div className='flex items-center gap-2.5'>
              <Avatar size='medium' name={full_name} />
              <div className='flex flex-col items-start justify-center'>
                <h1 className='text-dark-charcoal max-w-[120px] font-semibold truncate'>
                  {full_name}
                </h1>
                {role && (
                  <h2 className='text-cool-grey text-xs max-w-[120px] truncate'>
                    {t(role.name)}
                  </h2>
                )}
              </div>
              {open ? <Up color='#8D91A0' /> : <Down color='#8D91A0' />}
            </div>
          </Menu.Button>
          <Transition
            as={Fragment}
            enter='transition ease-out duration-100'
            enterFrom='transform opacity-0 scale-95'
            enterTo='transform opacity-100 scale-100'
            leave='transition ease-in duration-75'
            leaveFrom='transform opacity-100 scale-100'
            leaveTo='transform opacity-0 scale-95'
          >
            <Menu.Items
              className={cn(
                'absolute right-0 mt-2 w-[200px] origin-top-right',
                'divide-y divide-ghost-white bg-white rounded-md shadow-lg',
                'ring-1 ring-dark-charcoal ring-opacity-5 focus:outline-none',
              )}
            >
              <Menu.Item>
                <button
                  className={cn(
                    'w-full px-4 py-3',
                    'text-sm text-left text-gray-700',
                    'rounded-md hover:bg-vibrant-orange hover:bg-opacity-10',
                  )}
                  onClick={handleLogout}
                >
                  {t('logout')}
                </button>
              </Menu.Item>
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
};

export default ProfileDropdown;
