import { cn } from 'common/utils';
import React from 'react';

const Error = ({ size = 24, color = '#8D91A0', onClick }) => {
  return (
    <svg
      className={cn({ 'cursor-pointer': onClick })}
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 18 18'
      fill='none'
      onClick={onClick}
    >
      <path
        d='M9 0C13.977 0 18 4.023 18 9C18 13.977 13.977 18 9 18C4.023 18 0 13.977 0 9C0 4.023 4.023 0 9 0ZM12.231 4.5L9 7.731L5.769 4.5L4.5 5.769L7.731 9L4.5 12.231L5.769 13.5L9 10.269L12.231 13.5L13.5 12.231L10.269 9L13.5 5.769L12.231 4.5Z'
        fill={color}
      />
    </svg>
  );
};

export default Error;
