import Danger from 'assets/icons/Danger';
import Button from 'common/components/Button';
import Card from 'common/components/Card';
import LabelledInput from 'common/components/LabelledInput';
import Loader from 'common/components/Loader';
import useNavigate from 'common/hooks/useNavigate';
import { useToast } from 'common/hooks/useToast';
import useTranslation from 'common/hooks/useTranslation';
import insightsTracker from 'common/insights_tracker';
import {
  selectCurrentCompany,
  selectCurrentToken,
  setCredentials,
} from 'common/slices/auth.slice';
import { useSignupMutation } from 'common/slices/authApi.slice';
import { cn } from 'common/utils';
import { Form, Formik } from 'formik';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ShowFeature } from 'split';
import * as Yup from 'yup';

const SignUpForm = () => {
  const toast = useToast();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation('auth\\sign-up');

  const [signup, { isLoading }] = useSignupMutation();

  const token = useSelector(selectCurrentToken);
  const currentCompany = useSelector(selectCurrentCompany);

  useEffect(() => {
    if (token && currentCompany) {
      navigate('/onboarding');
    }
  }, [token, currentCompany]);

  const initialValues = {
    default_company: '',
    full_name: '',
    position: '',
    email: '',
    password: '',
  };

  const validationSchema = Yup.object({
    default_company: Yup.string().required(t('status.company-required')),
    full_name: Yup.string().required(t('status.name-required')),
    position: Yup.string().required(t('status.position-required')),
    email: Yup.string()
      .email(t('status.invalid-email'))
      .required(t('status.email-required')),
    password: Yup.string().required(t('status.password-required')),
  });

  const handleSubmit = async (values, { setStatus, setSubmitting }) => {
    setStatus('');

    try {
      const data = { ...values };

      const response = await signup(data).unwrap();

      insightsTracker.signupSuccess();

      dispatch(setCredentials({ ...response }));

      toast.newToast('positive', t('toasts.welcome'));

      navigate('/onboarding');
    } catch (err) {
      switch (err?.status) {
        case 400:
          const message = err?.data.errors[0].detail;
          // toast.newToast('negative', message);
          setStatus(message);
          break;
        default:
          // toast.newToast('negative', t('toasts.something-wrong'));
          setStatus(t('status.something-wrong'));
      }
    }

    setSubmitting(false);
  };

  return (
    <Card shadowed>
      <div className='bg-vibrant-yellow rounded-t-lg h-2 w-full' />
      <div className='flex flex-col justify-center pt-10 sm:px-[72px] px-5 pb-12 w-full relative'>
        <div className='w-[300px]'>
          <h1 className='text-center font-semibold text-3xl text-dark-blue pb-6'>
            {t('welcome')}
          </h1>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ status }) => (
              <Form className='flex flex-col gap-4 w-full'>
                <LabelledInput
                  type='text'
                  label={t('company')}
                  placeholder={t('company')}
                  name='default_company'
                />
                <div className='grid grid-cols-2 gap-2'>
                  <LabelledInput
                    type='text'
                    label={t('name')}
                    placeholder={t('name')}
                    name='full_name'
                  />
                  <LabelledInput
                    type='text'
                    label={t('position')}
                    placeholder={t('position')}
                    name='position'
                  />
                </div>
                <LabelledInput
                  type='email'
                  label={t('email')}
                  placeholder={t('email')}
                  name='email'
                />
                <LabelledInput
                  type='password'
                  label={t('password')}
                  placeholder={t('password')}
                  name='password'
                />
                {status && (
                  <div
                    className={cn(
                      'text-bittersweet-shimmer text-xs font-semibold',
                      'flex justify-start items-center gap-1',
                    )}
                  >
                    <Danger size='14px' color='#C2514B' />
                    {status}
                  </div>
                )}
                <Button variant='primary' type='submit'>
                  {t('sign-up')}
                </Button>
              </Form>
            )}
          </Formik>
        </div>
        <ShowFeature featureFlag='LINKED_IN_LOG_IN'>
          <div className='flex items-center py-4'>
            <div className='flex-grow h-px bg-dark-blue'></div>
            <span className='flex-shrink px-4 text-xs uppercase'>
              {t('or')}
            </span>
            <div className='flex-grow h-px bg-dark-blue'></div>
          </div>
        </ShowFeature>
        <div className='flex flex-col w-full items-center'>
          <div className='flex flex-col max-w-fit'>
            <ShowFeature featureFlag='LINKED_IN_LOG_IN'>
              <Button variant='linkedin'>{t('linked-in')}</Button>
            </ShowFeature>
            <div className='flex flex-row mt-4 gap-2 text-dark-blue text-sm items-center justify-center'>
              {t('has-account')}
              <span
                className='text-vibrant-orange text-xs font-semibold uppercase underline cursor-pointer'
                onClick={() => navigate('/login')}
              >
                {t('login')}
              </span>
            </div>
          </div>
        </div>
        {isLoading && <Loader />}
      </div>
    </Card>
  );
};

export default SignUpForm;
