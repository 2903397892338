import { Listbox, Transition } from '@headlessui/react';
import Down from 'assets/icons/Down';
import Trash from 'assets/icons/Trash';
import Up from 'assets/icons/Up';
import useTranslation from 'common/hooks/useTranslation';
import { cn } from 'common/utils';
import { Field, useFormikContext } from 'formik';
import { isEmpty } from 'lodash';
import React, { Fragment } from 'react';

const listboxStyles = {
  button: (meta) =>
    cn(
      'rounded-md px-4 py-2 w-full flex justify-between items-center mt-1.5',
      'text-dark-blue border border-dark-blue outline-dark-blue',
      'disabled:border-soft-blue disabled:text-soft-blue',
      'disabled:bg-white disabled:cursor-not-allowed',
      {
        'text-bittersweet-shimmer border-bittersweet-shimmer outline-bittersweet-shimmer placeholder-bittersweet-shimmer':
          meta.error && meta.touched,
      },
    ),
  list: (meta) =>
    cn(
      'mt-1 w-full rounded-md bg-white text-base shadow-lg',
      'ring-1 ring-black ring-opacity-5 focus:outline-none',
      'max-h-[180px] absolute cursor-pointer',
      'overflow-y-auto scrollbar scrollbar-w-1',
      'scrollbar-thumb-vibrant-orange scrollbar-thumb-rounded',
      'scrollbar-track-rounded z-50',
      {
        'text-bittersweet-shimmer border-bittersweet-shimmer outline-bittersweet-shimmer placeholder-bittersweet-shimmer':
          meta.error && meta.touched,
      },
    ),
  option: ({ active }) =>
    cn(
      'flex w-full items-center',
      'px-4 py-2 text-sm gap-2.5',
      'select-none truncate text-black',
      {
        'bg-vibrant-orange bg-opacity-10': active,
      },
    ),
};

const QuestionCombo = ({ index, options, remove }) => {
  const { values, setFieldValue } = useFormikContext();
  const { t } = useTranslation('offers\\details');

  const currentValues = values.questions[index];

  const dataTypeConditionals = {
    number: [
      { gt: t('filters.conditionals.gt') },
      { gte: t('filters.conditionals.gte') },
      { lt: t('filters.conditionals.lt') },
      { lte: t('filters.conditionals.lte') },
      { eq: t('filters.conditionals.eq') },
      { neq: t('filters.conditionals.neq') },
    ],
    boolean: [{ boolean: 'boolean' }],
    text: [
      { eq: t('filters.conditionals.eq') },
      { neq: t('filters.conditionals.neq') },
      { contains: t('filters.conditionals.contains') },
    ],
  };

  return (
    <div className='mb-4'>
      <div className='flex flex-row justify-between w-full items-center'>
        <label className='text-sonic-silver text-sm uppercase font-semibold'>
          {`${t('filters.question-filter')} ${index + 1}`}
        </label>
        <Trash size='24px' color='#7A7A7A' onClick={() => remove(index)} />
      </div>
      <div className='flex flex-col'>
        <Field name={`questions.${index}.question`}>
          {({ field, meta }) => (
            <>
              <Listbox
                value={field.value ? field.value : ''}
                onChange={(value) => {
                  setFieldValue(`questions.${index}.conditional`, {});
                  setFieldValue(`questions.${index}.answer`, '');
                  field.onChange({
                    target: { value, name: field.name },
                  });
                }}
              >
                {({ open }) => (
                  <div className='relative'>
                    <Listbox.Button className={listboxStyles.button(meta)}>
                      <p className='truncate'>
                        {field.value?.name
                          ? field.value?.name
                          : t('filters.placeholders.question')}
                      </p>
                      {open ? <Up /> : <Down />}
                    </Listbox.Button>
                    <Transition
                      as={Fragment}
                      leave='transition ease-in duration-100'
                      leaveFrom='opacity-100'
                      leaveTo='opacity-0'
                    >
                      <Listbox.Options className={listboxStyles.list(meta)}>
                        {options.map((option, index) => (
                          <Listbox.Option
                            key={index}
                            className={listboxStyles.option}
                            value={option}
                          >
                            {option.name}
                          </Listbox.Option>
                        ))}
                      </Listbox.Options>
                    </Transition>
                  </div>
                )}
              </Listbox>
              {meta.touched && meta.error && (
                <div className='text-bittersweet-shimmer text-xs font-semibold mt-1.5'>
                  {meta.error}
                </div>
              )}
            </>
          )}
        </Field>
        <div className='flex flex-row gap-1.5'>
          {currentValues.question?.data_type === 'boolean' ? (
            <div className='flex flex-col w-full max-w-full'>
              <Field
                type='hidden'
                name={`questions.${index}.conditional`}
                value={{ boolean: 'boolean' }}
              />
              <Field name={`questions.${index}.answer`}>
                {({ field, meta }) => (
                  <>
                    <Listbox
                      value={field.value ? field.value : ''}
                      onChange={(value) => {
                        const isEmpty = Object.keys(value)[0] === 'isempty';
                        const isFilled = Object.keys(value)[0] === 'isfilled';

                        console.log(isEmpty, isFilled);

                        if (isEmpty || isFilled) {
                          setFieldValue(`questions.${index}.conditional`, {
                            isnull: isEmpty ? value.isempty : value.isfilled,
                          });

                          field.onChange({
                            target: { value: isEmpty, name: field.name },
                          });
                        } else {
                          setFieldValue(`questions.${index}.conditional`, {
                            boolean: 'boolean',
                          });

                          field.onChange({
                            target: { value, name: field.name },
                          });
                        }
                      }}
                      disabled={isEmpty(currentValues.question)}
                    >
                      {({ open }) => (
                        <div className='relative'>
                          <Listbox.Button
                            className={listboxStyles.button(meta)}
                          >
                            <p className='truncate'>
                              {Object.keys(currentValues.conditional)[0] ===
                              'isnull'
                                ? currentValues.answer === true
                                  ? t('filters.conditionals.isempty')
                                  : t('filters.conditionals.isfilled')
                                : currentValues.answer !== ''
                                ? currentValues.answer
                                  ? t('filters.answers.yes')
                                  : t('filters.answers.no')
                                : t('filters.placeholders.answer')}
                            </p>
                            {open ? <Up /> : <Down />}
                          </Listbox.Button>
                          <Transition
                            as={Fragment}
                            leave='transition ease-in duration-100'
                            leaveFrom='opacity-100'
                            leaveTo='opacity-0'
                          >
                            <Listbox.Options
                              className={listboxStyles.list(meta)}
                            >
                              <Listbox.Option
                                className={listboxStyles.option}
                                value={true}
                              >
                                {t('filters.answers.yes')}
                              </Listbox.Option>
                              <Listbox.Option
                                className={listboxStyles.option}
                                value={false}
                              >
                                {t('filters.answers.no')}
                              </Listbox.Option>
                              <Listbox.Option
                                className={listboxStyles.option}
                                value={{
                                  isempty: t('filters.conditionals.isempty'),
                                }}
                              >
                                {t('filters.conditionals.isempty')}
                              </Listbox.Option>
                              <Listbox.Option
                                className={listboxStyles.option}
                                value={{
                                  isfilled: t('filters.conditionals.isfilled'),
                                }}
                              >
                                {t('filters.conditionals.isfilled')}
                              </Listbox.Option>
                            </Listbox.Options>
                          </Transition>
                        </div>
                      )}
                    </Listbox>
                    {meta.touched &&
                      meta.error &&
                      !isEmpty(currentValues.question) && (
                        <div className='text-bittersweet-shimmer text-xs font-semibold mt-1.5'>
                          {meta.error}
                        </div>
                      )}
                  </>
                )}
              </Field>
            </div>
          ) : (
            <>
              <Field name={`questions.${index}.conditional`}>
                {({ field, meta }) => (
                  <div
                    className={cn(
                      'flex flex-col w-1/2 max-w-[calc(50%-0.1875rem)]',
                      {
                        'w-full max-w-full':
                          Object.keys(currentValues.conditional)[0] ===
                          'isnull',
                      },
                    )}
                  >
                    <Listbox
                      value={field.value ? field.value : ''}
                      onChange={(value) => {
                        const isEmpty = Object.keys(value)[0] === 'isempty';
                        const isFilled = Object.keys(value)[0] === 'isfilled';

                        if (isEmpty || isFilled) {
                          field.onChange({
                            target: {
                              value: {
                                isnull: isEmpty
                                  ? value.isempty
                                  : value.isfilled,
                              },
                              name: field.name,
                            },
                          });

                          setFieldValue(`questions.${index}.answer`, isEmpty);
                        } else {
                          const isAnswerSet =
                            currentValues.answer === true ||
                            currentValues.answer === false;

                          if (isAnswerSet) {
                            setFieldValue(`questions.${index}.answer`, '');
                          }

                          field.onChange({
                            target: { value, name: field.name },
                          });
                        }
                      }}
                      disabled={isEmpty(currentValues.question)}
                    >
                      {({ open }) => (
                        <div className='relative'>
                          <Listbox.Button
                            className={listboxStyles.button(meta)}
                          >
                            <p className='truncate'>
                              {!isEmpty(currentValues.conditional)
                                ? Object.values(currentValues.conditional)[0]
                                : t('filters.placeholders.conditional')}
                            </p>
                            {open ? <Up /> : <Down />}
                          </Listbox.Button>
                          <Transition
                            as={Fragment}
                            leave='transition ease-in duration-100'
                            leaveFrom='opacity-100'
                            leaveTo='opacity-0'
                          >
                            <Listbox.Options
                              className={listboxStyles.list(meta)}
                            >
                              {dataTypeConditionals[
                                currentValues.question.data_type
                              ]?.map((conditional, index) => (
                                <Listbox.Option
                                  key={index}
                                  className={listboxStyles.option}
                                  value={conditional}
                                >
                                  {Object.values(conditional)[0]}
                                </Listbox.Option>
                              ))}
                              <Listbox.Option
                                className={listboxStyles.option}
                                value={{
                                  isempty: t('filters.conditionals.isempty'),
                                }}
                              >
                                {t('filters.conditionals.isempty')}
                              </Listbox.Option>
                              <Listbox.Option
                                className={listboxStyles.option}
                                value={{
                                  isfilled: t('filters.conditionals.isfilled'),
                                }}
                              >
                                {t('filters.conditionals.isfilled')}
                              </Listbox.Option>
                            </Listbox.Options>
                          </Transition>
                        </div>
                      )}
                    </Listbox>
                    {meta.touched &&
                      meta.error &&
                      !isEmpty(currentValues.question) && (
                        <div className='text-bittersweet-shimmer text-xs font-semibold mt-1.5'>
                          {meta.error}
                        </div>
                      )}
                  </div>
                )}
              </Field>
              {Object.keys(currentValues.conditional)[0] === 'isnull' ? (
                <Field type='hidden' name={`questions.${index}.answer`} />
              ) : (
                <Field name={`questions.${index}.answer`}>
                  {({ field, meta }) => (
                    <div className='flex flex-col w-1/2 max-w-[calc(50%-0.1875rem)]'>
                      <input
                        className={cn(
                          'text-input text-dark-blue border rounded-md px-4 py-2',
                          'border-dark-blue outline-dark-blue mt-1.5',
                          'disabled:border-soft-blue disabled:text-soft-blue',
                          'disabled:bg-white disabled:cursor-not-allowed',
                          {
                            'text-bittersweet-shimmer border-bittersweet-shimmer outline-bittersweet-shimmer placeholder-bittersweet-shimmer':
                              meta.error &&
                              meta.touched &&
                              !isEmpty(currentValues.conditional),
                          },
                        )}
                        type='text'
                        placeholder={t('filters.placeholders.value')}
                        disabled={isEmpty(currentValues.conditional)}
                        {...field}
                      />
                      {meta.touched &&
                        meta.error &&
                        !isEmpty(currentValues.conditional) && (
                          <div className='text-bittersweet-shimmer text-xs font-semibold mt-1.5'>
                            {meta.error}
                          </div>
                        )}
                    </div>
                  )}
                </Field>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default QuestionCombo;
