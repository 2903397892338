import { PlaceholderEmptyState } from 'common/components/PlaceholderEmptyState';
import useTranslation from 'common/hooks/useTranslation';
import { cn } from 'common/utils';
import DOMPurify from 'dompurify';
import React from 'react';

export const DescriptionTab = ({ offerDescription }) => {
  const { t } = useTranslation('offers\\details');

  return offerDescription ? (
    <div className='grid grid-cols-1 gap-4'>
      <div
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(offerDescription.replace(/\n/g, '<br />')),
        }}
        className={cn('p-4', 'text-dark-liver text-sm text-justify')}
      />
    </div>
  ) : (
    <PlaceholderEmptyState className='pt-4' text={t('noDescription')} />
  );
};
