import { cn } from 'common/utils';
import React from 'react';

const Show = ({ size = 24, color = '#8D91A0', onClick }) => {
  return (
    <svg
      className={cn({ 'cursor-pointer': onClick })}
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 25 24'
      fill='none'
    >
      <g clipPath='url(#clip0_2801_46136)'>
        <path
          d='M24.0254 0H0.0253906V24H24.0254V0Z'
          fill='white'
          fillOpacity='0.01'
        />
        <path
          d='M12.0254 18C17.5482 18 22.0254 12 22.0254 12C22.0254 12 17.5482 6 12.0254 6C6.50254 6 2.02539 12 2.02539 12C2.02539 12 6.50254 18 12.0254 18Z'
          stroke={color}
          strokeWidth='1.6'
          strokeLinejoin='round'
        />
        <path
          d='M12.0254 14.5C13.4061 14.5 14.5254 13.3807 14.5254 12C14.5254 10.6193 13.4061 9.5 12.0254 9.5C10.6447 9.5 9.52539 10.6193 9.52539 12C9.52539 13.3807 10.6447 14.5 12.0254 14.5Z'
          fill={color}
        />
      </g>
      <defs>
        <clipPath id='clip0_2801_46136'>
          <rect
            width={size}
            height={size}
            fill='white'
            transform='translate(0.0253906)'
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Show;
