import { usePdf } from '@mikecousins/react-pdf';
import Attention from 'assets/icons/Attention';
import Down from 'assets/icons/Down';
import Up from 'assets/icons/Up';
import Loader from 'common/components/Loader';
import useTranslation from 'common/hooks/useTranslation';
import { cn } from 'common/utils';
import { isNil } from 'lodash';
import React, { useRef, useState } from 'react';

const PdfViewer = ({ cvUrl }) => {
  const [page, setPage] = useState(1);
  const canvasRef = useRef(null);

  const { t } = useTranslation('offers\\details');

  const { pdfDocument } = usePdf({
    file: cvUrl,
    page,
    canvasRef,
    scale: 1.1,
  });

  const previousDisabled = page === 1;
  const nextDisabled = page === pdfDocument?.numPages;

  return (
    <>
      {!pdfDocument ? (
        <Loader />
      ) : (
        <div className='w-full h-full bg-ghost-white p-2.5 flex flex-col flex-grow'>
          <div className='w-full p-5 bg-white flex flex-col flex-grow items-center overflow-hidden relative'>
            <canvas ref={canvasRef} />
            <div
              className={cn(
                'absolute right-4 top-4 flex py-1 px-2',
                'bg-ghost-white rounded gap-2 items-center',
              )}
            >
              <div className='flex items-center gap-1'>
                <Up
                  color='#342E30'
                  onClick={() => setPage((prev) => prev - 1)}
                  disabled={previousDisabled}
                />
                <Down
                  color='#342E30'
                  onClick={() => setPage((prev) => prev + 1)}
                  disabled={nextDisabled}
                />
              </div>
              <p className='text-dark-charcoal text-sm mr-1'>
                {t('page')} <span className='font-semibold'>{page}</span>{' '}
                {t('of')} {pdfDocument?.numPages}
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const CandidateCurriculum = ({ cvUrl }) => {
  const { t } = useTranslation('offers\\details');

  return (
    <>
      {!isNil(cvUrl) ? (
        <PdfViewer cvUrl={cvUrl} />
      ) : (
        <div className='w-full h-full bg-ghost-white p-2.5 flex flex-col flex-grow'>
          <div className='w-full bg-white p-5 flex flex-col flex-grow'>
            <div className='h-full w-full gap-6 flex flex-col justify-center items-center'>
              <Attention size='64px' color='#F16347' />
              <h1 className='text-dark-charcoal text-xl font-semibold '>
                {t('no-curriculum')}
              </h1>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CandidateCurriculum;
