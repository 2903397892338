import { cn } from 'common/utils';
import React from 'react';

export const OfferIntroduction = ({ description, setDescription }) => {
  const handleChange = (e) => {
    setDescription(e.target.value);
  };

  return (
    <div className='grid grid-cols-1 gap-4'>
      <textarea
        value={description}
        className={cn(
          'border border-dark-blue rounded',
          'bg-ghost-white p-4',
          'text-dark-blue text-sm',
          'w-full h-[300px]',
        )}
        onChange={handleChange}
      />
    </div>
  );
};
