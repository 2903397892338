import React from 'react';

import Toast from './Toast';

const ToastContainer = ({ toasts }) => {
  return (
    <div className='fixed z-50 bottom-8 -right-[200px] flex flex-col gap-2'>
      {toasts.map((toast, index) => (
        <Toast key={toast.id} {...toast} />
      ))}
    </div>
  );
};

export default ToastContainer;
