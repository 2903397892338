import useTranslation from 'common/hooks/useTranslation';
import { cn } from 'common/utils';
import React from 'react';

const Footer = () => {
  const { t } = useTranslation('auth\\footer');

  return (
    <div
      className={cn(
        'h-20 w-full mt-12',
        'flex flex-col justify-center items-center',
        'bg-white rounded-t-lg shadow',
        'lg:hidden',
      )}
    >
      <span className='text-soft-blue text-'>{t('copyright')}</span>
    </div>
  );
};

export default Footer;
