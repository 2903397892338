import { cn } from 'common/utils';
import React from 'react';

const Ranking = ({ size = 24, color = '#8D91A0', onClick }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      width={size}
      height={size}
      viewBox='0 0 24 24'
      className={cn({ 'cursor-pointer': onClick })}
    >
      <path d='M24 0H0V24H24V0Z' fill={color} fillOpacity='0.01' />
      <path
        d='M8.5 9H2V21H8.5V9Z'
        stroke={color}
        strokeWidth='1.6'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M15 3H8.5V21H15V3Z'
        stroke={color}
        strokeWidth='1.6'
        strokeLinejoin='round'
      />
      <path
        d='M21.5 13H15V21H21.5V13Z'
        stroke={color}
        strokeWidth='1.6'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default Ranking;
