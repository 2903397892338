import { cva } from 'class-variance-authority';
import { cn } from 'common/utils';
import React, { useState } from 'react';

const Tooltip = ({ content, alignment, children }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const tooltipVariants = cva(
    ['relative flex flex-1', { 'cursor-pointer': content }],
    {
      variants: {
        alignment: {
          start: 'justify-start',
          center: 'justify-center',
          end: 'justify-end',
        },
      },
      defaultVariants: {
        alignment: 'center',
      },
    },
  );

  return (
    <div className={cn(tooltipVariants({ alignment }))}>
      <div
        onClick={() => setShowTooltip(!showTooltip)}
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
      >
        {children}
      </div>
      <div
        className={cn(
          'absolute top-8 shadow w-max',
          'bg-raisin-black text-white rounded p-2',
          'pointer-events-none transition-all duration-300 z-50',
          'text-xs text-white font-semibold',
          {
            'opacity-100': showTooltip,
            'hidden opacity-0': !showTooltip,
          },
        )}
      >
        {content}
      </div>
    </div>
  );
};

export default Tooltip;
