import { cn } from 'common/utils';
import React from 'react';

const GradientBackground = ({ fromColor, toColor, children }) => {
  return (
    <div
      className={cn(
        'flex-1  h-full hidden lg:block',
        `bg-gradient-to-br ${fromColor} from-40% ${toColor}`,
      )}
    >
      {children}
    </div>
  );
};

export default GradientBackground;
