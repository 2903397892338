import { cn } from 'common/utils';
import React from 'react';

const Hide = ({ size = 24, color = '#8D91A0', onClick }) => {
  return (
    <svg
      className={cn({ 'cursor-pointer': onClick })}
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 21 21'
      fill='none'
    >
      <g clipPath='url(#clip0_2800_15918)'>
        <path
          d='M20.5254 0.5H0.525391V20.5H20.5254V0.5Z'
          fill='white'
          fillOpacity='0.01'
        />
        <path
          d='M3.02539 7.16666C3.28986 7.6747 3.69058 8.14599 4.20154 8.56474C5.63414 9.73886 7.93331 10.5 10.5254 10.5C13.1175 10.5 15.4166 9.73886 16.8492 8.56474C17.3602 8.14599 17.7609 7.6747 18.0254 7.16666'
          stroke={color}
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path d='M12.5996 10.5L13.4623 13.7197Z' fill={color} />
        <path
          d='M12.5996 10.5L13.4623 13.7197'
          stroke={color}
          strokeWidth='1.6'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M16.0889 9.39734L18.4459 11.7543'
          stroke={color}
          strokeWidth='1.6'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M2.6084 11.7543L4.96541 9.39734'
          stroke={color}
          strokeWidth='1.6'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M7.57812 13.7197L8.44087 10.5'
          stroke={color}
          strokeWidth='1.6'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_2800_15918'>
          <rect
            width='20'
            height='20'
            fill='white'
            transform='translate(0.525391 0.5)'
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Hide;
