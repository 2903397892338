import React from 'react';

export const OfferTableItemSkeleton = () => {
  return (
    <tr className='h-[72px] border-b border-bright-grey'>
      <td className='whitespace-nowrap min-w-fit  w-[190px]'>
        <div className='h-[18px] w-[74px] bg-bright-grey rounded' />
      </td>
      <td className='whitespace-nowrap min-w-fit w-[233px]'>
        <div className='h-[18px] w-[190px] bg-bright-grey rounded' />
      </td>
      <td className='whitespace-nowrap min-w-fit w-[231px]'>
        <div className='flex items-center '>
          <div className='flex items-center pr-2 gap-1.5'>
            <div className='h-[18px] w-[30px] bg-bright-grey rounded' />
            <div className='h-[18px] w-[8px] bg-bright-grey rounded' />
          </div>
          <div className='flex items-center pr-2 gap-1.5'>
            <div className='h-[18px] w-[30px] bg-bright-grey rounded' />
            <div className='h-[18px] w-[8px] bg-bright-grey rounded' />
          </div>
          <div className='flex items-center pr-2 gap-1.5'>
            <div className='h-[18px] w-[30px] bg-bright-grey rounded' />
            <div className='h-[18px] w-[8px] bg-bright-grey rounded' />
          </div>
          <div className='flex items-center'>
            <div className='h-[18px] w-[30px] bg-bright-grey rounded' />
          </div>
        </div>
      </td>
      <td className='whitespace-nowrap min-w-fit w-[136px]'>
        <div className='h-[18px] w-[52px] bg-bright-grey rounded' />
      </td>
      <td className='whitespace-nowrap min-w-fit w-[161px]'>
        <div className='h-[18px] w-[66px] bg-bright-grey rounded' />
      </td>
      <td className='whitespace-nowrap min-w-fit w-[209px]'>
        <div className='h-[18px] w-[106px] bg-bright-grey rounded' />
      </td>
      <td className='whitespace-nowrap min-w-fit'>
        <div className='h-[18px] w-[119px] bg-bright-grey rounded' />
      </td>
    </tr>
  );
};
