import { cn } from 'common/utils';
import React from 'react';

const Attention = ({ size = 24, color = '#8D91A0', onClick }) => {
  return (
    <svg
      className={cn({ 'cursor-pointer': onClick })}
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 64 65'
      fill='none'
    >
      <g clipPath='url(#clip0_3329_38315)'>
        <path
          d='M64 0.613281H0V64.6133H64V0.613281Z'
          fill='white'
          fillOpacity='0.01'
        />
        <path
          d='M32.0007 59.2796C39.3644 59.2796 46.0311 56.2948 50.8568 51.4691C55.6825 46.6434 58.6673 39.9767 58.6673 32.613C58.6673 25.2492 55.6825 18.5826 50.8568 13.7568C46.0311 8.93106 39.3644 5.94629 32.0007 5.94629C24.6369 5.94629 17.9703 8.93106 13.1445 13.7568C8.31876 18.5826 5.33398 25.2492 5.33398 32.613C5.33398 39.9767 8.31876 46.6434 13.1445 51.4691C17.9703 56.2948 24.6369 59.2796 32.0007 59.2796Z'
          stroke={color}
          strokeWidth='2.5'
          strokeLinejoin='round'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M32.0009 48.8173C33.2182 48.8173 34.2049 47.8305 34.2049 46.6132C34.2049 45.396 33.2182 44.4092 32.0009 44.4092C30.7837 44.4092 29.7969 45.396 29.7969 46.6132C29.7969 47.8305 30.7837 48.8173 32.0009 48.8173Z'
          fill={color}
        />
        <path
          d='M32 16.6133V37.9466'
          stroke={color}
          strokeWidth='2.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_3329_38315'>
          <rect
            width='64'
            height='64'
            fill='white'
            transform='translate(0 0.613281)'
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Attention;
