import Add from 'assets/icons/Add';
import useTranslation from 'common/hooks/useTranslation';
import { cn } from 'common/utils';
import React from 'react';

const QuestionsEmptyState = () => {
  const { t } = useTranslation('offers\\details');

  return (
    <div
      className={cn('flex w-full h-60', 'border border-bright-grey rounded')}
    >
      <div className='flex flex-col items-center justify-center px-4 py-8'>
        <Add size='80px' color='#F16347' />
        <h4 className='text-center text-raisin-black font-semibold leading-8'>
          {t('filters.empty-state.title')}
        </h4>
        <p className='text-center text-sm text-cool-grey leading-snug'>
          {t('filters.empty-state.description')}
        </p>
      </div>
    </div>
  );
};

export default QuestionsEmptyState;
