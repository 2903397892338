import Button from 'common/components/Button';
import LabelledInput from 'common/components/LabelledInput';
import Select from 'common/components/Select';
import { useToast } from 'common/hooks/useToast';
import useTranslation from 'common/hooks/useTranslation';
import { useGenerateOfferDetailsMutation } from 'common/slices/offerApi.slice';
import { useFormikContext } from 'formik';
import React from 'react';

import { LoadingResultState } from '../../../common/components/LoadingResultState';
import { OfferIntroduction } from './OfferIntroduction';

export const DescriptionStep = ({
  availabIndustries,
  availableTones,
  description,
  setDescription,
  setDisableNavigationButtons,
  assistant_id,
  setAssistantID,
}) => {
  const toast = useToast();
  const formik = useFormikContext();

  const { t } = useTranslation('offers\\new');

  const [generateOfferDetails, { isLoading }] =
    useGenerateOfferDetailsMutation();

  const handleClick = async () => {
    try {
      setDisableNavigationButtons(true);
      const { positionName, industry, tone, keywords } = formik.values;
      const response = await generateOfferDetails({
        title: positionName,
        industry: industry.value,
        keywords: keywords,
        tone: tone.value,
        assistant_id: assistant_id,
      }).unwrap();

      setDescription(response.description);
      setAssistantID(response.assistant_id);
    } catch (err) {
      switch (err?.status) {
        case 400:
          toast.newToast('negative', err?.data.errors[0].detail);
          break;
        case 403:
          toast.newToast('negative', err?.data.errors[0].detail);
          break;
        case 502:
          toast.newToast('negative', err?.data.errors[0].detail);
          break;
        default:
          toast.newToast('negative', t('toasts.something-wrong'));
      }
    } finally {
      setDisableNavigationButtons(false);
    }
  };

  return (
    <React.Fragment>
      <div className='w-2/3 pb-8'>
        <p className='text-sm text-dark-liver font-normal pb-8'>
          {t('proposalMessage')}
        </p>
        <div className='flex w-full gap-4'>
          <div className='flex flex-col w-1/2'>
            <Select
              name='industry'
              label={t('industryLabel')}
              options={availabIndustries}
              isWithAvatar={false}
              translationPath='offers\\new'
            />
          </div>
          <div className='flex flex-col w-1/2'>
            <Select
              name='tone'
              label={t('toneLabel')}
              options={availableTones}
              isWithAvatar={false}
              translationPath='offers\\new'
            />
          </div>
        </div>
        <div className='flex w-full gap-4'>
          <div className='flex flex-col w-4/5 pt-3'>
            <LabelledInput
              type='text'
              label={t('keywords')}
              name='keywords'
              placeholder={t('keywordsLabel')}
            />
          </div>
          <div className='flex items-end w-1/5 justify-end'>
            <Button
              variant='primary'
              disabled={isLoading}
              onClick={handleClick}
              className='h-[42px]'
            >
              {t('generateButton')}
            </Button>
          </div>
        </div>
        {isLoading ? (
          <div className='flex gap-3'>
            <LoadingResultState text={t('generatingDescription')} />
          </div>
        ) : (
          <div className='grid grid-cols-1 gap-4 pt-8 '>
            <h1 className='text-sm text-dark-liver font-semibold uppercase'>
              {t('generatedText')}
            </h1>
            <OfferIntroduction
              description={description}
              setDescription={setDescription}
            />
          </div>
        )}
      </div>
    </React.Fragment>
  );
};
