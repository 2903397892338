import { apiSlice } from 'common/slices/api.slice';

export const userApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCurrentUserSettings: builder.query({
      query: () => ({
        url: '/users/me/settings',
      }),
      providesTags: ['Setting'],
    }),
    updateCurrentUserSettings: builder.mutation({
      query: (data) => ({
        url: '/users/me/settings',
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: ['Setting'],
    }),
  }),
});

export const {
  useGetCurrentUserSettingsQuery,
  useUpdateCurrentUserSettingsMutation,
} = userApiSlice;
