import Right from 'assets/icons/Right';
import Button from 'common/components/Button';
import Chip from 'common/components/Chip';
import useNavigate from 'common/hooks/useNavigate';
import useTranslation from 'common/hooks/useTranslation';
import { formatDate } from 'common/utils';
import React from 'react';

import { findStatusState } from '../details/components/StatusCombo';

export const OfferTableItem = ({ created_at, name, slug, status }) => {
  const navigate = useNavigate();

  const { t, i18n } = useTranslation('offers\\index');

  return (
    <tr className='h-[72px] border-b border-bright-grey'>
      <td className='whitespace-nowrap'>
        <p className='text-sonic-silver'>
          {formatDate(created_at, i18n, 'numeric')}
        </p>
      </td>
      <td className='whitespace-nowrap'>
        <p className='font-semibold text-raisin-black'>{name}</p>
      </td>
      <td className='whitespace-nowrap'>
        <div className='flex items-center '>
          {status && (
            <Chip
              className='uppercase'
              variant={findStatusState(status)?.cssVariant}
            >
              {t(`${findStatusState(status).id.toLowerCase()}`)}
            </Chip>
          )}
        </div>
      </td>
      <td className='whitespace-nowrap' align='right'>
        <Button variant='secondary' onClick={() => navigate(`/offers/${slug}`)}>
          {t('see-offer')}
          <Right size='16px' color='#8D91A0' />
        </Button>
      </td>
    </tr>
  );
};
