import { clsx } from 'clsx';
import { enUS, es, pt } from 'date-fns/locale';
import { twMerge } from 'tailwind-merge';

const cn = (...inputs) => {
  return twMerge(clsx(inputs));
};

const getDateLocale = (language) => {
  switch (language) {
    case 'en':
      return enUS;
    case 'pt':
      return pt;
    default:
      return es;
  }
};

const formatDate = (dateString, i18n, format = 'long', withTime = false) => {
  const date = new Date(dateString);

  const formatterOptions = {
    day: '2-digit',
    month: format === 'numeric' ? '2-digit' : 'long',
    year: 'numeric',
    hour: withTime ? '2-digit' : undefined,
    minute: withTime ? '2-digit' : undefined,
    second: withTime ? '2-digit' : undefined,
  };

  const currentLocale = i18n.language || 'en';
  const formatter = new Intl.DateTimeFormat(currentLocale, formatterOptions);

  return formatter.format(date);
};

const queryBuilder = (basePath, params = {}) => {
  let queryUrl = basePath;

  const queryParams = [];

  for (var param in params) {
    if (param === 'page' && params[param] > 1) {
      queryParams.push(`page=${params[param]}`);
    }

    if (param === 'size' && params[param] > 0) {
      queryParams.push(`size=${params[param]}`);
    }

    if (param === 'orderBy' && params[param]) {
      queryParams.push(`order_by=${params[param]}`);
    }

    if (param === 'status' && params[param]) {
      queryParams.push(`status=${params[param]}`);
    }

    if (param === 'name' && params[param]) {
      queryParams.push(`name=${params[param]}`);
    }

    if (param === 'slug' && params[param]) {
      queryParams.push(`slug=${params[param]}`);
    }

    if (param === 'location' && params[param]) {
      queryParams.push(`location=${params[param]}`);
    }

    if (param === 'withDetail' && params[param]) {
      queryParams.push(`with_detail=${params[param]}`);
    }

    if (param === 'with_owner' && params[param]) {
      queryParams.push(`with_owner=${params[param]}`);
    }

    if (param === 'with_company' && params[param]) {
      queryParams.push(`with_company=${params[param]}`);
    }

    if (param === 'with_managers' && params[param]) {
      queryParams.push(`with_managers=${params[param]}`);
    }

    if (param === 'phase' && params[param]) {
      queryParams.push(`phase=${params[param]}`);
    }
    if (param === 'isFavorite' && params[param]) {
      queryParams.push(`is_favorite=${params[param]}`);
    }

    if (param === 'search' && params[param]) {
      queryParams.push(`q=${params[param]}`);
    }
  }

  if (queryParams.length > 0) {
    queryUrl += `?${queryParams.join('&')}`;
  }

  return queryUrl;
};

function capitalizeFirstLetter(string) {
  return string
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
}

const formatCandidateName = (candidate, isFullName = false) => {
  const fullName = candidate?.last_name
    ? capitalizeFirstLetter(`${candidate?.first_name} ${candidate?.last_name}`)
    : capitalizeFirstLetter(candidate?.first_name);

  if (isFullName) return fullName;

  const nameParts = fullName.split(' ');
  const firstName = nameParts[0];
  const lastName = nameParts.length > 1 ? nameParts[nameParts.length - 1] : '';

  return lastName ? `${firstName} ${lastName}` : firstName;
};

export { cn, formatDate, queryBuilder, getDateLocale, formatCandidateName };
