import { cn } from 'common/utils';
import React from 'react';

const Card = ({ bordered, shadowed, onClick, children }) => {
  return (
    <div
      className={cn('flex flex-col', 'bg-white rounded-lg', {
        shadow: shadowed,
        'border border-bright-grey': bordered,
        'cursor-pointer': onClick,
      })}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

export default Card;
