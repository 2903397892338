import { cn } from 'common/utils';
import React from 'react';

const List = ({ size = 24, color = '#8D91A0', onClick }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      fill='none'
      viewBox='0 0 16 16'
      className={cn({ 'cursor-pointer': onClick })}
      onClick={onClick}
    >
      <rect
        x='2'
        y='11.333'
        width='11.9906'
        height='2.66667'
        rx='1'
        fill={color}
      />
      <rect
        x='2'
        y='6.66016'
        width='11.9906'
        height='2.66667'
        rx='1'
        fill={color}
      />
      <rect
        x='2'
        y='1.9873'
        width='11.9906'
        height='2.66667'
        rx='1'
        fill={color}
      />
    </svg>
  );
};

export default List;
