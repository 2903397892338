import { cn } from 'common/utils';
import React from 'react';

const Comment = ({ size = 24, color = '#8D91A0', onClick }) => {
  return (
    <svg
      className={cn({ 'cursor-pointer': onClick })}
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 25 25'
      fill='none'
    >
      <g clipPath='url(#clip0_2217_90365)'>
        <path
          d='M24.6484 0.573822H0.648438V24.5738H24.6484V0.573822Z'
          fill='white'
          fillOpacity='0.01'
        />
        <path
          d='M22.6484 3.57382H2.64844V18.5738H7.14844V21.0738L12.1484 18.5738H22.6484V3.57382Z'
          stroke={color}
          strokeWidth='1.6'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M7.64844 10.3238V11.8238'
          stroke={color}
          strokeWidth='1.6'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M12.6484 10.3238V11.8238'
          stroke={color}
          strokeWidth='1.6'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M17.6484 10.3238V11.8238'
          stroke={color}
          strokeWidth='1.6'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_2217_90365'>
          <rect
            width={size}
            height={size}
            fill='white'
            transform='translate(0.648438 0.573822)'
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Comment;
