import { cn } from 'common/utils';
import React from 'react';

const Brain = ({ size = 24, color = '#8D91A0', onClick }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 26 21'
      fill='none'
      className={cn({ 'cursor-pointer': onClick })}
    >
      <path
        d='M21.9539 5.14006C21.9183 5.97885 21.6297 6.84851 21.1334 7.64379C20.9053 8.00334 20.6508 8.32481 20.3973 8.60027C20.0223 8.99994 19.5928 9.35071 19.1225 9.63222C18.5013 9.96634 17.5961 10.3298 16.6269 10.3679C16.5985 10.3684 16.57 10.3687 16.5415 10.3687C16.246 10.3687 15.9762 10.3451 15.7292 10.3022C14.8644 10.1334 13.974 9.62779 13.2033 8.49235C14.3942 7.08601 15.0499 5.70353 15.1711 4.4038C15.3003 3.01861 14.8172 1.77769 13.8268 0.772503C13.9314 0.729243 14.0322 0.676733 14.1274 0.615814C15.8924 -0.513427 19.3783 -0.311702 21.4578 3.11502C21.8256 3.81089 21.9682 4.49251 21.9539 5.14006Z'
        fill={color}
      />
      <path
        d='M13.7858 10.3687C14.2689 10.724 14.8566 10.9953 15.5832 11.1226C15.7919 11.1628 16.0007 11.1874 16.208 11.1982C16.3569 11.206 16.5045 11.2067 16.6503 11.2012C17.5968 11.1827 18.4865 10.9241 19.2784 10.4996C19.8453 10.9628 20.8503 11.6621 22.0862 11.8517C22.1052 11.855 22.1242 11.8583 22.1431 11.8615C21.7888 12.7753 21.234 13.8399 20.3343 14.5325C19.3137 15.3182 17.7607 15.689 15.311 14.5673C14.5716 13.7763 13.2643 12.6395 11.8289 11.6365C10.4516 10.6743 8.901 9.79571 7.58972 9.49407C7.40133 8.98273 7.14637 8.44507 6.80501 7.9558C6.38485 7.323 5.76458 6.70103 4.79984 6.38789C4.74262 6.36914 4.68462 6.35178 4.62582 6.33585C4.37793 6.2679 4.10859 6.21976 3.81566 6.19592L3.79488 6.19423L3.77404 6.19466C2.92193 6.21234 2.22362 6.36288 1.65285 6.60083C1.56099 5.9613 1.64051 5.18834 2.07994 4.51881C2.62106 3.69432 3.7757 2.92605 6.11407 2.8239L6.11831 2.82522C6.15479 2.83688 6.20706 2.85435 6.27319 2.87841C6.41902 2.93147 6.63182 3.01644 6.89095 3.14166C7.01132 3.19982 7.14152 3.26659 7.27951 3.34279C7.78614 3.6302 8.29781 3.99436 8.79552 4.40781L8.83405 4.44054C9.0045 4.59089 9.16585 4.73532 9.31981 4.87636C10.2801 5.78077 11.3208 7.01811 12.3346 8.67195C12.6709 9.23817 13.1151 9.87546 13.7858 10.3687Z'
        fill={color}
      />
      <path
        d='M21.7424 8.22752C21.7727 8.18133 21.8026 8.13477 21.8318 8.08788C21.9039 7.97405 21.9735 7.85714 22.04 7.73738C22.4518 6.99518 22.7521 6.12473 22.7786 5.18282C22.8093 4.50846 22.7041 3.83707 22.4342 3.208C22.5735 3.19589 22.7352 3.19079 22.9136 3.19824C23.3665 3.21716 23.8864 3.31569 24.3654 3.55219C24.8396 3.78638 25.2752 4.15598 25.5768 4.72903C25.8798 5.30469 26.0632 6.11561 25.9799 7.2559C25.813 9.54181 24.8359 10.6497 23.6911 10.9774C23.5461 11.0189 23.3958 11.0487 23.2412 11.0669H22.7188C22.5567 11.0669 22.3976 11.0547 22.2421 11.0323C21.527 10.9077 20.7691 10.5752 20.0415 10.039C20.3466 9.81503 20.6878 9.512 21.0226 9.14501C21.2895 8.85753 21.5307 8.54954 21.7424 8.22752Z'
        fill={color}
      />
      <path
        d='M9.34575 15.3702L14.0971 15.3359C13.3856 16.096 12.4887 17.0637 11.6933 17.9242C11.1553 18.5064 10.6632 19.0401 10.3056 19.4282L9.72491 20.0591C9.71519 20.0697 9.70593 20.0808 9.69735 20.0924L9.69622 20.0939L9.69005 20.102C9.68431 20.1095 9.67537 20.1211 9.66354 20.1362C9.63984 20.1663 9.60473 20.21 9.5606 20.2623C9.47163 20.3677 9.34934 20.5039 9.21248 20.6328C9.07283 20.7644 8.93273 20.8745 8.80873 20.9408C8.68751 21.0056 8.63597 21.0002 8.63097 20.9997C8.56604 20.9803 8.49419 20.9222 8.41699 20.7604C8.35972 20.6404 8.32545 20.5254 8.28742 20.3977C8.26985 20.3387 8.25146 20.277 8.22964 20.2109C8.02943 19.6042 7.76057 18.7572 7.54129 18.0604C7.43176 17.7123 7.33478 17.4022 7.26519 17.1791L7.23765 17.0908L9.34575 15.3702Z'
        fill={color}
      />
      <path
        d='M8.93921 14.6303L6.49131 16.6206C5.0772 17.585 4.18343 17.5156 3.62683 17.153C3.03373 16.7666 2.64499 15.9436 2.4794 15.0015C2.60168 14.4186 2.88231 13.5723 3.28786 12.7882C3.4774 12.4285 3.69717 12.0758 3.94737 11.7521C3.98508 11.7033 4.02338 11.6553 4.06225 11.6081C4.18138 11.4698 4.298 11.3404 4.41504 11.2205C4.85523 10.7852 5.35935 10.4596 5.93171 10.3088C6.2834 10.2307 6.70002 10.2091 7.21187 10.2673C8.80514 10.6191 11.4203 12.0593 14.0452 14.5027L9.19561 14.5377C9.1025 14.5384 9.01175 14.5714 8.93921 14.6303Z'
        fill={color}
      />
      <path
        d='M0.142834 12.1464C0.342535 12.9706 0.792048 13.8305 1.77592 14.3932C1.93833 13.7874 2.19825 13.0811 2.56096 12.3934C2.80912 11.9151 3.1022 11.4532 3.43689 11.0642C3.54951 10.9283 3.6681 10.7967 3.79268 10.6709C4.12979 10.3208 4.50411 10.004 4.96171 9.77497C5.19047 9.66046 5.43515 9.57019 5.70057 9.50701C5.9887 9.42883 6.29304 9.38467 6.61363 9.38164C6.4718 9.05767 6.30876 8.70715 6.08892 8.37757C5.83912 8.03146 5.54412 7.72749 5.1981 7.49832C4.40327 6.9719 3.25413 6.78559 1.55713 7.57593C1.21546 7.77723 0.957987 8.01592 0.761652 8.26037C0.251446 8.89562 0.107971 9.6269 0.0572319 10.0288L0.056633 10.0336C0.0223656 10.305 -0.0883026 11.1924 0.142834 12.1464Z'
        fill={color}
      />
      <path
        d='M9.65097 0.359953C9.66883 0.357445 9.6867 0.355032 9.70458 0.352716C9.88013 0.331195 10.0538 0.31999 10.2224 0.319528C10.5096 0.318741 10.785 0.328076 11.0494 0.356246C11.9181 0.474978 12.7062 0.819506 13.2335 1.35211C14.0721 2.19914 14.454 3.20447 14.3494 4.32562C14.2548 5.34069 13.7571 6.49013 12.7605 7.72746C12.4227 7.15724 11.9743 6.53021 11.453 5.90554C11.1747 5.56373 10.8914 5.25087 10.586 4.94158C10.4961 4.84874 10.4049 4.75668 10.3126 4.66561C10.0069 4.36386 9.68565 4.06978 9.35371 3.79228C8.98659 3.47037 8.62424 3.20161 8.28433 2.97868C7.72857 2.60537 7.21714 2.34001 6.77323 2.16689C6.78785 2.14233 6.80293 2.11724 6.81844 2.09177C6.92173 1.92209 7.03453 1.75056 7.14343 1.6066C7.76663 0.913557 8.67264 0.497323 9.65097 0.359953Z'
        fill={color}
      />
    </svg>
  );
};

export default Brain;
