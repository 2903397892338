import Attention from 'assets/icons/Attention';
import useTranslation from 'common/hooks/useTranslation';
import { isBoolean, isEmpty } from 'lodash';
import React from 'react';

const CandidateQuestions = ({ questions }) => {
  const { t } = useTranslation('offers\\details');

  const formatAnswer = (answer) => {
    if (isBoolean(answer)) {
      return t(answer.toString());
    } else return answer;
  };

  return (
    <>
      {isEmpty(questions) ? (
        <div className='w-full h-full bg-ghost-white p-2.5 flex flex-col flex-grow'>
          <div className='w-full bg-white p-5 flex flex-col flex-grow'>
            <div className='h-full w-full gap-6 flex flex-col justify-center items-center'>
              <Attention size='64px' color='#F16347' />
              <h1 className='text-dark-charcoal text-xl font-semibold '>
                {t('no-questions')}
              </h1>
            </div>
          </div>
        </div>
      ) : (
        <div className='w-full h-full bg-ghost-white p-2.5 flex flex-col flex-grow'>
          <div className='w-full bg-white p-5 flex flex-col flex-grow'>
            <div className='h-full w-full flex flex-col divide-y '>
              {questions.map((entry, index) => (
                <div
                  key={index}
                  className='flex flex-col w-full gap-4 px-2 py-5 first:pt-0'
                >
                  <div className='flex justify-between items-center'>
                    <div className='flex items-center gap-2'>
                      <h4 className='uppercase font-semibold text-dark-liver'>
                        {entry.question}
                      </h4>
                    </div>
                  </div>
                  <p className='text-sm text-cool-grey px-4'>
                    {entry.description}
                  </p>
                  <p className='text-sm px-4'>{formatAnswer(entry.answer)}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CandidateQuestions;
