import { cn } from 'common/utils';
import React from 'react';

const Gravatar = ({ size = 24, color = '#8D91A0', onClick }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 25 24'
      fill='none'
      className={cn({ 'cursor-pointer': onClick })}
    >
      <g clipPath='url(#clip0_3208_41503)'>
        <path
          d='M10.3497 2.39897V10.7985C10.3497 12.1234 11.4238 13.1975 12.7488 13.1975C14.0737 13.1975 15.1477 12.1234 15.1477 10.7984V5.21044C18.1725 6.27684 20.1273 9.21544 19.942 12.4173C19.7567 15.6192 17.4759 18.3126 14.3483 19.0229C11.2207 19.7332 8.00025 18.2893 6.45 15.4815C4.89984 12.6737 5.39362 9.17916 7.66106 6.91088C8.58234 5.97019 8.57456 4.46306 7.64353 3.53212C6.71249 2.601 5.20537 2.59322 4.26478 3.51459C0.216839 7.56234 -0.408005 13.9059 2.77256 18.6659C5.95303 23.4257 12.0529 25.2759 17.3418 23.0851C22.6306 20.8942 25.6352 15.2723 24.5182 9.65784C23.4009 4.04316 18.4733 -0.000374974 12.7488 2.60812e-08C11.4238 2.60812e-08 10.3497 1.07409 10.3497 2.39897Z'
          fill={color}
        />
      </g>
      <defs>
        <clipPath id='clip0_3208_41503'>
          <rect
            width='24'
            height='24'
            fill='white'
            transform='translate(0.75)'
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Gravatar;
