import Danger from 'assets/icons/Danger';
import Money from 'assets/icons/Money';
import People from 'assets/icons/People';
import Right from 'assets/icons/Right';
import Card from 'common/components/Card';
import Chip from 'common/components/Chip';
import useNavigate from 'common/hooks/useNavigate';
import { cn } from 'common/utils';
import React from 'react';
import { ShowFeature } from 'split';

const OpenOffer = ({
  id,
  slug,
  isClosing,
  hasWarning,
  dueDate,
  position,
  salaryRange,
  candidates,
  inAssessment,
  inClassification,
  inInterview,
}) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/offers/${slug}`);
  };

  return (
    <Card key={id} bordered onClick={handleClick}>
      <div className='w-[224px] flex flex-col'>
        <div
          className={cn('rounded-t-lg h-1 w-full', {
            'bg-vibrant-orange': isClosing,
            'bg-inherit': !isClosing,
          })}
        />
        <div className='flex flex-col px-6 py-2.5 gap-3'>
          <div className='h-7 flex gap-2 items-center justify-start'>
            {isClosing && <Chip>Closing soon</Chip>}
            <p className='text-sonic-silver text-xs '>{dueDate}</p>
          </div>
          <div className='flex flex-col gap-4'>
            <h1 className='text-raisin-black font-semibold truncate'>
              {position}
            </h1>
            <div className='flex flex-col gap-1 justify-center'>
              <div className='flex gap-2 items-center'>
                <Money size='16px' color='#7A7A7A' />
                <p className='text-sonic-silver'>{salaryRange}</p>
              </div>
              <ShowFeature featureFlag='WEB_NO_ACTION_UI'>
                <div className='flex gap-2 items-center'>
                  <People size='16px' color='#7A7A7A' />
                  <div className='flex gap-1 items-center text-sonic-silver'>
                    <span>{candidates}</span>
                    <Right size='12px' color='#C4C4C4' />
                    <span>{inAssessment}</span>
                    <Right size='12px' color='#C4C4C4' />
                    <span>{inClassification}</span>
                    <Right size='12px' color='#C4C4C4' />
                    <span>{inInterview}</span>
                  </div>
                  {hasWarning && <Danger size='16px' color='#FA2F2F' />}
                </div>
              </ShowFeature>
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default OpenOffer;
