import useTranslation from 'common/hooks/useTranslation';
import React from 'react';
import { Pie, PieChart, ResponsiveContainer } from 'recharts';

const LocationPieChart = ({ location }) => {
  const { t } = useTranslation('offers\\details');

  const colorList = ['#084D6E', '#8292A2', '#8DAEB5', '#EDEDED'];
  const data = Object.entries(location).map(([name, value], index) => ({
    name,
    value,
    fill: colorList[index],
  }));

  return (
    <div className='flex justify-center items-center w-full max-w-[300px] h-16 gap-2.5 px-4'>
      <ResponsiveContainer height='100%' width={60}>
        <PieChart>
          <Pie
            data={data}
            startAngle={90}
            endAngle={-270}
            fill='#F16347'
            dataKey='value'
          />
        </PieChart>
      </ResponsiveContainer>
      <div className='flex flex-col ml-6'>
        <h4 className='text-xs uppercase font-bold text-dark-liver'>
          {t('charts.location')}
        </h4>
        <div className='flex flex-wrap gap-x-2'>
          {data.map((entry, index) => (
            <div key={index} className='flex items-center gap-1'>
              <span
                className='rounded-full h-2 w-2'
                style={{ backgroundColor: entry.fill }}
              />
              <p className='text-[10px] max-w-[96px] truncate'>{entry.name}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default LocationPieChart;
