import React from 'react';

const BottomEllipse = () => (
  <svg
    id='bottom-elipse'
    width='202'
    height='73'
    viewBox='0 0 202 73'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className='right-[76px] top-[214px] absolute z-0'
  >
    <path
      d='M1.72946 33.4507C1.57062 37.9993 3.98726 42.5568 8.79511 46.9224C13.5986 51.284 20.687 55.3534 29.5855 58.8935C47.3741 65.9702 72.1856 70.8486 99.7942 71.8127C127.403 72.7768 152.494 69.6411 170.733 63.8224C179.857 60.9117 187.212 57.3467 192.308 53.3308C197.408 49.3113 200.137 44.9334 200.296 40.3848C200.455 35.8362 198.038 31.2787 193.23 26.9132C188.427 22.5516 181.338 18.4821 172.44 14.9421C154.651 7.86534 129.84 2.98695 102.231 2.02283C74.6227 1.05872 49.5313 4.19447 31.2924 10.0131C22.1686 12.9238 14.8137 16.4889 9.71763 20.5048C4.61697 24.5243 1.8883 28.9021 1.72946 33.4507Z'
      stroke='#8DAEB5'
      strokeWidth='2'
    />
  </svg>
);

export default BottomEllipse;
