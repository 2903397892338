import Add from 'assets/icons/Add';
import EditProcess from 'assets/icons/EditProcess';
import People from 'assets/icons/People';
import Button from 'common/components/Button';
import Divider from 'common/components/Divider';
import useNavigate from 'common/hooks/useNavigate';
import useTranslation from 'common/hooks/useTranslation';
import { useGetOffersQuery } from 'common/slices/offerApi.slice';
import React, { useState } from 'react';
import { ShowFeature } from 'split';

import { OfferTab } from './components/OfferTab';
import { OfferTable } from './components/OfferTable';
import { OfferTableSkeleton } from './components/OfferTableSkeleton';
import { OffersSkeleton } from './components/OffersSkeleton';

const Offers = () => {
  const navigate = useNavigate();
  const [filter, setFilter] = useState('');

  const { t } = useTranslation('offers\\index');

  const { data: openOffers, isLoading } = useGetOffersQuery({
    size: 20,
    status: filter,
    with_owner: true,
    with_company: true,
  });

  return (
    <div className='flex flex-col h-full w-full max-w-full p-6'>
      {isLoading ? (
        <>
          <OffersSkeleton />
          <OfferTableSkeleton />
        </>
      ) : (
        <div className='flex flex-col w-full h-full'>
          <div className='flex items-center justify-between mb-4'>
            <div className='flex items-center gap-6'>
              <h1 className='text-3xl text-dark-liver font-semibold'>
                {t('processes')}
              </h1>
              <ShowFeature featureFlag='WEB_NO_ACTION_UI'>
                <div className='flex items-center gap-1.5'>
                  <People size='20px' color='#7A7A7A' />
                  <p className='text-sm text-sonic-silver'>12,034</p>
                </div>
              </ShowFeature>
              <div className='flex items-center gap-1.5'>
                <EditProcess size='20px' color='#7A7A7A' />
                <p className='text-sm text-sonic-silver'>{openOffers?.total}</p>
              </div>
            </div>
            <Button
              variant='primary'
              onClick={() => navigate('/offers/new-offer')}
            >
              <Add size='16px' color='#FFFFFF' />
              {t('add-offer')}
            </Button>
          </div>
          <div className='flex items-center justify-between mb-3.5 z-10'>
            <OfferTab filter={filter} setFilter={setFilter} />
          </div>
          <Divider />
          <OfferTable
            openOffers={openOffers}
            isLoading={isLoading}
            currentTab={filter}
          />
        </div>
      )}
    </div>
  );
};

export default Offers;
