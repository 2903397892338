import { cn } from 'common/utils';
import React from 'react';

const ArrowUpRight = ({ size = 24, color = '#8D91A0', onClick }) => {
  return (
    <div className={cn({ 'cursor-pointer': onClick })}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width={size}
        height={size}
        viewBox='0 0 24 24'
        fill='none'
      >
        <g clipPath='url(#clip0_633_550)'>
          <path d='M24 0H0V24H24V0Z' fill='white' fillOpacity='0.01' />
          <path
            d='M9.5 5.5H18.5V14.5'
            stroke={color}
            strokeWidth='1.6'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            d='M5.77209 18.2279L18.5 5.5'
            stroke={color}
            strokeWidth='1.6'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </g>
        <defs>
          <clipPath id='clip0_633_550'>
            <rect width='24' height='24' fill='white' />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default ArrowUpRight;
