import { Dialog, Transition } from '@headlessui/react';
import Refresh from 'assets/icons/Refresh';
import Right from 'assets/icons/Right';
import Button from 'common/components/Button';
import Loader from 'common/components/Loader';
import { useToast } from 'common/hooks/useToast';
import useTranslation from 'common/hooks/useTranslation';
import { useSyncOfferMutation } from 'common/slices/offerApi.slice';
import { cn } from 'common/utils';
import React, { Fragment } from 'react';

const SyncModal = ({ offerSlug, isOpen, setIsOpen, setSyncing }) => {
  const toast = useToast();
  const { t } = useTranslation('offers\\details');

  const [syncOffer, { isLoading }] = useSyncOfferMutation();

  const closeModal = () => {
    setIsOpen(false);
  };

  const handleSync = async () => {
    try {
      await syncOffer({ offerSlug }).unwrap();

      toast.newToast('positive', t('modals.sync.toasts.success'));
      setSyncing(true);
    } catch (err) {
      switch (err?.status) {
        case 403:
          toast.newToast('negative', t('modals.sync.toasts.forbidden'));
          break;
        default:
          toast.newToast('negative', t('modals.sync.toasts.something-wrong'));
      }
    } finally {
      closeModal();
    }
  };

  return (
    <Fragment>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as='div'
          className='relative z-50'
          onClose={isLoading ? () => null : closeModal}
        >
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='fixed inset-0 bg-black bg-opacity-25' />
          </Transition.Child>
          <div className='fixed inset-0 overflow-y-auto'>
            <div className='flex min-h-full items-center justify-center p-4 text-center'>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 scale-95'
                enterTo='opacity-100 scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 scale-100'
                leaveTo='opacity-0 scale-95'
              >
                <Dialog.Panel
                  className={cn(
                    'flex flex-col w-full max-w-md transform overflow-hidden',
                    'rounded bg-white p-10 text-left gap-6',
                    'align-middle shadow-xl transition-all relative',
                  )}
                >
                  <Dialog.Title
                    as='div'
                    className='flex flex-col w-full items-center gap-8'
                  >
                    <Refresh size='64px' color='#084D6E' />
                    <h1 className=' text-2xl text-center font-semibold text-dark-charcoal'>
                      {t('modals.sync.title')}
                    </h1>
                  </Dialog.Title>
                  <Dialog.Description className='text-sm text-cool-grey text-center'>
                    {t('modals.sync.description')}
                  </Dialog.Description>
                  <div className='w-full flex items-center justify-center gap-2'>
                    <Button variant='secondary' onClick={closeModal}>
                      {t('modals.sync.buttons.cancel')}
                    </Button>
                    <Button variant='primary' onClick={handleSync}>
                      {t('modals.sync.buttons.continue')}
                      <Right size='16px' color='#FFFFFF' />
                    </Button>
                  </div>
                  {isLoading && <Loader />}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </Fragment>
  );
};

export default SyncModal;
