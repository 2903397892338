import { selectUserGender } from 'common/slices/auth.slice';
import { useTranslation as useT } from 'react-i18next';
import { useSelector } from 'react-redux';

const useTranslation = (ns) => {
  const currentUserGender = useSelector(selectUserGender);

  const { t, i18n, ready } = useT(ns, { useSuspense: false });

  const translateWithGenderContext = (key, options) => {
    if (currentUserGender === 'male') {
      return t(key, { ...options, context: 'male' });
    } else {
      return t(key, { ...options, context: 'female' });
    }
  };

  return {
    i18n,
    t: (key, options) =>
      ready ? translateWithGenderContext(key, options) : null,
  };
};

export default useTranslation;
