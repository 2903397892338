import React from 'react';

const Bookmark = ({ size = 24, color = '#FA8072', fillColor = 'none' }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill={fillColor}
    >
      <path
        d='M1.5 1H16.5V21L9 15.7143L1.5 21V1Z'
        stroke={color}
        strokeWidth='1.6'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default Bookmark;
