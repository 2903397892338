import BottomEllipse from 'assets/drawings/BottomEllipse';
import LeftHand from 'assets/drawings/LeftHand';
import RightHand from 'assets/drawings/RightHand';
import TopEllipse from 'assets/drawings/TopEllipse';
import { LogoFull } from 'assets/logos';
import React from 'react';
import { Outlet } from 'react-router-dom';

import Footer from './components/Footer';
import GradientBackground from './components/GradientBackground';

const Auth = () => {
  document.title = 'Validate Talent';

  return (
    <div className='flex flex-row min-h-screen lg:h-screen w-full bg-cultured'>
      <GradientBackground
        fromColor='from-vibrant-orange'
        toColor='to-vibrant-yellow'
      >
        <div className='relative h-full overflow-hidden'>
          <div className='w-[450px] right-16 top-16 absolute text-right font-poppins text-yellow-400 text-7xl font-bold z-10 select-none'>
            “Validate before your hire”
          </div>
          <TopEllipse />
          <BottomEllipse />
          <LeftHand />
          <RightHand />
        </div>
      </GradientBackground>
      <div className='flex-1 flex flex-col h-full min-h-screen w-1/2 justify-between lg:justify-center items-center'>
        <LogoFull className='lg:hidden mt-5 mb-10 w-[142px] h-10 md:w-[200px] md:h-[56px]' />
        <Outlet />
        <Footer />
      </div>
    </div>
  );
};

export default Auth;
