import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  items: [],
};

const toastSlice = createSlice({
  name: 'toast',
  initialState,
  reducers: {
    addToast: (state, action) => {
      const updatedToasts = [...state.items, action.payload];

      state.items = updatedToasts;
    },
    removeToast: (state, action) => {
      const updatedToasts = state.items.filter(
        (toast) => toast.id !== action.payload,
      );

      state.items = updatedToasts;
    },
  },
});

export const { addToast, removeToast } = toastSlice.actions;

export default toastSlice.reducer;

export const selectCurrentToasts = (state) => state.toast.items || [];
