import { cn } from 'common/utils';
import React from 'react';

const Info = ({ size = 24, color = '#8D91A0', onClick }) => {
  return (
    <svg
      className={cn({ 'cursor-pointer': onClick })}
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 16 17'
      fill='none'
      onClick={onClick}
    >
      <path
        d='M8 0.5C3.58172 0.5 0 4.08172 0 8.5C0 12.9183 3.58172 16.5 8 16.5C12.4183 16.5 16 12.9183 16 8.5C16 4.08172 12.4183 0.5 8 0.5ZM7.85 2.65C8.59945 2.60912 9.24014 3.18355 9.281 3.933C9.28367 3.97567 9.284 4.01867 9.282 4.062C9.31243 4.45032 9.17137 4.83251 8.89594 5.10794C8.62051 5.38337 8.23832 5.52443 7.85 5.494C7.46335 5.51734 7.08524 5.37383 6.81144 5.09984C6.53763 4.82584 6.39439 4.44763 6.418 4.061C6.38814 3.31098 6.97198 2.67878 7.722 2.649C7.76467 2.64767 7.80733 2.648 7.85 2.65ZM10 13C10 13.2761 9.77614 13.5 9.5 13.5H6.5C6.22386 13.5 6 13.2761 6 13V12C6 11.7239 6.22386 11.5 6.5 11.5H7V8.5H6.5C6.22386 8.5 6 8.27614 6 8V7C6 6.72386 6.22386 6.5 6.5 6.5H8.5C8.77614 6.5 9 6.72386 9 7V11.5H9.5C9.77614 11.5 10 11.7239 10 12V13Z'
        fill={color}
      />
    </svg>
  );
};

export default Info;
