import LoaderAnimation from 'assets/animations/animation-loader.gif';
import React from 'react';

export const LoadingResultState = ({ text }) => {
  return (
    <div className='grid grid-cols-1 w-full gap-4 pt-8'>
      <div className='grid grid-cols-1 gap-4'>
        <div className='h-full w-full bg-ghost-white rounded border border-dark-blue flex items-center justify-center flex-col py-8'>
          <div className='flex items-center'>
            <img src={LoaderAnimation} alt='Loader animation' />
          </div>
          <div className='text-center pt-4'>{text}</div>
        </div>
      </div>
    </div>
  );
};
