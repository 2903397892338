import ArrowUpRight from 'assets/icons/ArrowUpRight';
import useTranslation from 'common/hooks/useTranslation';
import { cn } from 'common/utils';
import React from 'react';

const OffersEmptyState = () => {
  const { t } = useTranslation('dashboard\\offers-empty-state');

  return (
    <div
      className={cn(
        'flex flex-col w-full',
        'bg-white rounded-lg',
        'border border-bright-grey',
      )}
    >
      <div className='flex gap-2 justify-center items-center h-[130px] mx-6 relative'>
        <p className='text-raisin-black'>{t('empty-state')}</p>
        <ArrowUpRight color='#F16347' />
      </div>
    </div>
  );
};

export default OffersEmptyState;
