import Attention from 'assets/icons/Attention';
import useTranslation from 'common/hooks/useTranslation';
import { cn } from 'common/utils';
import { isEmpty, isNull } from 'lodash';
import React from 'react';

const CandidateEvaluation = ({ tests }) => {
  const { t } = useTranslation('offers\\details');

  const formatTestValue = (value, label) => {
    if (isNull(value)) {
      return 'N/A';
    }
    return label === 'EVALUATION' ? `${value}%` : value;
  };

  const getTestValueColor = (value, label) => {
    if (isNull(value)) {
      return 'bg-gray-100 text-gray-500';
    }
    if (label !== 'EVALUATION') {
      return 'bg-gray-200 text-gray-500';
    }
    if (value >= 80) {
      return 'bg-green-100 text-green-500';
    } else if (value < 80 && value >= 50) {
      return 'bg-yellow-100 text-yellow-500';
    } else if (value < 50) {
      return 'bg-red-100 text-red-500';
    }
  };

  return (
    <>
      {isEmpty(tests) ? (
        <div className='w-full h-full bg-ghost-white p-2.5 flex flex-col flex-grow'>
          <div className='w-full bg-white p-5 flex flex-col flex-grow'>
            <div className='h-full w-full gap-6 flex flex-col justify-center items-center'>
              <Attention size='64px' color='#F16347' />
              <h1 className='text-dark-charcoal text-xl font-semibold '>
                {t('no-test-results')}
              </h1>
            </div>
          </div>
        </div>
      ) : (
        <div className='w-full h-full bg-ghost-white p-2.5 flex flex-col flex-grow'>
          <div className='w-full bg-white p-5 flex flex-col flex-grow'>
            <div className='h-full w-full flex flex-col divide-y '>
              {tests.map((test, index) => (
                <div
                  key={index}
                  className='flex flex-col w-full gap-4 px-2 py-5 first:pt-0'
                >
                  <div className='flex justify-between items-center'>
                    <div className='flex items-center gap-2'>
                      <div
                        className={cn(
                          'px-2 py-1 font-semibold rounded flex justify-center',
                          getTestValueColor(test.value, test.label),
                        )}
                      >
                        {formatTestValue(test.value, test.label)}
                      </div>
                      <h4 className='uppercase font-semibold text-dark-liver'>
                        {test.name}
                      </h4>
                    </div>
                  </div>
                  <p className='text-sm'>{test.description}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CandidateEvaluation;
