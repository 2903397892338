import Favorite from 'assets/icons/Favorite';
import { cn } from 'common/utils';
import React, { useEffect, useState } from 'react';

const OfferSwitch = ({ setIsFavoriteParam, labelActive, labelInactive }) => {
  const [showFavorites, setShowFavorites] = useState(false);

  const toggleFavorites = () => {
    setShowFavorites((prev) => !prev);
  };

  useEffect(() => {
    if (showFavorites === true) {
      setIsFavoriteParam(true);
    } else {
      setIsFavoriteParam(null);
    }
  }, [showFavorites]);

  return (
    <div className='flex items-center cursor-pointer' onClick={toggleFavorites}>
      <div
        className={cn(
          'relative flex items-center justify-center',
          'w-[54px] h-[14px] rounded-full',
          'transition-colors duration-300 ease-in-out',
        )}
        style={{
          position: 'relative',
          backgroundColor: 'transparent',
        }}
      >
        <div
          style={{
            position: 'absolute',
            width: '34px',
            height: '18px',
            backgroundColor: showFavorites
              ? 'rgba(241, 99, 71, 0.8)'
              : 'rgb(180, 180, 180)',
            borderRadius: '20px',
            opacity: '0.35',
            transition: 'background-color 0.3s ease',
            zIndex: 1,
          }}
        />
        <div
          style={{
            position: 'absolute',
            width: '22px',
            height: '22px',
            backgroundColor: showFavorites ? '#F16347' : 'rgb(180, 180, 180)',
            borderRadius: '50%',
            top: '50%',
            left: showFavorites ? 'calc(100% - 22px)' : '2px',
            transform: 'translateY(-50%)',
            transition: 'left 0.3s ease',
            zIndex: 2,
          }}
        />
      </div>
      <span className='ml-1 text-sm font-medium'>
        {showFavorites ? labelActive : labelInactive}
      </span>
      <div className='relative -ml-0-5'>
        <Favorite color={showFavorites ? '#F16347' : 'rgb(180, 180, 180)'} />
      </div>
    </div>
  );
};

export default OfferSwitch;
