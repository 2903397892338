import { cn } from 'common/utils';
import React from 'react';

const People = ({ size = 24, color = '#8D91A0', onClick }) => {
  return (
    <svg
      className={cn({ 'cursor-pointer': onClick })}
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 20 13'
      fill='none'
    >
      <path
        d='M10 0.393555C10.7735 0.393555 11.5154 0.700846 12.0624 1.24783C12.6094 1.79481 12.9167 2.53667 12.9167 3.31022C12.9167 4.08377 12.6094 4.82563 12.0624 5.37262C11.5154 5.9196 10.7735 6.22689 10 6.22689C9.22645 6.22689 8.48459 5.9196 7.93761 5.37262C7.39062 4.82563 7.08333 4.08377 7.08333 3.31022C7.08333 2.53667 7.39062 1.79481 7.93761 1.24783C8.48459 0.700846 9.22645 0.393555 10 0.393555ZM4.16667 2.47689C4.63333 2.47689 5.06667 2.60189 5.44167 2.82689C5.31667 4.01855 5.66667 5.20189 6.38333 6.12689C5.96667 6.92689 5.13333 7.47689 4.16667 7.47689C3.50363 7.47689 2.86774 7.2135 2.3989 6.74466C1.93006 6.27581 1.66667 5.63993 1.66667 4.97689C1.66667 4.31385 1.93006 3.67796 2.3989 3.20912C2.86774 2.74028 3.50363 2.47689 4.16667 2.47689ZM15.8333 2.47689C16.4964 2.47689 17.1323 2.74028 17.6011 3.20912C18.0699 3.67796 18.3333 4.31385 18.3333 4.97689C18.3333 5.63993 18.0699 6.27581 17.6011 6.74466C17.1323 7.2135 16.4964 7.47689 15.8333 7.47689C14.8667 7.47689 14.0333 6.92689 13.6167 6.12689C14.3333 5.20189 14.6833 4.01855 14.5583 2.82689C14.9333 2.60189 15.3667 2.47689 15.8333 2.47689ZM4.58333 11.0186C4.58333 9.29355 7.00833 7.89355 10 7.89355C12.9917 7.89355 15.4167 9.29355 15.4167 11.0186V12.4769H4.58333V11.0186ZM0 12.4769V11.2269C0 10.0686 1.575 9.09355 3.70833 8.81022C3.21667 9.37689 2.91667 10.1602 2.91667 11.0186V12.4769H0ZM20 12.4769H17.0833V11.0186C17.0833 10.1602 16.7833 9.37689 16.2917 8.81022C18.425 9.09355 20 10.0686 20 11.2269V12.4769Z'
        fill={color}
      />
    </svg>
  );
};

export default People;
