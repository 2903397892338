import Divider from 'common/components/Divider';
import React from 'react';

export const TalentSkeleton = () => {
  return (
    <div className='animate-pulse pb-2'>
      <div className='flex flex-col w-full'>
        <div className='flex items-center justify-between mb-4'>
          <div className='flex items-center gap-3'>
            <div className='h-[28px] w-[120px] bg-bright-grey rounded' />
            <div className='h-[24px] w-[32px] bg-bright-grey rounded' />
          </div>
          <div className='h-[32px] w-[150px] bg-bright-grey rounded' />
        </div>
      </div>
      <Divider />
    </div>
  );
};
