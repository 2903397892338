import Danger from 'assets/icons/Danger';
import React from 'react';

const ErrorFallback = () => {
  return (
    <div className='flex flex-col items-center justify-center h-screen w-screen'>
      <div className='flex justify-center border border-bright-silver rounded-md w-1/2'>
        <div className='flex flex-col w-80 gap-1.5 items-center justify-center mx-12 my-32'>
          <Danger size='100px' color='#F16347' />
          <h1 className='text-center text-raisin-black text-xl font-semibold'>
            Ups!
          </h1>
          <p className='text-center text-sonic-silver'>
            Seems like you have encountered an error! Please try again later...
          </p>
        </div>
      </div>
    </div>
  );
};

export default ErrorFallback;
