import Search from 'assets/icons/Search';
import { cn } from 'common/utils';
import React, { useState } from 'react';

export const SearchInput = ({
  height,
  inputWidth,
  placeholderText,
  handleChange,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [isSelected, setIsSelected] = useState(false);
  const containerHeight = height ?? 'h-9';
  const openWidth = inputWidth ?? 'w-41';

  const openSearchBar = () => {
    setIsOpen(true);
  };

  const closeSearchBar = () => {
    if (!inputValue && !isSelected) {
      setIsOpen(false);
    }
  };

  const handleInputFocus = () => {
    setIsSelected(true);
  };

  const handleInputBlur = () => {
    setIsSelected(false);
    if (!inputValue) {
      setIsOpen(false);
    }
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
    handleChange && handleChange(e);
  };

  return (
    <div
      className={`relative ${
        isOpen ? openWidth : 'aspect-square'
      } ${containerHeight} overflow-hidden border border-1 border-gray-200 rounded-md`}
      onMouseEnter={openSearchBar}
      onMouseLeave={closeSearchBar}
    >
      {isOpen ? (
        <>
          <input
            className={cn(
              containerHeight,
              'w-full h-full pl-3 pr-8 rounded',
              'placeholder:font-semibold',
            )}
            type='text'
            id='search'
            placeholder={placeholderText}
            onChange={handleInputChange}
            value={inputValue}
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
          />
          <div className='absolute top-0 right-0 h-full flex items-center pr-2.5'>
            <Search size='16px' />
          </div>
        </>
      ) : (
        <div className='w-full h-full flex items-center justify-center'>
          <div className='grid place-items-center h-full w-12'>
            <Search size='16px' />
          </div>
        </div>
      )}
    </div>
  );
};
