export const availableIndustries = [
  { label: 'consulting', value: 'consulting' },
  { label: 'leisure-entertainment', value: 'leisure-entertainment' },
  { label: 'services', value: 'services' },
  { label: 'professional-services', value: 'professional-services' },
  { label: 'pharmaceutical', value: 'pharmaceutical' },
  { label: 'distribution', value: 'distribution' },
  { label: 'automotive', value: 'automotive' },
  { label: 'technology', value: 'technology' },
  { label: 'food', value: 'food' },
  { label: 'energy', value: 'energy' },
  { label: 'health', value: 'health' },
  { label: 'construction', value: 'construction' },
  { label: 'textile', value: 'textile' },
  { label: 'aerospace', value: 'aerospace' },
  { label: 'tourism', value: 'tourism' },
  { label: 'finance', value: 'finance' },
  { label: 'chemistry', value: 'chemistry' },
  { label: 'transportation', value: 'transportation' },
  { label: 'education', value: 'education' },
  { label: 'media', value: 'media' },
  { label: 'metallurgy', value: 'metallurgy' },
  { label: 'agriculture', value: 'agriculture' },
  { label: 'telecommunications', value: 'telecommunications' },
  { label: 'mining', value: 'mining' },
  { label: 'biotechnology', value: 'biotechnology' },
  { label: 'insurance', value: 'insurance' },
];
