import Danger from 'assets/icons/Danger';
import Right from 'assets/icons/Right';
import Button from 'common/components/Button';
import Card from 'common/components/Card';
import LabelledInput from 'common/components/LabelledInput';
import Loader from 'common/components/Loader';
import { OnboardingStatus } from 'common/enums';
import useNavigate from 'common/hooks/useNavigate';
import { useToast } from 'common/hooks/useToast';
import useTranslation from 'common/hooks/useTranslation';
import {
  selectCurrentCompany,
  selectCurrentToken,
  setCredentials,
} from 'common/slices/auth.slice';
import { useLoginMutation } from 'common/slices/authApi.slice';
import { cn } from 'common/utils';
import { Form, Formik } from 'formik';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { ShowFeature } from 'split';
import * as Yup from 'yup';

import insightsTracker from '../../common/insights_tracker';

const LoginForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const toast = useToast();
  const { t } = useTranslation('auth\\login');

  const [login, { isLoading }] = useLoginMutation();

  const token = useSelector(selectCurrentToken);
  const currentCompany = useSelector(selectCurrentCompany);

  useEffect(() => {
    if (token && currentCompany) {
      if (
        currentCompany?.onboarding_status !== OnboardingStatus.done &&
        currentCompany?.onboarding_status !== OnboardingStatus.skipped
      ) {
        navigate('/onboarding');
        return;
      }
      navigate('/dashboard');
    }
  }, [token, currentCompany]);

  const initialValues = {
    email: location.state?.email || '',
    password: '',
  };

  const validationSchema = Yup.object({
    email: Yup.string()
      .email(t('status.invalid-email'))
      .required(t('status.email-required')),
    password: Yup.string().required(t('status.password-required')),
  });

  const handleSubmit = async (values, { setStatus, setSubmitting }) => {
    setStatus('');

    try {
      const loginForm = new FormData();
      loginForm.append('username', values.email);
      loginForm.append('password', values.password);
      const response = await login(loginForm).unwrap();

      dispatch(setCredentials({ ...response }));
      insightsTracker.loginSuccess(response);
      toast.newToast('positive', t('toasts.welcome-back'));

      if (response?.companies.length > 1) {
        navigate('/company-selector');
      } else {
        navigate('/dashboard');
      }
    } catch (err) {
      switch (err?.status) {
        case 401:
          setStatus(t('status.incorrect-email-password'));
          break;
        default:
          // toast.newToast('negative', t('toasts.something-wrong'));
          setStatus(t('status.something-wrong'));
      }
    }

    setSubmitting(false);
  };

  return (
    <Card shadowed>
      <div className='bg-vibrant-yellow rounded-t-lg h-2 w-full' />
      <div className='flex flex-col justify-center pt-10 md:px-[72px] px-5 pb-12 w-full relative'>
        <div className='w-[300px]'>
          <h1 className='text-center font-semibold text-3xl text-dark-blue pb-6'>
            {t('welcome')}
          </h1>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ values, status }) => (
              <Form className='flex flex-col gap-5 w-full'>
                <LabelledInput
                  name='email'
                  type='email'
                  label={t('email')}
                  placeholder={t('email')}
                />
                <LabelledInput
                  name='password'
                  type='password'
                  label={t('password')}
                  placeholder={t('password')}
                />
                {status && (
                  <div
                    className={cn(
                      'text-bittersweet-shimmer text-xs font-semibold',
                      'flex justify-start items-center gap-1',
                    )}
                  >
                    <Danger size='14px' color='#C2514B' />
                    {status}
                  </div>
                )}
                <Button variant='primary' type='submit'>
                  {t('login')}
                  <Right size='16px' color='#FFFFFF' />
                </Button>
                <Link
                  to={'/password-assistance'}
                  state={{ email: values.email }}
                  className='text-center text-dark-blue font-semibold uppercase text-xs underline'
                >
                  {t('password-recovery')}
                </Link>
              </Form>
            )}
          </Formik>
        </div>
        <ShowFeature featureFlag='LINKED_IN_LOG_IN'>
          <div className='flex items-center py-4'>
            <div className='flex-grow h-px bg-dark-blue'></div>
            <span className='flex-shrink px-4 text-xs uppercase'>
              {t('or')}
            </span>
            <div className='flex-grow h-px bg-dark-blue'></div>
          </div>
        </ShowFeature>
        <div className='flex flex-col w-full items-center'>
          <div className='flex flex-col max-w-fit'>
            <ShowFeature featureFlag='LINKED_IN_LOG_IN'>
              <Button variant='linkedin'>{t('linked-in')}</Button>
            </ShowFeature>
            <ShowFeature featureFlag='SHOW_SIGN_UP'>
              <div className='flex flex-row mt-4 gap-2 text-dark-blue text-sm items-center justify-center'>
                {t('new-account')}
                <span
                  className='text-vibrant-orange text-xs font-semibold uppercase underline cursor-pointer'
                  onClick={() => navigate('/signup')}
                >
                  {t('sign-up')}
                </span>
              </div>
            </ShowFeature>
          </div>
        </div>
        {isLoading && <Loader />}
      </div>
    </Card>
  );
};

export default LoginForm;
