import Page from 'assets/icons/Page';
import useTranslation from 'common/hooks/useTranslation';
import React from 'react';

const PageEmptyState = () => {
  const { t } = useTranslation('common\\page-empty-state');

  return (
    <div className='flex flex-col items-center justify-center h-full w-full'>
      <div className='flex justify-center border border-bright-silver rounded-md w-1/2'>
        <div className='flex flex-col w-80 gap-1.5 items-center justify-center mx-12 my-32'>
          <Page size='100px' color='#F16347' />
          <h1 className='text-center text-raisin-black text-xl font-semibold'>
            {t('coming-soon')}
          </h1>
          <p className='text-center text-sonic-silver'>{t('description')}</p>
        </div>
      </div>
    </div>
  );
};

export default PageEmptyState;
