import React from 'react';

const RightHand = () => (
  <svg
    id='right-hand'
    width='588'
    height='514'
    viewBox='0 0 588 514'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className='bottom-0 left-[72px] absolute'
  >
    <path
      d='M380.55 239.818C380.305 238.12 378.189 237.499 377.032 238.69C374.973 240.807 371.57 244.082 368.365 247.145C367.578 247.897 366.802 248.636 366.062 249.342C363.8 251.499 361.867 253.342 360.942 254.267L304.159 311.05L247.376 367.833C190.857 424.351 99.2231 424.351 42.7048 367.833C29.578 354.706 19.5013 339.687 12.4728 323.655C-19.7117 250.241 19.9224 169.907 83.8697 107.718C115.809 76.6572 153.739 50.2081 191.604 31.5281C229.481 12.842 267.223 1.96387 298.795 1.96387C457.965 1.96387 586.999 130.997 586.999 290.166C586.999 449.336 457.965 578.369 298.795 578.369C262.884 578.369 244.747 577.611 207.413 572.536C206.469 572.408 206.171 570.97 207.2 570.533C342.949 512.757 400.398 377.295 380.55 239.818Z'
      stroke='#F0DC29'
      strokeWidth='2'
      strokeLinejoin='round'
    />
  </svg>
);

export default RightHand;
