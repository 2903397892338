import Brain from 'assets/icons/Brain';
import { cn } from 'common/utils';
import React from 'react';

function getClassificationColor(classification, min, max) {
  if (classification >= max) {
    return 'bg-green-100 text-green-500';
  } else if (classification < max && classification >= min) {
    return 'bg-yellow-100 text-yellow-500';
  } else {
    return 'bg-red-100 text-red-500';
  }
}

const AIClassification = ({ classification, icon }) => {
  if (!classification) {
    return null;
  }

  classification = classification.toFixed(1);

  const cn_colors = getClassificationColor(classification, 3, 4);

  return (
    <>
      <div
        className={cn(
          'px-2 py-1 mb-1 text-2xl font-semibold rounded flex justify-center',
          cn_colors,
        )}
      >
        <div className='flex items-center mr-3'>
          <Brain size='20px' color='currentColor' />
          {icon}
        </div>
        <div className='flex items-baseline'>
          <span className='font-semibold'>{classification}</span>
          <span className={cn('ml-1 text-sm', cn_colors)}>PTS</span>
        </div>
      </div>
    </>
  );
};

export default AIClassification;
