import { Listbox, Transition } from '@headlessui/react';
import Down from 'assets/icons/Down';
import Avatar from 'common/components/Avatar';
import { cn } from 'common/utils';
import { useField } from 'formik';
import React, { Fragment, useState } from 'react';

import { CompanyCard } from './CompanyCard';

const CompanySelect = ({ label, options, ...props }) => {
  const [field] = useField(props.name);
  const [selectedOption, setSelectedOption] = useState(options[0]);

  const handleOptionClick = (optionValue) => {
    setSelectedOption(optionValue);
    field.onChange({
      target: {
        name: props.name,
        value: optionValue,
      },
    });
  };

  return (
    <div className='relative'>
      <Listbox value={selectedOption} onChange={setSelectedOption}>
        <Listbox.Label className='text-xs font-semibold uppercase text-weldon-blue'>
          {label}
        </Listbox.Label>
        <div className='relative mt-2'>
          <Listbox.Button
            className={cn(
              'bg-white text-input',
              'text-dark-blue',
              'border rounded-md',
              'px-4 py-2 w-full',
              'flex items-center justify-between',
              'cursor-pointer gap-2.5',
            )}
          >
            <div className='flex items-center gap-2.5 py-1'>
              {props.isWithAvatar && (
                <Avatar size='small' name={selectedOption.label} />
              )}
              {selectedOption.label}
            </div>
            <Down color='#8D91A0' />
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave='transition ease-in duration-100'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <Listbox.Options
              className={cn(
                'absolute',
                'flex flex-col max-h-[240px]',
                'top-full left-0 mt-0.5 w-full',
                'border border-gray-300 bg-white',
                'shadow-md rounded',
                'overflow-y-auto scrollbar scrollbar-w-1',
                'scrollbar-thumb-vibrant-orange scrollbar-thumb-rounded',
                'scrollbar-track-rounded',
                'p-4',
              )}
            >
              {options.map((option, index) => (
                <Listbox.Option key={index} value={option}>
                  <CompanyCard
                    key={option.value}
                    option={option}
                    isSelected={option === selectedOption}
                    handleOptionClick={handleOptionClick}
                    isWithAvatar={props.isWithAvatar}
                  />
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  );
};

export default CompanySelect;
