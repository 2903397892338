import Right from 'assets/icons/Right';
import Button from 'common/components/Button';
import LabelledInput from 'common/components/LabelledInput';
import Loader from 'common/components/Loader';
import { useToast } from 'common/hooks/useToast';
import useTranslation from 'common/hooks/useTranslation';
import { selectCurrentCompany } from 'common/slices/auth.slice';
import {
  useGetCurrentCompanySettingsQuery,
  useUpdateCurrentCompanySettingsMutation,
} from 'common/slices/companyApi.slice';
import { Form, Formik } from 'formik';
import React from 'react';
import { useSelector } from 'react-redux';

const IntegrationsSettingsForm = () => {
  const toast = useToast();

  const { t } = useTranslation('settings\\index');

  const currentCompany = useSelector(selectCurrentCompany);

  const { data: companySettings, isLoading: isLoadingCompanySettings } =
    useGetCurrentCompanySettingsQuery({ companyId: currentCompany.id });

  const [
    updateCurrentCompanySettings,
    { isLoading: isUpdatingCompanySettings },
  ] = useUpdateCurrentCompanySettingsMutation();

  const workableSetting = companySettings?.find(
    (setting) => setting.name === 'workable_api_key',
  );

  const subdomainSetting = companySettings?.find(
    (setting) => setting.name === 'workable_api_subdomain',
  );

  const initialValues = {
    workable_api_key: workableSetting?.value || '',
    workable_api_subdomain: subdomainSetting?.value || '',
  };

  const handleSubmit = async (values) => {
    const settings = Object.values(values).map((setting, index) => ({
      name: Object.keys(values)[index],
      value: setting,
    }));

    try {
      await updateCurrentCompanySettings({
        companyId: currentCompany.id,
        data: settings,
      }).unwrap();

      toast.newToast('positive', t('toasts.success'));
    } catch (err) {
      switch (err?.status) {
        case 403:
          toast.newToast('negative', t('toasts.forbidden'));
          break;
        default:
          toast.newToast('negative', t('toasts.something-wrong'));
      }
    }
  };

  return (
    <>
      {isLoadingCompanySettings ? (
        <Loader />
      ) : (
        <>
          <Formik initialValues={initialValues} onSubmit={handleSubmit}>
            <Form
              id='company-settings-form'
              className='flex flex-col w-full flex-grow gap-10 mt-6 relative'
            >
              <div className='w-1/3 mt-1.5 gap-6 flex flex-col'>
                <LabelledInput
                  name='workable_api_key'
                  label={t('workable-api-key')}
                  type='text'
                />
                <LabelledInput
                  name='workable_api_subdomain'
                  label={t('workable-api-subdomain')}
                  type='text'
                />
              </div>
              <div className='w-1/4'>
                <Button
                  type='submit'
                  variant='primary'
                  form='company-settings-form'
                >
                  {t('save')}
                  <Right size='16px' color='#FFFFFF' />
                </Button>
              </div>
            </Form>
          </Formik>
          {isUpdatingCompanySettings && <Loader />}
        </>
      )}
    </>
  );
};

export default IntegrationsSettingsForm;
