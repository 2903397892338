import Add from 'assets/icons/Add';
import Check from 'assets/icons/Check';
import Danger from 'assets/icons/Danger';
import Left from 'assets/icons/Left';
import Button from 'common/components/Button';
import Chip from 'common/components/Chip';
import useTranslation from 'common/hooks/useTranslation';
import { cn } from 'common/utils';
import { FieldArray, Form, Formik } from 'formik';
import { isEqual } from 'lodash';
import React from 'react';
import * as Yup from 'yup';

import QuestionCombo from './QuestionCombo';
import QuestionsEmptyState from './QuestionsEmptyState';

const QuestionsDrawer = ({
  questions,
  setQuestions,
  initialValues,
  setInitialValues,
  closeDrawer,
}) => {
  const { t } = useTranslation('offers\\details');

  const validationSchema = Yup.object().shape({
    questions: Yup.array().of(
      Yup.object().shape({
        question: Yup.object()
          .test(
            'is-not-empty',
            t('filters.validation.question-required'),
            (value) => {
              return Object.keys(value).length > 0;
            },
          )
          .required('Question is required'),
        conditional: Yup.object()
          .test(
            'is-not-empty',
            t('filters.validation.conditional-required'),
            (value) => {
              return Object.keys(value).length > 0;
            },
          )
          .required('Conditional is required'),
        answer: Yup.string().required(t('filters.validation.answer-required')),
      }),
    ),
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    const transformedValues = { questions: [] };

    values.questions.forEach((item) => {
      const question = item.question;
      const transformedQuestion = {
        id: question.id,
        conditional: Object.keys(item.conditional)[0],
        data_type: question.data_type,
        answer: item.answer,
      };

      transformedValues.questions.push(transformedQuestion);
    });

    setInitialValues(values);
    setQuestions(transformedValues);
    setSubmitting(false);
    closeDrawer(false);
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ values }) => {
        const hasChanges = !isEqual(values, initialValues);

        return (
          <div
            className={cn(
              'w-full max-w-full h-full max-h-full',
              'flex flex-col justify-between flex-grow',
            )}
          >
            <div className='flex flex-col h-full p-5'>
              <div className='flex flex-row w-full justify-between items-center mb-4'>
                <Button
                  size='icon'
                  variant='primary'
                  onClick={() => closeDrawer(hasChanges)}
                >
                  <Left size='16px' color='#FFFFFF' />
                </Button>
                {hasChanges ? (
                  <Chip variant='primary'>
                    <Danger size='12px' color='#F16347' />
                    {t('filters.has-changes')}
                  </Chip>
                ) : values.questions.length === 0 ? null : (
                  <Chip variant='success'>
                    <Check size='12px' color='#5FBC75' />
                    {t('filters.no-changes')}
                  </Chip>
                )}
              </div>
              <div className='flex flex-col gap-2 justify-start mb-6'>
                <h1 className='font-semibold text-2xl text-raisin-black'>
                  {t('filters.title')}
                </h1>
              </div>
              <Form
                id='question-filters'
                className='flex flex-col h-full justify-between'
              >
                <FieldArray name='questions'>
                  {({ remove, push }) => (
                    <>
                      {values.questions.length === 0 ? (
                        <QuestionsEmptyState />
                      ) : (
                        <div className='flex flex-col'>
                          {values.questions.map((_, index) => (
                            <QuestionCombo
                              key={index}
                              index={index}
                              options={questions}
                              remove={remove}
                            />
                          ))}
                        </div>
                      )}
                      <Button
                        type='button'
                        variant='tertiary'
                        onClick={() =>
                          push({
                            question: {},
                            conditional: {},
                            answer: '',
                          })
                        }
                      >
                        <Add size='16px' color='#FFFFFF' />
                        {t('filters.buttons.add-filter')}
                      </Button>
                    </>
                  )}
                </FieldArray>
              </Form>
            </div>
            <div
              className={cn(
                'flex flex-row justify-between items-center',
                'p-4 border-t border-bright-grey bg-ghost-white',
              )}
            >
              <Button
                variant='secondary'
                onClick={() => closeDrawer(hasChanges)}
              >
                {t('filters.buttons.cancel')}
              </Button>
              <Button type='submit' variant='primary' form='question-filters'>
                {t('filters.buttons.filter')}
                <Check size='12px' color='#FFFFFF' />
              </Button>
            </div>
          </div>
        );
      }}
    </Formik>
  );
};

export default QuestionsDrawer;
