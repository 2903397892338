import { cn } from 'common/utils';
import React from 'react';

const Facebook = ({ size = 24, color = '#8D91A0', onClick }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 25 24'
      fill='none'
      className={cn({ 'cursor-pointer': onClick })}
    >
      <path
        d='M22.6219 0.905518H2.88001C2.20381 0.905518 1.65564 1.45369 1.65564 2.12989V21.8718C1.65564 22.548 2.20381 23.0961 2.88001 23.0961H22.6219C23.2981 23.0961 23.8463 22.548 23.8463 21.8718V2.12989C23.8463 1.45369 23.2981 0.905518 22.6219 0.905518Z'
        fill={color}
      />
      <path
        d='M16.965 23.0943V14.5012H19.8488L20.28 11.1524H16.965V9.01494C16.965 8.04557 17.235 7.38369 18.6244 7.38369H20.3981V4.38369C19.5392 4.29418 18.6761 4.25161 17.8125 4.25619C15.2588 4.25619 13.5 5.81244 13.5 8.68307V11.1524H10.6163V14.5012H13.5V23.0943H16.965Z'
        fill='white'
      />
    </svg>
  );
};

export default Facebook;
