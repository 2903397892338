// insights_tracker.js

/* global dataLayer */

const LINKEDIN_PARTNER_ID = process.env.REACT_APP_LINKEDIN_PARTNER_ID;
const LINKEDIN_SIGNUP_SUCCESS_EVENT_ID = 17212193;
const GOOGLE_TAG_ID = process.env.REACT_APP_GOOGLE_TAG_ID;

const EVENT_TYPE = {
  SIGNUP_SUCCESS: 'SIGNUP_SUCCESS',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  JOB_OFFER_ADD_SUCCESS: 'JOB_OFFER_ADD_SUCCESS',
  SUBMISSION_DETAIL_PAGE_VIEW: 'SUBMISSION_DETAIL_PAGE_VIEW',
};

function _loadLinkedinTracker() {
  window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
  window._linkedin_data_partner_ids.push(LINKEDIN_PARTNER_ID);

  if (!window.lintrk) {
    window.lintrk = function (a, b) {
      window.lintrk.q.push([a, b]);
    };
    window.lintrk.q = [];
  }
  const s = document.getElementsByTagName('script')[0];
  const b = document.createElement('script');
  b.type = 'text/javascript';
  b.async = true;
  b.src = 'https://snap.licdn.com/li.lms-analytics/insight.min.js';
  s.parentNode.insertBefore(b, s);
}

const googleTracker = {
  gtag() {
    dataLayer.push(arguments);
  },

  isEnabled: () => {
    return Boolean(GOOGLE_TAG_ID);
  },

  init() {
    if (!this.isEnabled()) {
      return;
    }

    // Create the script element
    const script = document.createElement('script');
    script.src = 'https://www.googletagmanager.com/gtag/js?id=' + GOOGLE_TAG_ID;
    script.async = true;

    // Append the script to the document head
    document.head.appendChild(script);

    window.dataLayer = window.dataLayer || [];

    this.gtag('js', new Date());

    console.log('gtracker::loaded');
  },

  doPageView(location, currentUser) {
    if (!this.isEnabled()) {
      return;
    }

    console.log('gtracker::doPageView');

    this.gtag('config', GOOGLE_TAG_ID, {
      page_title: document.title,
      page_location: window.location.href,
      page_path: window.location.pathname,
      allow_enhanced_conversions: true,
      user_id: currentUser?.id,
    });

    this.gtag('set', 'user_data', {
      email: currentUser?.email,
      phone_number: currentUser?.phone,
      // 'first_name': user?.first_name,
      // 'last_name': user?.last_name,
    });
  },

  sendEvent(eventType) {
    if (!this.isEnabled()) {
      return;
    }
    console.log(`gtracker::events::${eventType}`);
    this.gtag('event', eventType);
  },

  // registerUser(user) {
  //   if (!this.isEnabled()) {
  //     return;
  //   }
  //   console.log(`gtracker::register_user::${user?.id}`);
  //   this.gtag('config', GOOGLE_TAG_ID, {
  //     user_id: user?.id,
  //   });
  //   this.gtag('set', 'user_data', {
  //     email: user?.email,
  //     phone_number: user?.phone,
  //     // 'first_name': user?.first_name,
  //     // 'last_name': user?.last_name,
  //   });
  // },

  fireSignupSuccess() {
    this.sendEvent(EVENT_TYPE.SIGNUP_SUCCESS);
  },

  fireLoginSuccess(user) {
    // this.registerUser(user);
    this.sendEvent(EVENT_TYPE.LOGIN_SUCCESS);
  },

  fireJobOfferAddSuccess() {
    this.sendEvent(EVENT_TYPE.JOB_OFFER_ADD_SUCCESS);
  },

  fireSubmissionDetailPageView(submissionId) {
    this.sendEvent(EVENT_TYPE.SUBMISSION_DETAIL_PAGE_VIEW);
  },
};

const insightsTracker = {
  googleTracker: googleTracker,

  isLinkedinEnabled: () => {
    return Boolean(LINKEDIN_PARTNER_ID);
  },

  init() {
    insightsTracker.isLinkedinEnabled() && _loadLinkedinTracker();
    this.googleTracker.init();
  },

  doPageView(location, currentUser) {
    this.googleTracker.doPageView(location, currentUser);
  },

  signupSuccess() {
    insightsTracker.isLinkedinEnabled() &&
      window.lintrk('track', {
        conversion_id: LINKEDIN_SIGNUP_SUCCESS_EVENT_ID,
      });
    this.googleTracker.fireSignupSuccess();
  },

  loginSuccess(user) {
    this.googleTracker.fireLoginSuccess(user);
  },

  jobOfferAddSuccess() {
    this.googleTracker.fireJobOfferAddSuccess();
  },

  submissionDetailPageView(submissionId) {
    this.googleTracker.fireSubmissionDetailPageView(submissionId);
  },
};

export default insightsTracker;
