import useTranslation from 'common/hooks/useTranslation';
import { isEmpty, isNil } from 'lodash';
import React from 'react';

import { OfferTableItem } from './OfferTableItem';
import { OffersTableEmptyState } from './OffersTableEmpyState';

export const OfferTable = ({ openOffers, isLoading, currentTab }) => {
  const { t } = useTranslation('offers\\index');

  return (
    <div className='w-full h-full relative mt-6'>
      {isNil(openOffers) || isEmpty(openOffers?.items) ? (
        <OffersTableEmptyState isLoading={isLoading} currentTab={currentTab} />
      ) : (
        <div className='w-full py-6'>
          <table className='w-full table-auto'>
            <thead className='text-sm text-weldon-blue font-semibold uppercase border-b border-bright-grey'>
              <tr>
                <th className='pb-5 whitespace-nowrap min-w-fit'>
                  <div className='font-semibold text-left'> {t('date')}</div>
                </th>
                <th className='pb-5 whitespace-nowrap min-w-fit'>
                  <div className='font-semibold text-left'>{t('position')}</div>
                </th>
                <th className='pb-5 whitespace-nowrap min-w-fit'>
                  <div className='font-semibold text-left'> {t('status')}</div>
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {openOffers?.items?.map(
                ({ id, created_at, name, slug, status, owner_id }) => {
                  return (
                    <OfferTableItem
                      key={id}
                      created_at={created_at}
                      name={name}
                      slug={slug}
                      status={status}
                      owner_id={owner_id}
                    />
                  );
                },
              )}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};
