import { cn } from 'common/utils';
import React from 'react';

const Money = ({ size = 24, color = '#8D91A0', onClick }) => {
  return (
    <svg
      className={cn({ 'cursor-pointer': onClick })}
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 18 18'
      fill='none'
    >
      <path
        d='M0 10.8643L10.6066 0.257656L17.6777 7.32872L7.07107 17.9353L0 10.8643ZM7.07107 7.32872C7.53991 6.85988 8.17579 6.59649 8.83883 6.59649C9.50188 6.59649 10.1378 6.85988 10.6066 7.32872C11.0754 7.79756 11.3388 8.43345 11.3388 9.09649C11.3388 9.75953 11.0754 10.3954 10.6066 10.8643C10.1378 11.3331 9.50188 11.5965 8.83883 11.5965C8.17579 11.5965 7.53991 11.3331 7.07107 10.8643C6.60223 10.3954 6.33883 9.75953 6.33883 9.09649C6.33883 8.43345 6.60223 7.79757 7.07107 7.32872ZM3.53553 9.68575C3.84809 9.99831 4.02369 10.4222 4.02369 10.8643C4.02369 11.3063 3.84809 11.7302 3.53553 12.0428L5.89256 14.3998C6.20512 14.0872 6.62904 13.9116 7.07107 13.9116C7.5131 13.9116 7.93702 14.0872 8.24958 14.3998L14.1421 8.50724C13.8296 8.19467 13.654 7.77075 13.654 7.32872C13.654 6.8867 13.8296 6.46277 14.1421 6.15021L11.7851 3.79319C11.4726 4.10575 11.0486 4.28135 10.6066 4.28135C10.1646 4.28135 9.74065 4.10575 9.42809 3.79319L3.53553 9.68575Z'
        fill={color}
      />
    </svg>
  );
};

export default Money;
