import { cn } from 'common/utils';
import React from 'react';

const Help = ({ size = 24, color = '#8D91A0', onClick }) => {
  return (
    <svg
      className={cn({ 'cursor-pointer': onClick })}
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 25 25'
      fill='none'
    >
      <g clipPath='url(#clip0_2217_90381)'>
        <path
          d='M12.6484 22.0879C15.4098 22.0879 17.9098 20.9686 19.7195 19.1589C21.5291 17.3493 22.6484 14.8493 22.6484 12.0879C22.6484 9.32649 21.5291 6.82649 19.7195 5.01682C17.9098 3.20718 15.4098 2.08789 12.6484 2.08789C9.88704 2.08789 7.38704 3.20718 5.57737 5.01682C3.76773 6.82649 2.64844 9.32649 2.64844 12.0879C2.64844 14.8493 3.76773 17.3493 5.57737 19.1589C7.38704 20.9686 9.88704 22.0879 12.6484 22.0879Z'
          stroke={color}
          strokeWidth='1.6'
          strokeLinejoin='round'
        />
        <path
          d='M12.6484 14.4003V12.4003C14.3053 12.4003 15.6484 11.0572 15.6484 9.40033C15.6484 7.74348 14.3053 6.40033 12.6484 6.40033C10.9916 6.40033 9.64844 7.74348 9.64844 9.40033'
          stroke={color}
          strokeWidth='1.6'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M12.6484 18.9003C13.3388 18.9003 13.8984 18.3407 13.8984 17.6503C13.8984 16.96 13.3388 16.4003 12.6484 16.4003C11.9581 16.4003 11.3984 16.96 11.3984 17.6503C11.3984 18.3407 11.9581 18.9003 12.6484 18.9003Z'
          fill={color}
        />
      </g>
      <defs>
        <clipPath id='clip0_2217_90381'>
          <rect
            width={size}
            height={size}
            fill='white'
            transform='translate(0.648438 0.0878906)'
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Help;
