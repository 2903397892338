import { cva } from 'class-variance-authority';
import { cn } from 'common/utils';
import React from 'react';

const buttonVariants = cva(
  [
    'inline-flex items-center gap-1 justify-center uppercase',
    'whitespace-nowrap rounded-md text-xs font-semibold',
    'transition-colors disabled:pointer-events-none disabled:opacity-50',
  ],
  {
    variants: {
      variant: {
        primary: 'bg-vibrant-orange text-white hover:bg-vibrant-orange/90',
        secondary: 'bg-white border border-bright-grey text-cool-grey',
        tertiary: 'bg-cool-grey text-white hover:bg-cool-grey/90',
        linkedin: 'bg-linkedin-blue text-white hover:bg-linkedin-blue/90',
      },
      size: {
        default: 'px-4 py-2.5',
        icon: 'p-2.5',
      },
    },
    defaultVariants: {
      variant: 'primary',
      size: 'default',
    },
  },
);

const Button = ({ className, variant, size, ...props }) => {
  return (
    <button
      className={cn(buttonVariants({ variant, size, className }))}
      {...props}
    />
  );
};

export default Button;
