import Avatar from 'common/components/Avatar';
import Chip from 'common/components/Chip';
import useTranslation from 'common/hooks/useTranslation';
import React from 'react';

const TeamTableItem = ({ id, name, role, email }) => {
  const { t } = useTranslation('settings\\index');

  const roleVariants = {
    admin: 'success-outlined',
    staff: 'warning-outlined',
    'talent-manager': 'secondary-outlined',
    'business-manager': 'primary-outlined',
  };

  return (
    <tr className='h-[72px] border-b border-bright-grey'>
      <td className='whitespace-nowrap'>
        <div className='flex items-center gap-2'>
          <div className='w-10 h-10 flex-shrink-0'>
            <Avatar size='medium' name={name} />
          </div>
          <div className='font-semibold text-raisin-black'>{name}</div>
        </div>
      </td>
      <td className='whitespace-nowrap'>
        <div className='flex items-start'>
          {role && (
            <Chip className='uppercase' variant={roleVariants[role]}>
              {t(`roles.${role}`)}
            </Chip>
          )}
        </div>
      </td>
      <td className='whitespace-nowrap'>
        <div className='flex items-start'>
          <p className='text-raisin-black'>{email}</p>
        </div>
      </td>
    </tr>
  );
};

export default TeamTableItem;
