import Right from 'assets/icons/Right';
import Button from 'common/components/Button';
import LabelledInput from 'common/components/LabelledInput';
import Loader from 'common/components/Loader';
import Select from 'common/components/Select';
import { SettingsContext } from 'common/contexts/SettingsContext';
import { useToast } from 'common/hooks/useToast';
import useTranslation from 'common/hooks/useTranslation';
import { Form, Formik } from 'formik';
import React, { useContext } from 'react';

const UserSettingsForm = () => {
  const toast = useToast();

  const { t } = useTranslation('settings\\index');

  const settingsContext = useContext(SettingsContext);

  const availableLanguages = [
    { label: 'english', value: 'en' },
    { label: 'spanish', value: 'es' },
    { label: 'portuguese', value: 'pt' },
  ];

  const defaultLanguage =
    availableLanguages.find(
      (lng) => lng.value === localStorage.getItem('i18nextLng'),
    ) ?? availableLanguages[0];

  const agendaLinkSetting = settingsContext.userSettings?.find(
    (setting) => setting.name === 'agenda_link',
  );

  const initialValues = {
    language: defaultLanguage,
    agenda_link: agendaLinkSetting?.value || '',
  };

  const handleSubmit = async (values) => {
    const settings = [
      { name: 'language', value: values.language.value },
      { name: 'agenda_link', value: values.agenda_link },
    ];

    try {
      await settingsContext.updateCurrentUserSettings(settings);
    } catch (err) {
      switch (err?.status) {
        case 403:
          toast.newToast('negative', t('toasts.forbidden'));
          break;
        default:
          toast.newToast('negative', t('something-wrong'));
      }
    }
  };

  return (
    <>
      {settingsContext.isLoadingUserSettings ? (
        <Loader />
      ) : (
        <>
          <Formik initialValues={initialValues} onSubmit={handleSubmit}>
            <Form
              id='general-settings-form'
              className='flex flex-col w-full flex-grow gap-10 mt-6 relative'
            >
              <div className='w-1/4 gap-6 flex flex-col'>
                <Select
                  name='language'
                  label={t('language-label')}
                  options={availableLanguages}
                  translationPath='settings\\index'
                />
                <LabelledInput
                  name='agenda_link'
                  label={t('scheduleLink')}
                  type='text'
                />
              </div>
              <div className='w-1/4'>
                <Button
                  type='submit'
                  variant='primary'
                  form='general-settings-form'
                >
                  {t('save')}
                  <Right size='16px' color='#FFFFFF' />
                </Button>
              </div>
            </Form>
          </Formik>
          {settingsContext.isUpdatingUserSettings && <Loader />}
        </>
      )}
    </>
  );
};

export default UserSettingsForm;
