import Avatar from 'common/components/Avatar';
import useNavigate from 'common/hooks/useNavigate';
import useTranslation from 'common/hooks/useTranslation';
import { formatDate } from 'common/utils';
import React from 'react';
import { ShowFeature } from 'split';

import Right from '../../assets/icons/Right';
import Button from '../../common/components/Button';

const TalentTableItem = ({ submission, candidateName }) => {
  const { t, i18n } = useTranslation('dashboard\\talent-table');
  const navigate = useNavigate();

  const getCurrentPhase = (phase) => {
    switch (phase) {
      case 'all':
        return t('candidates');
      case 'evaluate':
        return t('evaluation');
      case 'rank':
        return t('classification');
      case 'interview':
        return t('interview');
      default:
        return null;
    }
  };

  return (
    <tr className='h-[72px] border-b border-bright-grey'>
      <td className='whitespace-nowrap'>
        <div className='flex items-center gap-2'>
          <div className='w-10 h-10 flex-shrink-0'>
            <Avatar size='medium' name={candidateName} />
          </div>
          <div className='font-semibold text-raisin-black'>{candidateName}</div>
        </div>
      </td>
      <td className='whitespace-nowrap'>
        <div className='flex flex-col'>
          <p className='text-raisin-black'>{submission.offer.name}</p>
          {/*<p className='text-sm text-sonic-silver'>{company}</p>*/}
        </div>
      </td>
      <ShowFeature featureFlag='WEB_NO_ACTION_UI'>
        <td className='whitespace-nowrap'>
          <p className='text-raisin-black'>
            {submission.candidate.experience + '+'}
          </p>
        </td>
      </ShowFeature>
      <td className='whitespace-nowrap'>
        <p className='text-raisin-black'>{getCurrentPhase(submission.phase)}</p>
      </td>
      <td className='whitespace-nowrap'>
        <p className='text-sonic-silver'>
          {formatDate(submission.created_at, i18n, 'numeric')}
        </p>
      </td>
      <td className='whitespace-nowrap'>
        <p className='text-sonic-silver'>
          {formatDate(submission.updated_at, i18n, 'numeric')}
        </p>
      </td>
      <td className='whitespace-nowrap' align='right'>
        <Button
          variant='secondary'
          onClick={() =>
            navigate(
              `/offers/${submission.offer.slug}/submissions/${submission.slug}`,
            )
          }
        >
          {t('navigate-to-submission')}
          <Right size='16px' color='#8D91A0' />
        </Button>
      </td>
    </tr>
  );
};

export default TalentTableItem;
