import { OnboardingTeam } from 'assets/common';
import useTranslation from 'common/hooks/useTranslation';
import React from 'react';

export const WelcomeStep = () => {
  const { t } = useTranslation('onboarding\\index');

  return (
    <>
      <OnboardingTeam />
      <p className='mt-12 w-1/2 text-sm font-normal text-center'>
        {t('welcomeText')}
      </p>
    </>
  );
};
