import { cn } from 'common/utils';
import React from 'react';

const Right = ({ size = 24, color = '#8D91A0', onClick }) => {
  return (
    <div className={cn({ 'cursor-pointer': onClick })}>
      <svg
        width={size}
        height={size}
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        onClick={onClick}
      >
        <g id='right' clipPath='url(#clip0_633_585)'>
          <path
            id='Vector'
            d='M24 0H0V24H24V0Z'
            fill='white'
            fillOpacity='0.01'
          />
          <path
            id='Vector_2'
            d='M9.5 6L15.5 12L9.5 18'
            stroke={color}
            strokeWidth='1.6'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </g>
        <defs>
          <clipPath id='clip0_633_585'>
            <rect width='24px' height='24px' fill='white' />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default Right;
