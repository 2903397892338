import Add from 'assets/icons/Add';
import Button from 'common/components/Button';
import LabelledInput from 'common/components/LabelledInput';
import { LoadingResultState } from 'common/components/LoadingResultState';
import Select from 'common/components/Select';
import { useToast } from 'common/hooks/useToast';
import useTranslation from 'common/hooks/useTranslation';
import { useGenerateOfferQuestionsMutation } from 'common/slices/offerApi.slice';
import { useFormikContext } from 'formik';
import { OfferQuestionItem } from 'offers/components/OfferQuestionItem';
import React, { useState } from 'react';

import QuestionsModal from './QuestionsModal';

const SOURCE_AI = 'ai-suggested';

export const QuestionsStep = ({
  description,
  tests,
  questions,
  setQuestions,
  availableIndustries,
  setDisableNavigationButtons,
  assistant_id,
  setAssistantID,
}) => {
  const [isNewQuestionOpen, setNewQuestionOpen] = useState(false);
  const { t } = useTranslation('offers\\new');
  const toast = useToast();
  const formik = useFormikContext();
  const [generateOfferQuestions, { isLoading }] =
    useGenerateOfferQuestionsMutation();

  const handleClick = async () => {
    try {
      setDisableNavigationButtons(true);
      const { positionName, industry, tone, keywords } = formik.values;
      const response = await generateOfferQuestions({
        title: positionName,
        industry: industry.value,
        keywords: keywords,
        tone: tone.value,
        description: description,
        tests: tests,
        assistant_id: assistant_id,
      }).unwrap();

      setAssistantID(response.assistant_id);

      const manualQuestions = questions.filter(
        (question) => question.source !== SOURCE_AI,
      );

      setQuestions([
        ...manualQuestions,
        ...response.questions.map((question) => {
          return {
            name: question.name,
            description: question.description,
            data_type: question.data_type,
            source: SOURCE_AI,
          };
        }),
      ]);
    } catch (err) {
      console.log(err);
      switch (err?.status) {
        case 400:
          toast.newToast('negative', err?.data.errors[0].detail);
          break;
        case 403:
          toast.newToast('negative', err?.data.errors[0].detail);
          break;
        case 502:
          toast.newToast('negative', err?.data.errors[0].detail);
          break;
        default:
          toast.newToast('negative', t('toasts.something-wrong'));
      }
    } finally {
      setDisableNavigationButtons(false);
    }
  };

  const handleClickAddQuestion = async () => {
    setNewQuestionOpen(true);
  };

  const handleClickDeleteQuestion = async (index) => {
    let remainingQuestions = [...questions];
    remainingQuestions.splice(index, 1);
    setQuestions([...remainingQuestions]);
  };

  return (
    <>
      <div className='w-2/3 pb-8'>
        <p className='text-sm text-dark-liver font-normal pb-8'>
          {t('questionsMessage')}
        </p>
        <div className='flex w-full gap-4'>
          <div className='w-1/2 pt-2'>
            <LabelledInput
              label={t('position-name')}
              name='positionName'
              type='text'
              placeholder={t('position-name')}
              disabled={true}
              height='h-[48px]'
            />
          </div>
          <div className='w-1/2'>
            <Select
              name='industry'
              label={t('industryLabel')}
              options={availableIndustries}
              isWithAvatar={false}
              translationPath='offers\\new'
              isDisabled={true}
            />
          </div>
        </div>
        <div className='flex w-full gap-4'>
          <div className='flex w-full justify-end py-8'>
            <Button
              type='button'
              variant='primary'
              onClick={handleClick}
              disabled={isLoading}
            >
              {t('generateButton')}
            </Button>
          </div>
        </div>
        <div className='flex w-full gap-4'>
          <div className='flex flex-row w-full'>
            <div className='flex flex-col'>
              <h3 className='text-sm font-semibold text-weldon-blue uppercase pb-2'>
                {t('questions')}
              </h3>
              <p className='text-sm text-dark-liver font-normal'>
                {t('questionsListMessage')}
              </p>
            </div>
            <div className='flex items-center ml-auto'>
              <Button
                type='button'
                size='icon'
                variant='primary'
                onClick={handleClickAddQuestion}
                disabled={isLoading}
              >
                <Add size='16px' color='#FFFFFF' />
              </Button>
            </div>
          </div>
        </div>
        {isLoading ? (
          <div className='flex gap-3'>
            <LoadingResultState text={t('generatingQuestions')} />
          </div>
        ) : (
          questions.length > 0 && (
            <div className='flex w-full gap-4'>
              <div className='w-full flex flex-col'>
                {questions.map((question, index) => (
                  <OfferQuestionItem
                    key={index}
                    name={question.name}
                    description={question.description}
                    dataType={question.data_type}
                    answer={question.answer}
                    isRemovable={true}
                    onDeleteClick={() => handleClickDeleteQuestion(index)}
                  />
                ))}
              </div>
            </div>
          )
        )}
      </div>
      <QuestionsModal
        isOpen={isNewQuestionOpen}
        setIsOpen={setNewQuestionOpen}
        questions={questions}
        setQuestions={setQuestions}
      />
    </>
  );
};
