import { cn } from 'common/utils';
import React from 'react';

const Pencil = ({ size = 24, color = '#8D91A0', onClick }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      className={cn({ 'cursor-pointer': onClick })}
      onClick={onClick}
    >
      <g clipPath='url(#clip0_5866_13427)'>
        <path d='M24 0H0V24H24V0Z' fill='white' fillOpacity='0.01' />
        <path
          d='M3.5 21H21.5'
          stroke={color}
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M5.5 13.36V17H9.1586L19.5 6.65405L15.8476 3L5.5 13.36Z'
          stroke={color}
          strokeWidth='2'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_5866_13427'>
          <rect width='24' height='24' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Pencil;
