import React from 'react';

const Breadcrumbs = ({ firstCrumb, secondCrumb }) => {
  return (
    <div className='flex items-center ml-6'>
      <div className='text-cool-grey flex'>
        {firstCrumb}
        <span className='px-1'>/</span>
      </div>
      <div className='text-dark-charcoal font-semibold capitalize'>
        {secondCrumb}
      </div>
    </div>
  );
};

export default Breadcrumbs;
