import { cn } from 'common/utils';
import React from 'react';

const Down = ({ size = 24, color = '#8D91A0', onClick, disabled }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      onClick={disabled ? null : onClick}
      className={cn({
        'cursor-pointer': onClick && !disabled,
        'pointer-events-none': !onClick,
        'opacity-50': disabled,
      })}
    >
      <g clipPath='url(#clip0_633_645)'>
        <path d='M24 0H0V24H24V0Z' fill='white' fillOpacity='0.01' />
        <path
          d='M18.5 9L12.5 15L6.5 9'
          stroke={color}
          strokeWidth='1.6'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_633_645'>
          <rect width='24' height='24' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Down;
