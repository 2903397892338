import useTranslation from 'common/hooks/useTranslation';
import { cn } from 'common/utils';
import React from 'react';

const TalentEmptyState = () => {
  const { t } = useTranslation('dashboard\\talent-empty-state');

  return (
    <div
      className={cn(
        'flex flex-col w-full h-full',
        'bg-white rounded-lg',
        'border border-bright-grey',
      )}
    >
      <div className='h-full flex justify-center items-center mx-6'>
        <p className='text-raisin-black'>{t('empty-state')}</p>
      </div>
    </div>
  );
};

export default TalentEmptyState;
