import { PlaceholderEmptyState } from 'common/components/PlaceholderEmptyState';
import useTranslation from 'common/hooks/useTranslation';
import { OfferQuestionItem } from 'offers/components/OfferQuestionItem';
import React from 'react';

export const QuestionsTab = ({ questions }) => {
  const { t } = useTranslation('offers\\details');

  return questions.length > 0 ? (
    <div className='grid grid-cols-1 gap-4'>
      <div className='w-1/2'>
        {questions.map((question, index) => (
          <OfferQuestionItem
            key={index}
            name={question.name}
            description={question.description}
            dataType={question.data_type}
            answer={question.answer}
          />
        ))}
      </div>
    </div>
  ) : (
    <PlaceholderEmptyState className='pt-4' text={t('noQuestions')} />
  );
};
