import { Menu, Transition } from '@headlessui/react';
import Brain from 'assets/icons/Brain';
import Import from 'assets/icons/Import';
import More from 'assets/icons/More';
import Pencil from 'assets/icons/Pencil';
import Refresh from 'assets/icons/Refresh';
import Trash from 'assets/icons/Trash';
import useTranslation from 'common/hooks/useTranslation';
import { selectUser } from 'common/slices/auth.slice';
import { cn } from 'common/utils';
import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';

const OptionsMenu = ({
  handleImport,
  handleUpdate,
  handleDelete,
  handleSync,
  handleResetAssistant,
  handleResetSubmissionsAIInfo,
  isChatEnabled,
  isChatSyncing,
  isResettingSubmissions,
}) => {
  const { t } = useTranslation('offers\\details');
  const currentUser = useSelector(selectUser);

  const hasAccessToSyncAndAssistant =
    currentUser.role.name === 'staff' || currentUser.role.name === 'admin';

  return (
    <Menu as='div' className='relative inline-block'>
      <Menu.Button className='border rounded-md p-2.5'>
        <More size='16px' color='#7A7A7A' />
      </Menu.Button>
      <Transition
        as={Fragment}
        enter='transition ease-out duration-100'
        enterFrom='transform opacity-0 scale-95'
        enterTo='transform opacity-100 scale-100'
        leave='transition ease-in duration-75'
        leaveFrom='transform opacity-100 scale-100'
        leaveTo='transform opacity-0 scale-95'
      >
        <Menu.Items
          className={cn(
            'absolute right-0 mt-2 w-56 origin-top-right',
            'divide-y divide-ghost-white bg-white rounded-md shadow-lg',
            'ring-1 ring-dark-charcoal ring-opacity-5 focus:outline-none',
          )}
        >
          <Menu.Item>
            {({ active }) => (
              <button
                className={cn(
                  'flex w-full items-center px-4 py-4 text-sm gap-2.5',
                  'rounded-t-md',
                  {
                    'bg-vibrant-orange bg-opacity-10': active,
                  },
                )}
                onClick={handleImport}
              >
                <Import size='24px' color='#084D6E' />
                <p className='text-sm text-dark-charcoal'>
                  {t('options-menu.import-candidates')}
                </p>
              </button>
            )}
          </Menu.Item>
          <Menu.Item>
            {({ active }) => (
              <button
                className={cn(
                  'flex w-full items-center px-4 py-4 text-sm gap-2.5',
                  'rounded-b-md',
                  {
                    'bg-vibrant-orange bg-opacity-10': active,
                  },
                )}
                onClick={handleUpdate}
              >
                <Pencil size='24px' color='#084D6E' />
                <p className='text-sm text-dark-charcoal'>
                  {t('options-menu.update-offer')}
                </p>
              </button>
            )}
          </Menu.Item>
          <Menu.Item>
            {({ active }) => (
              <button
                className={cn(
                  'flex w-full items-center px-4 py-4 text-sm gap-2.5',
                  'rounded-b-md',
                  {
                    'bg-vibrant-orange bg-opacity-10': active,
                  },
                )}
                onClick={handleDelete}
              >
                <Trash size='24px' color='#084D6E' />
                <p className='text-sm text-dark-charcoal'>
                  {t('options-menu.delete-offer')}
                </p>
              </button>
            )}
          </Menu.Item>
          {hasAccessToSyncAndAssistant && (
            <>
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={cn(
                      'flex w-full items-center px-4 py-4 text-sm gap-2.5',
                      'rounded-b-md',
                      {
                        'bg-vibrant-orange bg-opacity-10': active,
                      },
                    )}
                    onClick={handleSync}
                  >
                    <Refresh size='24px' color='#084D6E' />
                    <p className='text-sm text-dark-charcoal truncate'>
                      {t('options-menu.sync-data-source')}
                    </p>
                  </button>
                )}
              </Menu.Item>
              <Menu.Item disabled={isChatSyncing}>
                {({ active, disabled }) => (
                  <button
                    className={cn(
                      'flex w-full items-center px-4 py-4 text-sm gap-2.5',
                      'rounded-b-md',
                      {
                        'bg-vibrant-orange bg-opacity-10': active,
                        'opacity-50': disabled,
                      },
                    )}
                    onClick={handleResetAssistant}
                  >
                    <Brain size='24px' color='#084D6E' />
                    <p className='text-sm text-dark-charcoal'>
                      {isChatEnabled
                        ? t('options-menu.reset-assistant')
                        : t('options-menu.activate-assistant')}
                    </p>
                  </button>
                )}
              </Menu.Item>
              <Menu.Item disabled={isResettingSubmissions}>
                {({ active, disabled }) => (
                  <button
                    className={cn(
                      'flex w-full items-center px-4 py-4 text-sm gap-2.5',
                      'rounded-b-md',
                      {
                        'bg-vibrant-orange bg-opacity-10': active,
                        'opacity-50': disabled,
                      },
                    )}
                    onClick={handleResetSubmissionsAIInfo}
                  >
                    <Brain size='24px' color='#084D6E' />
                    <p className='text-sm text-dark-charcoal'>
                      {' '}
                      {t('options-menu.generate-ai-info')}
                    </p>
                  </button>
                )}
              </Menu.Item>
            </>
          )}
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default OptionsMenu;
