import { cn } from 'common/utils';
import React from 'react';

const Redo = ({ size = 24, color = '#8D91A0', onClick }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      fill='none'
      className={cn({ 'cursor-pointer': onClick })}
    >
      <g clipPath='url(#clip0_2885_20125)'>
        <path
          d='M3.78276 13.1176C4.86852 14.2034 6.36852 14.875 8.02539 14.875C11.3391 14.875 14.0254 12.1887 14.0254 8.875C14.0254 5.5613 11.3391 2.875 8.02539 2.875C6.36852 2.875 4.86852 3.54657 3.78276 4.63237C3.23008 5.18503 2.02539 6.54167 2.02539 6.54167'
          stroke={color}
          strokeWidth='1.6'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M2.02539 3.875V6.54167H4.69206'
          stroke={color}
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_2885_20125'>
          <rect
            width='16'
            height='16'
            fill='white'
            transform='translate(0.0253906 0.875)'
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Redo;
