import LabelledInput from 'common/components/LabelledInput';
import LabelledPhoneInput from 'common/components/LabelledPhoneInput';
import useTranslation from 'common/hooks/useTranslation';
import React from 'react';

export const CompanyDataStep = () => {
  const { t } = useTranslation('onboarding\\index');

  return (
    <div className='flex flex-row gap-6'>
      <div className='w-1/2 mt-1.5 gap-6 flex flex-col'>
        <LabelledInput name='name' label={t('companyName')} type='text' />
        <LabelledInput name='webpage' label={t('companyWebPage')} type='text' />
        <LabelledInput
          name='location'
          label={t('companyLocation')}
          type='text'
        />
        <LabelledInput name='sector' label={t('companySector')} type='text' />
      </div>
      <div className='w-1/2 mt-1.5 gap-6 flex flex-col'>
        <LabelledInput
          name='n_employees'
          label={t('numberOfEmployees')}
          type='text'
        />
        <LabelledInput name='email' label={t('companyEmail')} type='text' />
        <LabelledPhoneInput name='phone' label={t('phoneNumber')} type='text' />
      </div>
    </div>
  );
};
