import useNavigate from 'common/hooks/useNavigate';
import useTranslation from 'common/hooks/useTranslation';
import React from 'react';
import { useLocation } from 'react-router-dom';

const Breadcrumbs = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { t } = useTranslation('common\\breadcrumbs');

  let currentLink = '';

  const subpaths = location.pathname.split('/').filter((crumb) => crumb !== '');
  // .slice(1); // remove company name from breadcrumbs
  const subpaths_title = subpaths.join(' - ');

  // const basePath = capitalize(subpaths[0]); // capitalize header title
  document.title = `Validate Talent - ${subpaths_title}`; // document title

  const crumbs = subpaths.map((crumb, index) => {
    currentLink += `/${crumb}`;

    const isLastSubpath = index === subpaths.length - 1;
    const resultingLink = currentLink;

    let resultingCrumb;

    if (crumb.match(/\b(?=\w*[A-Z])(?=\w*[a-z])\w{5,8}\b/)) {
      resultingCrumb = crumb.replace(/%20|-/g, ' ').replace(/\w{5,8}$/, '');
    } else {
      resultingCrumb = crumb.replace(/%20|-/g, ' ');
    }

    if (isLastSubpath) {
      return (
        <div
          key={resultingCrumb}
          className='text-dark-charcoal font-semibold capitalize'
        >
          {t(resultingCrumb) ?? resultingCrumb}
        </div>
      );
    } else {
      return (
        <div key={resultingCrumb} className='text-cool-grey flex'>
          <div
            className='hover:underline cursor-pointer capitalize'
            onClick={() => {
              navigate(resultingLink);
            }}
          >
            {t(resultingCrumb) ?? resultingCrumb}
          </div>
          <span className='px-1'>/</span>
        </div>
      );
    }
  });

  return <div className='flex items-center'>{crumbs}</div>;
};

export default Breadcrumbs;
