import LabelledInput from 'common/components/LabelledInput';
import useTranslation from 'common/hooks/useTranslation';
import { Form, Formik } from 'formik';
import React from 'react';

export const InformationTab = ({ offer }) => {
  const { t } = useTranslation('offers\\new');

  // Function to handle value change without formatting
  const handleChange = (e, formik) => {
    const { name, value } = e.target;
    const numericsOnly = value.replace(/[^\d]/g, ''); // Keep only digits

    formik.setFieldValue(name, numericsOnly);
  };

  return (
    <Formik
      initialValues={{
        positionName: offer.name || '',
        minSalary: offer.salary_min || '',
        maxSalary: offer.salary_max || '',
        vacancies: offer.n_vacancies || '',
      }}
      onSubmit={(values) => {
        // Handle form submission
        console.log(values);
      }}
    >
      {(formik) => (
        <Form>
          <div className='grid grid-cols-1 gap-4 pt-4'>
            <div className='w-1/2'>
              <LabelledInput
                label={t('position-name')}
                name='positionName'
                type='text'
                value={formik.values.positionName}
                placeholder={t('position-name')}
                disabled={true}
              />
              <div className='w-full flex flex-col mt-4'>
                <div className='w-full flex gap-10'>
                  <div className='w-1/2'>
                    <LabelledInput
                      label={t('minimum-salary')}
                      name='minSalary'
                      type='text'
                      value={formik.values.minSalary}
                      placeholder='00,00$'
                      onChange={(e) => handleChange(e, formik)}
                    />
                  </div>
                  <div className='w-1/2'>
                    <LabelledInput
                      label={t('maximum-salary')}
                      name='maxSalary'
                      type='text'
                      value={formik.values.maxSalary}
                      placeholder='00,00$'
                      onChange={(e) => handleChange(e, formik)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className='w-1/2'>
              <LabelledInput
                label={t('number-vacancies')}
                name='vacancies'
                type='text'
                value={formik.values.vacancies}
                placeholder='1'
                onChange={(e) => handleChange(e, formik)}
              />
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};
