import useTranslation from 'common/hooks/useTranslation';
import React from 'react';
import { Label, Pie, PieChart, ResponsiveContainer } from 'recharts';

const TestResultsDonutCharts = ({ testResults }) => {
  const { t } = useTranslation('offers\\details');

  const colorList = ['#084D6E', '#8292A2', '#F16347', '#F0DC29'];

  const data = Object.keys(testResults).map((key, index) => [
    { name: key, value: testResults[key], fill: colorList[index] },
    { name: 'rest', value: 100 - testResults[key], fill: '#EDEDED' },
  ]);

  return (
    <div className='flex justify-center items-center w-full max-w-[500px] h-16 gap-6 px-4'>
      {data.map((entry, index) => (
        <ResponsiveContainer key={index} height='100%' width={60}>
          <PieChart>
            <Pie
              data={entry}
              innerRadius='75%'
              outerRadius='100%'
              startAngle={90}
              endAngle={-270}
              fill='#F16347'
              dataKey='value'
            >
              <Label
                className='text-xs font-semibold'
                fill='#342E30'
                position='center'
                value={`${entry[0].value}%`}
              />
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      ))}
      <div className='flex flex-col ml-4'>
        <h4 className='text-xs uppercase font-bold text-dark-liver'>
          {t('charts.test-results')}
        </h4>
        <div className='flex flex-wrap gap-x-2'>
          {data.map((entry, index) => (
            <div key={index} className='flex items-center gap-1'>
              <span
                className='rounded-full h-2 w-2'
                style={{ backgroundColor: entry[0].fill }}
              />
              <p className='text-[10px] max-w-[96px] truncate'>
                {entry[0].name}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TestResultsDonutCharts;
