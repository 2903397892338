import { queryBuilder } from 'common/utils';

import { apiSlice } from './api.slice';

export const submissionApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getSubmissions: builder.query({
      query: ({ page = 1, size = 50, orderBy = '-updated_at' }) => ({
        url: queryBuilder('/submissions/', { page, size, orderBy }),
      }),
      providesTags: ['Submission'],
    }),
    getSubmission: builder.query({
      query: ({ submissionSlug }) => ({
        url: `/submissions/${submissionSlug}`,
      }),
      providesTags: ['Submission'],
    }),
    getSubmissionCurriculum: builder.query({
      query: ({ submissionSlug }) => ({
        url: `/submissions/${submissionSlug}/cv`,
      }),
    }),
    getSubmissionTests: builder.query({
      query: ({ submissionSlug }) => ({
        url: `/submissions/${submissionSlug}/tests`,
      }),
    }),
    getSubmissionQuestions: builder.query({
      query: ({ submissionSlug }) => ({
        url: `/submissions/${submissionSlug}/questions`,
      }),
    }),
    updateNotes: builder.mutation({
      query: ({ submissionId, data }) => ({
        url: `/submissions/${submissionId}/notes`,
        method: 'PATCH',
        body: { notes: data.notes },
      }),
      invalidatesTags: ['Board', 'Submission'],
    }),
    deleteSubmissionNote: builder.mutation({
      query: ({ submissionId, noteId }) => ({
        url: `/submissions/${submissionId}/notes/${noteId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Board', 'Submission'],
    }),
    updatePhase: builder.mutation({
      query: ({ submissionId, nextPhase }) => ({
        url: `/submissions/${submissionId}`,
        method: 'PATCH',
        body: { phase: nextPhase },
      }),
      invalidatesTags: ['Board', 'Analytics'],
    }),
    updateHiredStatus: builder.mutation({
      query: ({ submissionSlug }) => ({
        url: `/submissions/${submissionSlug}`,
        method: 'PATCH',
        body: { is_hired: true },
      }),
      invalidatesTags: ['Board'],
    }),
    updateFavoriteStatus: builder.mutation({
      query: ({ submissionSlug, isFavorite }) => ({
        url: `/submissions/${submissionSlug}`,
        method: 'PATCH',
        body: { is_favorite: isFavorite },
      }),
      invalidatesTags: ['Submission'],
    }),

    updateSubmissionAIInfo: builder.mutation({
      query: ({ submissionSlug }) => ({
        url: `/submissions/${submissionSlug}/ai/info`,
        method: 'PATCH',
      }),
      invalidatesTags: ['Submission'],
    }),
  }),
});

export const {
  useGetSubmissionsQuery,
  useGetSubmissionQuery,
  useGetSubmissionCurriculumQuery,
  useGetSubmissionTestsQuery,
  useGetSubmissionQuestionsQuery,
  useUpdateNotesMutation,
  useDeleteSubmissionNoteMutation,
  useUpdatePhaseMutation,
  useUpdateHiredStatusMutation,
  useUpdateFavoriteStatusMutation,
  useUpdateSubmissionAIInfoMutation,
} = submissionApiSlice;
