import { cn } from 'common/utils';
import React from 'react';

const Up = ({ size = 24, color = '#8D91A0', onClick, disabled }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      onClick={disabled ? null : onClick}
      className={cn({
        'cursor-pointer': onClick && !disabled,
        'pointer-events-none': !onClick,
        'opacity-50': disabled,
      })}
    >
      <g clipPath='url(#clip0_2825_47443)'>
        <path
          d='M24 0.324219H0V24.3242H24V0.324219Z'
          fill='white'
          fillOpacity='0.01'
        />
        <path
          d='M6.5 15.3242L12.5 9.32422L18.5 15.3242'
          stroke={color}
          strokeWidth='1.6'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_2825_47443'>
          <rect
            width='24'
            height='24'
            fill='white'
            transform='translate(0 0.324219)'
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Up;
