import Left from 'assets/icons/Left';
import Right from 'assets/icons/Right';
import Button from 'common/components/Button';
import Divider from 'common/components/Divider';
import Loader from 'common/components/Loader';
import StepIndicator from 'common/components/layout/components/StepIndicator';
import { availableIndustries } from 'common/constants';
import useNavigate from 'common/hooks/useNavigate';
import { useToast } from 'common/hooks/useToast';
import useTranslation from 'common/hooks/useTranslation';
import {
  useGetOfferBySlugQuery,
  useUpdateOfferMutation,
} from 'common/slices/offerApi.slice';
import { cn } from 'common/utils';
import { Form, Formik } from 'formik';
import { isEmpty } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';

import OfferDetailsSkeleton from '../details/components/OfferDetailsSkeleton';
import { DescriptionStep } from '../new/components/DescriptionStep';
import { InformationStep } from '../new/components/InformationStep';
import { QuestionsStep } from '../new/components/QuestionsStep';
import { TestsStep } from '../new/components/TestsStep';

const OfferUpdate = () => {
  const toast = useToast();
  const navigate = useNavigate();

  const { t } = useTranslation('offers\\update');

  const formSteps = ['information', 'description', 'tests', 'questions'];

  const [currentStep, setCurrentStep] = useState(formSteps[0]);
  const [isLastStep, setIsLastStep] = useState(false);
  const [isFirstStep, setIsFirstStep] = useState(true);
  const [hasErrors, setHasErrors] = useState(false);
  const [description, setDescription] = useState('');
  const [tests, setTests] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [disableNavigationButtons, setDisableNavigationButtons] =
    useState(false);
  const [assistant_id, setAssistantID] = useState(null);

  const [updateOffer, { isLoading }] = useUpdateOfferMutation();

  const { offerSlug } = useParams();

  const {
    data: offer,
    isFetching,
    error: offerError,
  } = useGetOfferBySlugQuery({ offerSlug });

  const isOfferFirstLoad = useRef(true);

  useEffect(() => {
    if (isOfferFirstLoad.current && !isFetching && !offerError && offer) {
      setDescription(offer.description || '');
      setTests(offer.tests || []);
      setQuestions(offer.questions_suggestions || []);

      isOfferFirstLoad.current = false;
    }
  }, [isFetching, offerError, offer]); // Empty dependency array means this effect runs once after the initial render

  const availableTones = [
    { label: 'professional', value: 'professional and trustworthy' },
    { label: 'neutral', value: 'neutral' },
    { label: 'friendly', value: 'friendly and relaxed' },
  ];

  const getInitialValues = (offer) => {
    let industry =
      availableIndustries.find((item) => item.label === offer.industry) ??
      availableIndustries[0];

    return {
      positionName: offer.name,
      vacancies: offer.n_vacancies,
      minSalary: offer.salary_min,
      maxSalary: offer.salary_max,
      industry: industry,
      tone: availableTones[0],
    };
  };

  const validationSchema = Yup.object({
    positionName: Yup.string().required(t('errors.required-position')),
    vacancies: Yup.number()
      .integer(t('errors.vacancies-must-be-integer'))
      .min(1, t('errors.vacancies-not-null')),
    minSalary: Yup.number()
      .typeError(t('errors.min-salary-number'))
      .positive(t('errors.min-salary-positive'))
      .required(t('errors.min-salary-required')),
    maxSalary: Yup.number()
      .typeError(t('errors.max-salary-number'))
      .positive(t('errors.max-salary-positive'))
      .when('minSalary', (minSalary, schema) =>
        schema.min(minSalary, t('errors.max-salary-greater')),
      )
      .required(t('errors.max-salary-required')),
    description: Yup.string(),
  });

  const handleNextStep = () => {
    const lastStepIndex = formSteps.length - 1;
    const currentStepIndex = formSteps.indexOf(currentStep);

    if (currentStepIndex < lastStepIndex) {
      const nextStep = formSteps[currentStepIndex + 1];
      setCurrentStep(nextStep);

      if (currentStepIndex + 1 === lastStepIndex) {
        setIsLastStep(true);
      }
      setIsFirstStep(false);
    }
  };

  const handleBack = () => {
    const currentStepIndex = formSteps.indexOf(currentStep);

    if (currentStepIndex > 0) {
      const previousStep = formSteps[currentStepIndex - 1];
      setCurrentStep(previousStep);

      if (currentStepIndex - 1 === 0) {
        setIsFirstStep(true);
      }

      setIsLastStep(false);
    }
  };

  const handleFirstStep = (setTouched) => {
    setTouched({
      positionName: true,
      vacancies: true,
      minSalary: true,
      maxSalary: true,
    }).then((errors) => {
      if (!isEmpty(errors)) {
        setHasErrors(true);
        toast.newToast('negative', t('toasts.form-error'));
      } else {
        handleNextStep();
      }
    });
  };

  const handleSubmit = async (values, { setStatus, setSubmitting }) => {
    setStatus('');

    try {
      const requestBody = {
        name: values.positionName,
        industry: values.industry.value,
        keywords: values.keywords,
        salary_min: values.minSalary,
        salary_max: values.maxSalary,
        description: description,
        tests: tests,
        questions: questions,
      };

      if (values.vacancies) {
        requestBody.n_vacancies = values.vacancies;
      }

      const response = await updateOffer({ offerSlug, requestBody }).unwrap();

      toast.newToast('positive', t('toasts.success'));

      navigate(`/offers/${response.slug}`);
    } catch (err) {
      switch (err?.status) {
        case 403:
          toast.newToast('negative', t('toasts.forbidden'));
          break;
        default:
          toast.newToast('negative', t('toasts.something-wrong'));
      }
    }

    setSubmitting(false);
  };

  if (isFetching) {
    return (
      <div className='flex flex-col h-full w-full max-w-full px-6 pt-6 relative'>
        <OfferDetailsSkeleton />
      </div>
    );
  }

  if (offerError) {
    const offerErrorMessage = offerError.data?.errors[0].detail;
    toast.newToast('negative', offerErrorMessage);
    navigate('/dashboard');
    return;
  }

  return (
    <div className='flex flex-col h-full w-full max-w-full px-6 pt-6 relative'>
      <div className='flex items-center justify-between mb-4'>
        <h1 className='text-3xl text-dark-liver font-semibold'>{offer.name}</h1>
        <div className='flex items-center gap-6'>
          <StepIndicator
            currentStep={formSteps.indexOf(currentStep) + 1}
            totalSteps={formSteps.length}
          />
        </div>
      </div>
      <Formik
        initialValues={getInitialValues(offer)}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ setTouched, errors, values, setStatus, setSubmitting }) => (
          <React.Fragment>
            <div className='flex items-center justify-between mb-2 z-10'>
              <ul className='flex items-center gap-4'>
                <li
                  className={cn('font-semibold select-none text-sonic-silver', {
                    'text-vibrant-orange decoration-2 underline underline-offset-[20px]':
                      currentStep === 'information',
                  })}
                >
                  {t('information')}
                </li>
                <li
                  className={cn('font-semibold select-none text-sonic-silver', {
                    'text-vibrant-orange decoration-2 underline underline-offset-[20px]':
                      currentStep === 'description',
                  })}
                >
                  {t('description')}
                </li>
                <li
                  className={cn('font-semibold select-none text-sonic-silver', {
                    'text-vibrant-orange decoration-2 underline underline-offset-[20px]':
                      currentStep === 'tests',
                  })}
                >
                  {t('tests')}
                </li>
                <li
                  className={cn('font-semibold select-none text-sonic-silver', {
                    'text-vibrant-orange decoration-2 underline underline-offset-[20px]':
                      currentStep === 'questions',
                  })}
                >
                  {t('questions')}
                </li>
              </ul>
              <div className='flex items-center gap-4'>
                {isFirstStep ? (
                  <Button
                    type='button'
                    variant='primary'
                    onClick={() => handleFirstStep(setTouched)}
                    disabled={
                      hasErrors || !isEmpty(errors) || disableNavigationButtons
                    }
                  >
                    {t('next')}
                    <Right size='16px' color='#FFFFFF' />
                  </Button>
                ) : !isLastStep ? (
                  <>
                    <Button
                      type='button'
                      variant='secondary'
                      onClick={handleBack}
                      disabled={disableNavigationButtons}
                    >
                      <Left size='16px' color='#8D91A0' />
                      {t('back')}
                    </Button>
                    <Button
                      type='button'
                      variant='primary'
                      onClick={handleNextStep}
                      disabled={disableNavigationButtons}
                    >
                      {t('next')}
                      <Right size='16px' color='#FFFFFF' />
                    </Button>
                  </>
                ) : (
                  <>
                    <Button
                      type='button'
                      variant='secondary'
                      onClick={handleBack}
                      disabled={disableNavigationButtons}
                    >
                      <Left size='16px' color='#8D91A0' />
                      {t('back')}
                    </Button>
                    <Button
                      type='button'
                      form='offer-form'
                      variant='primary'
                      onClick={() =>
                        handleSubmit(values, { setStatus, setSubmitting })
                      }
                      disabled={disableNavigationButtons}
                    >
                      {t('submit')}
                      <Right size='16px' color='#FFFFFF' />
                    </Button>
                  </>
                )}
              </div>
            </div>
            <Divider />
            <Form
              id='offer-form'
              className='flex w-full flex-grow gap-10 mt-6 relative'
              onChange={() => setHasErrors(false)}
            >
              {currentStep === 'information' && <InformationStep />}
              {currentStep === 'description' && (
                <DescriptionStep
                  label={t('job-description')}
                  placeholder={t('insert-job-description')}
                  availabIndustries={availableIndustries}
                  availableTones={availableTones}
                  description={description}
                  setDescription={setDescription}
                  setDisableNavigationButtons={setDisableNavigationButtons}
                  assistant_id={assistant_id}
                  setAssistantID={setAssistantID}
                />
              )}
              {currentStep === 'tests' && (
                <TestsStep
                  availabIndustries={availableIndustries}
                  description={description}
                  tests={tests}
                  setTests={setTests}
                  setDisableNavigationButtons={setDisableNavigationButtons}
                  assistant_id={assistant_id}
                  setAssistantID={setAssistantID}
                />
              )}
              {currentStep === 'questions' && (
                <QuestionsStep
                  description={description}
                  tests={tests}
                  questions={questions}
                  setQuestions={setQuestions}
                  availableIndustries={availableIndustries}
                  setDisableNavigationButtons={setDisableNavigationButtons}
                  assistant_id={assistant_id}
                  setAssistantID={setAssistantID}
                />
              )}
              {isLoading && <Loader />}
            </Form>
          </React.Fragment>
        )}
      </Formik>
    </div>
  );
};

export default OfferUpdate;
