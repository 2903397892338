import Check from 'assets/icons/Check';
import Close from 'assets/icons/Close';
import Danger from 'assets/icons/Danger';
import Error from 'assets/icons/Error';
import Info from 'assets/icons/Info';
import { useToast } from 'common/hooks/useToast';
import { cn } from 'common/utils';
import React, { useEffect, useRef } from 'react';

const toastTypes = {
  neutral: {
    icon: null,
    styles: {
      background: 'bg-dark-liver',
    },
  },
  informative: {
    icon: <Info size='16px' color='white' />,
    styles: {
      background: 'bg-info-blue',
    },
  },
  positive: {
    icon: <Check size='16px' color='white' />,
    styles: {
      background: 'bg-success-green',
    },
  },
  warning: {
    icon: <Danger size='16px' color='black' />,
    styles: {
      background: 'bg-warning-yellow',
    },
  },
  negative: {
    icon: <Error size='16px' color='white' />,
    styles: {
      background: 'bg-error-red',
    },
  },
};

const Toast = ({ id, type, message, timeout = 5000 }) => {
  const toast = useToast();
  const timerRef = useRef(null);
  const wrapperRef = useRef(null);

  const handleDismiss = () => {
    toast.closeToast(id, wrapperRef);
  };

  useEffect(() => {
    timerRef.current = setTimeout(() => {
      handleDismiss();
    }, timeout);

    return () => {
      clearTimeout(timerRef.current);
    };
  }, []);

  const {
    icon,
    styles: { background },
  } = toastTypes[type];

  return (
    <div
      key={id}
      ref={wrapperRef}
      className={cn(
        'flex justify-between items-center p-2.5 rounded shadow gap-2',
        'animate-toast-in z-50',
        background,
      )}
    >
      <div className='flex items-center gap-2'>
        {icon}
        <p
          className={cn({
            'text-white': type !== 'warning',
            'text-black': type === 'warning',
          })}
        >
          {message}
        </p>
      </div>
      <Close
        size='32px'
        color={type === 'warning' ? '#000000' : '#FFFFFF'}
        onClick={handleDismiss}
      />
    </div>
  );
};

export default Toast;
