import React from 'react';

const KanbanColumnSkeleton = () => {
  return (
    <div className='flex flex-1 flex-col min-w-[287px] bg-ghost-white rounded-md px-3 py-5'>
      <div className='h-5 w-28 bg-bright-grey animate-pulse rounded mb-4' />
      <div className='flex flex-col w-full gap-4'>
        <div className='h-[42px] w-full bg-bright-grey animate-pulse rounded-lg' />
        <div className='h-[42px] w-full bg-bright-grey animate-pulse rounded-lg' />
        <div className='h-[42px] w-full bg-bright-grey animate-pulse rounded-lg' />
        <div className='h-[42px] w-full bg-bright-grey animate-pulse rounded-lg' />
        <div className='h-[42px] w-full bg-bright-grey animate-pulse rounded-lg' />
        <div className='h-[42px] w-full bg-bright-grey animate-pulse rounded-lg' />
        <div className='h-[42px] w-full bg-bright-grey animate-pulse rounded-lg' />
      </div>
    </div>
  );
};

export default KanbanColumnSkeleton;
