import { configureStore } from '@reduxjs/toolkit';
import { apiSlice } from 'common/slices/api.slice';
import authReducer from 'common/slices/auth.slice';
import offerReducer from 'common/slices/offer.slice';
import toastReducer from 'common/slices/toast.slice';

const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    auth: authReducer,
    offer: offerReducer,
    toast: toastReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiSlice.middleware),
  devTools: process.env.NODE_ENV === 'development',
});

export default store;
