import { cn } from 'common/utils';
import React from 'react';

const Grid = ({ size = 24, color = '#8D91A0', onClick }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      fill='none'
      viewBox='0 0 24 24'
      className={cn({ 'cursor-pointer': onClick })}
      onClick={onClick}
    >
      <path
        d='M3 11.5H11V3.5H3M3 21.5H11V13.5H3M13 21.5H21V13.5H13M13 3.5V11.5H21V3.5'
        fill={color}
      />
    </svg>
  );
};

export default Grid;
