import { Listbox, Transition } from '@headlessui/react';
import Down from 'assets/icons/Down';
import Pause from 'assets/icons/Pause';
import Resume from 'assets/icons/Resume';
import Up from 'assets/icons/Up';
import Chip from 'common/components/Chip';
import useTranslation from 'common/hooks/useTranslation';
import { cn } from 'common/utils';
import React, { Fragment } from 'react';

const listboxStyles = {
  list: () =>
    cn(
      'mt-1 w-full rounded-md bg-white text-base shadow-lg',
      'ring-1 ring-black ring-opacity-5 focus:outline-none',
      'max-h-[180px] absolute cursor-pointer',
      'overflow-y-auto scrollbar scrollbar-w-1',
      'scrollbar-thumb-vibrant-orange scrollbar-thumb-rounded',
      'scrollbar-track-rounded z-50',
    ),
  option: ({ active }) =>
    cn(
      'flex w-full items-center',
      'px-4 py-2 text-sm gap-2.5',
      'select-none truncate text-black',
      {
        'bg-vibrant-orange bg-opacity-10': active,
      },
    ),
};

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const STATE = {
  DRAFT: 'DRAFT',
  IN_PROGRESS: 'IN_PROGRESS',
  DONE: 'DONE',
  CANCELED: 'CANCELED',
  PAUSED: 'PAUSED',
};

const TRANSITION = {
  START: 'start',
  RESUME: 'resume',
  CLOSE: 'close',
  PAUSE: 'pause',
  CANCEL: 'cancel',
};

// Map for transitions
const OfferStatusTransitions = [
  {
    id: TRANSITION.START,
    icon: <Resume size='24px' color='#084D6E' />,
    nextState: STATE.IN_PROGRESS,
  },
  {
    id: TRANSITION.RESUME,
    icon: <Resume size='24px' color='#084D6E' />,
    nextState: STATE.IN_PROGRESS,
  },
  {
    id: TRANSITION.CLOSE,
    icon: null,
    nextState: STATE.DONE,
  },
  {
    id: TRANSITION.PAUSE,
    icon: <Pause size='24px' color='#084D6E' />,
    nextState: STATE.PAUSED,
  },
  {
    id: TRANSITION.CANCEL,
    icon: null,
    nextState: STATE.CANCELED,
  },
];

const OfferStatusStates = [
  {
    id: STATE.DRAFT,
    transitions: [TRANSITION.START],
    cssVariant: 'grey-outlined',
  },
  {
    id: STATE.IN_PROGRESS,
    transitions: [TRANSITION.PAUSE, TRANSITION.CLOSE, TRANSITION.CANCEL],
    cssVariant: 'success-outlined',
  },
  {
    id: STATE.DONE,
    transitions: [TRANSITION.RESUME],
    cssVariant: 'secondary-outlined',
  },
  {
    id: STATE.CANCELED,
    transitions: [TRANSITION.RESUME],
    cssVariant: 'primary-outlined',
  },
  {
    id: STATE.PAUSED,
    transitions: [TRANSITION.RESUME],
    cssVariant: 'warning-outlined',
  },
];

const findStatusState = (statusId) =>
  OfferStatusStates.find(({ id }) => id === statusId);
const findStatusTransition = (transitionId) =>
  OfferStatusTransitions.find(({ id }) => id === transitionId);

const StatusCombo = ({ currentStatus, handleUpdateOfferStatus }) => {
  const { t } = useTranslation('offers\\details');

  const selectedStatusState = findStatusState(currentStatus);

  return (
    <div className='relative inline-block'>
      <div className='flex flex-col'>
        <Listbox value={currentStatus}>
          {({ open }) => (
            <>
              <div className='relative'>
                <Listbox.Button>
                  <Chip
                    className='uppercase px-4 py-1.5 tex-normal cursor-pointer'
                    variant={selectedStatusState?.cssVariant}
                  >
                    <p className='truncate'>
                      {t(
                        `statusNames.${selectedStatusState?.id.toLowerCase()}`,
                      )}
                    </p>
                    {open ? <Up /> : <Down />}
                  </Chip>
                </Listbox.Button>

                <Transition
                  show={open}
                  as={Fragment}
                  leave='transition ease-in duration-100'
                  leaveFrom='opacity-100'
                  leaveTo='opacity-0'
                >
                  <Listbox.Options className={listboxStyles.list()}>
                    {selectedStatusState?.transitions.map((transition) => {
                      const statusTransition = findStatusTransition(transition);
                      return (
                        <Listbox.Option
                          key={statusTransition.id}
                          className={listboxStyles.option}
                          value={statusTransition.id}
                          onClick={() =>
                            handleUpdateOfferStatus(statusTransition.nextState)
                          }
                        >
                          {({ selected, active }) => (
                            <>
                              <div className='flex items-center'>
                                {/*{statusTransition.icon}*/}
                                <span
                                  className={classNames(
                                    selected
                                      ? 'font-semibold text-dark-charcoal'
                                      : 'font-normal',
                                    'ml-3 block truncate',
                                  )}
                                >
                                  {t(
                                    `statusTransitions.${statusTransition.id.toLowerCase()}`,
                                  )}
                                </span>
                              </div>
                            </>
                          )}
                        </Listbox.Option>
                      );
                    })}
                  </Listbox.Options>
                </Transition>
              </div>
            </>
          )}
        </Listbox>
      </div>
    </div>
  );
};

export { StatusCombo, findStatusState, STATE };
