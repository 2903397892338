import useTranslation from 'common/hooks/useTranslation';
import { cn } from 'common/utils';
import React from 'react';

import { STATE as OFFER_STATUS } from '../details/components/StatusCombo';

export const OfferTab = ({ filter, setFilter }) => {
  const { t } = useTranslation('offers\\index');
  const offerTabItems = [
    {
      filterBy: '',
      name: t('tab-all'),
    },
    {
      filterBy: OFFER_STATUS.DRAFT,
      name: t(`tab-${OFFER_STATUS.DRAFT.toLowerCase()}`),
    },
    {
      filterBy: OFFER_STATUS.IN_PROGRESS,
      name: t(`tab-${OFFER_STATUS.IN_PROGRESS.toLowerCase()}`),
    },
    {
      filterBy: OFFER_STATUS.PAUSED,
      name: t(`tab-${OFFER_STATUS.PAUSED.toLowerCase()}`),
    },
    {
      filterBy: OFFER_STATUS.DONE,
      name: t(`tab-${OFFER_STATUS.DONE.toLowerCase()}`),
    },
    {
      filterBy: OFFER_STATUS.CANCELED,
      name: t(`tab-${OFFER_STATUS.CANCELED.toLowerCase()}`),
    },
  ];

  return (
    <ul className='flex items-center gap-4'>
      {offerTabItems.map(({ filterBy, name }) => (
        <li
          key={name}
          className={cn(
            'text-cool-grey font-semibold cursor-pointer decoration-2',
            {
              'text-vibrant-orange underline underline-offset-[20px]':
                filter === filterBy,
            },
          )}
          onClick={() => setFilter(filterBy)}
        >
          {name}
        </li>
      ))}
    </ul>
  );
};
