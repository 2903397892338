import React from 'react';

const LeftHand = () => (
  <svg
    id='left-hand'
    width='450'
    height='517'
    viewBox='0 0 450 517'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className='bottom-0 left-0 absolute'
  >
    <path
      d='M443.614 257.221C444.222 256.547 445.287 256.916 445.397 257.73C447.173 270.862 448.09 284.267 448.09 297.886C448.09 461.811 315.202 594.698 151.278 594.698C-12.647 594.698 -145.535 461.811 -145.535 297.886C-145.535 133.961 -12.647 1.07422 151.278 1.07422C189.275 1.07422 231.504 9.7254 265.406 22.8711C266.237 23.1935 266.259 24.3325 265.453 24.6899C199.024 54.1485 128.082 111.267 89.4553 176.814C70.1365 209.597 58.8697 244.544 60.3548 279.226C61.841 313.932 76.0936 348.281 107.647 379.834C166.64 438.828 262.288 438.828 321.281 379.834L438.228 262.887C440.082 261.034 441.877 259.144 443.614 257.221Z'
      stroke='#F0DC29'
      strokeWidth='2'
      strokeLinejoin='round'
    />
  </svg>
);

export default LeftHand;
