import EditProcess from 'assets/icons/EditProcess';
import useTranslation from 'common/hooks/useTranslation';
import React from 'react';

const StepIndicator = ({ currentStep, totalSteps }) => {
  const { t } = useTranslation('common\\step-indicator');

  return (
    <div className='flex items-center gap-1.5'>
      <EditProcess size='20px' color='#7A7A7A' />
      <p className='text-sm text-sonic-silver'>
        {currentStep}/{totalSteps} {t('steps')}
      </p>
    </div>
  );
};

export default StepIndicator;
