import Avatar from 'common/components/Avatar';
import { cn } from 'common/utils';
import React from 'react';

export const CompanyCard = ({
  option,
  isSelected,
  handleOptionClick,
  isWithAvatar,
}) => {
  return (
    <div
      key={option.value}
      className={cn('px-2 py-4', {
        'bg-vibrant-orange bg-opacity-10 select-none': isSelected,
        'hover:bg-ghost-white cursor-pointer': !isSelected,
      })}
      onClick={() => handleOptionClick(option.value)}
    >
      <div className='flex items-center gap-2.5'>
        {isWithAvatar && <Avatar size='small' name={option.label} />}
        <div className='flex flex-col justify-center max-w-[215px]'>
          <p className='truncate'>{option.label}</p>
          <p className='text-xs text-sonic-silver truncate'>
            {option.location}
          </p>
        </div>
      </div>
    </div>
  );
};
