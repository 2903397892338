import { Disclosure } from '@headlessui/react';
import Down from 'assets/icons/Down';
import Trash from 'assets/icons/Trash';
import Up from 'assets/icons/Up';
import useTranslation from 'common/hooks/useTranslation';
import React from 'react';

export const OfferTestItem = ({
  name,
  description,
  isRemovable,
  onDeleteClick,
}) => {
  const { t } = useTranslation('offers\\index');
  return (
    <Disclosure defaultOpen={true}>
      {({ open }) => (
        <div className='py-2'>
          <Disclosure.Button className='flex w-full text-left'>
            <span className='w-full text-sm font-semibold'>{name}</span>
            {open ? <Up /> : <Down />}
            {isRemovable && (
              <span onClick={onDeleteClick}>
                <Trash />
              </span>
            )}
          </Disclosure.Button>
          <Disclosure.Panel className='text-xs font-normal pr-4'>
            <div className='flex flex-row'>
              <p className='w-full mr-auto pr-2'>
                {description ? description : t('no-description')}
              </p>
            </div>
          </Disclosure.Panel>
        </div>
      )}
    </Disclosure>
  );
};
