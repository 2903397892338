import Danger from 'assets/icons/Danger';
import Redo from 'assets/icons/Redo';
import Right from 'assets/icons/Right';
import Button from 'common/components/Button';
import Card from 'common/components/Card';
import LabelledInput from 'common/components/LabelledInput';
import Loader from 'common/components/Loader';
import useNavigate from 'common/hooks/useNavigate';
import useTranslation from 'common/hooks/useTranslation';
import { setCredentials } from 'common/slices/auth.slice';
import {
  useForgotPasswordMutation,
  useLoginMutation,
  useResetPasswordMutation,
} from 'common/slices/authApi.slice';
import { cn } from 'common/utils';
import { Form, Formik } from 'formik';
import React from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';

const NewPasswordForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { t } = useTranslation('auth\\new-password-form');
  const email = JSON.parse(localStorage.getItem('currentUserEmail'));

  const initialValues = {
    resetCode: '',
    newPassword: '',
    repeatPassword: '',
  };

  const validationSchema = Yup.object({
    resetCode: Yup.string()
      .required(t('status.reset-code-val'))
      .matches(/^[A-Za-z0-9]{6}$/, t('status.reset-code-type-val')),
    newPassword: Yup.string().required(t('status.password-required')),
    repeatPassword: Yup.string()
      .required(t('status.password-confirm'))
      .oneOf([Yup.ref('newPassword'), null], t('status.password-match')),
  });

  const [login, { isLoading: isLoggingIn }] = useLoginMutation();
  const [resetPassword, { isLoading: isResetingPassword }] =
    useResetPasswordMutation();
  const [forgotPassword] = useForgotPasswordMutation();

  const isLoading = isLoggingIn || isResetingPassword;

  const handleClick = async (setStatus) => {
    try {
      await forgotPassword(email).unwrap();
    } catch (error) {
      switch (error?.status) {
        default:
          setStatus({ error: t('status.something-wrong') });
      }
    }
  };

  const handleSubmit = async (values, { setStatus, setSubmitting }) => {
    setStatus('');
    try {
      const { newPassword, resetCode } = values;

      const loginData = new FormData();
      const email = JSON.parse(localStorage.getItem('currentUserEmail'));

      await resetPassword({ resetCode, password: newPassword })
        .unwrap()
        .then(async () => {
          loginData.append('username', email);
          loginData.append('password', newPassword);
          const response = await login(loginData).unwrap();

          dispatch(setCredentials({ ...response }));
          navigate('/dashboard');
        });
    } catch (error) {
      switch (error?.status) {
        default:
          setStatus({ error: t('status.something-wrong') });
      }
    }

    setSubmitting(false);
  };

  return (
    <Card shadowed>
      <div className='bg-vibrant-yellow rounded-t-lg h-2 w-full' />
      <div className='flex flex-col justify-center pt-10 md:px-[72px] px-5 pb-12 w-full relative'>
        <div className='w-[300px]'>
          <h1 className='text-center font-semibold text-3xl text-dark-blue pb-6'>
            {t('password-assistance-title')}
          </h1>
          <div className='flex flex-col gap-6'>
            <p className='text-dark-charcoal text-sm'>
              {t('password-assistance-subtitle')}
            </p>
            <div className='mb-6'>
              <p className='text-dark-charcoal text-sm font-semibold'>
                {t('password-requirements')}
              </p>
              <ul className='list-disc ml-6'>
                <li className='text-dark-charcoal text-sm'>
                  {t('password-long-requirements')}
                </li>
                <li className='text-dark-charcoal text-sm'>
                  {t('password-include-requirements')}
                </li>
              </ul>
            </div>
          </div>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ status, setStatus }) => (
              <Form className='flex flex-col gap-4 w-full'>
                <LabelledInput
                  type='text'
                  label={t('enter-your-reset-code')}
                  name='resetCode'
                  placeholder={t('code-placeholder')}
                />
                <div className='flex flex-row gap-2 items-center justify-center'>
                  <span
                    className='text-dark-blue text-xs font-semibold uppercase underline cursor-pointer'
                    onClick={() => handleClick(setStatus)}
                  >
                    {t('resend-code')}
                  </span>
                  <Redo size='16px' color='#084D6E' />
                </div>
                <LabelledInput
                  type='password'
                  label={t('new-password')}
                  name='newPassword'
                  placeholder={t('password-placeholder')}
                />
                <LabelledInput
                  type='password'
                  label={t('repeat-password')}
                  name='repeatPassword'
                  placeholder={t('password-placeholder')}
                />
                {status?.error && (
                  <div
                    className={cn(
                      'text-bittersweet-shimmer text-xs font-semibold',
                      'flex justify-start items-center gap-1',
                    )}
                  >
                    <Danger size='14px' color='#C2514B' />
                    {status.error}
                  </div>
                )}
                <Button variant='primary'>
                  {t('next')}
                  <Right size='16px' color='#FFFFFF' />
                </Button>
              </Form>
            )}
          </Formik>
          <div className='flex flex-row mt-4 items-center justify-center'>
            <span
              className='text-dark-blue text-xs font-semibold uppercase underline cursor-pointer'
              onClick={() => navigate('/login')}
            >
              {t('return-login-page')}
            </span>
          </div>
        </div>
        {isLoading && <Loader />}
      </div>
    </Card>
  );
};

export default NewPasswordForm;
