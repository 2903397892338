import Down from 'assets/icons/Down';
import Up from 'assets/icons/Up';
import useTranslation from 'common/hooks/useTranslation';
import { isEmpty } from 'lodash';
import React, { useState } from 'react';

import LocationPieChart from './charts/LocationPieChart';
import StackedPhasesBarChart from './charts/StackedPhasesBarChart';
import TestResultsDonutCharts from './charts/TestResultsDonutCharts';

const OfferAnalysis = ({
  analytics: { phases, location, tests_results, age, gender },
}) => {
  const [isOpen, setOpen] = useState(true);

  const { t } = useTranslation('offers\\details');

  const handleOpen = () => {
    setOpen((prev) => !prev);
  };

  const areAllValueZero = (data) => {
    return Object.values(data).every((value) => value === 0);
  };

  const showPhases = !isEmpty(phases) && !areAllValueZero(phases);
  const showTestResults = !isEmpty(tests_results);
  const showLocation = !isEmpty(location) && !areAllValueZero(location);

  const showEmpty = !showPhases && !showTestResults && !showLocation;

  return (
    <>
      {showEmpty ? null : isOpen ? (
        <div className='flex justify-between w-full mb-5 items-center rounded shadow'>
          <div className='flex w-full items-center justify-start my-4 divide-x'>
            {showPhases && <StackedPhasesBarChart phases={phases} />}
            {showTestResults && (
              <TestResultsDonutCharts testResults={tests_results} />
            )}
            {showLocation && <LocationPieChart location={location} />}
            {/* {!isEmpty(age) && <AgePieChart age={age} />}
              {!isEmpty(gender) && <GenderDonutChart gender={gender} />} */}
          </div>
          <div className='mx-4'>
            <Up onClick={handleOpen} />
          </div>
        </div>
      ) : (
        <div className='flex w-full items-center justify-between mb-5 rounded shadow px-4'>
          <div className='flex w-full py-2 items-center uppercase text-sm text-dark-liver font-bold'>
            {t('offer-analysis')}
          </div>
          <Down onClick={handleOpen} />
        </div>
      )}
    </>
  );
};

export default OfferAnalysis;
