import { Logo } from 'assets/logos';
import ProfileDropdown from 'common/components/layout/components/ProfileDropdown';
import { cn } from 'common/utils';
import React from 'react';

import Breadcrumbs from './Breadcrumbs';

const Header = ({ firstCrumb, secondCrumb }) => {
  return (
    <div
      className={cn(
        'flex justify-between items-center',
        'min-h-[74px] w-full bg-white shadow',
        'px-8 z-10',
      )}
    >
      <div className='flex flex-row'>
        <Logo />
        <Breadcrumbs firstCrumb={firstCrumb} secondCrumb={secondCrumb} />
      </div>

      <ProfileDropdown />
    </div>
  );
};

export default Header;
