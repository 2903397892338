import Hide from 'assets/icons/Hide';
import Show from 'assets/icons/Show';
import { cn } from 'common/utils';
import { useField } from 'formik';
import React, { useState } from 'react';

const LabelledInput = ({ label, type, height, ...props }) => {
  const [field, meta] = useField(props);
  const [showPassword, setShowPassword] = useState(false);

  const hasError = meta.touched && meta.error;

  const toggleHidePassword = () => {
    setShowPassword((prev) => !prev);
  };

  return (
    <div className='flex flex-col gap-2'>
      <label
        htmlFor={props.id || props.name}
        className={cn('text-xs font-semibold uppercase', {
          'text-weldon-blue': !hasError,
          'text-bittersweet-shimmer': hasError,
        })}
      >
        {label}
      </label>
      <div className='flex items-center relative'>
        {type === 'textarea' ? (
          <textarea
            className={cn(
              'text-input text-dark-blue border rounded-md px-4 py-2 w-full',
              height,
              {
                'border-dark-blue outline-dark-blue': !hasError,
                'border-bittersweet-shimmer outline-bittersweet-shimmer placeholder-bittersweet-shimmer':
                  hasError,
              },
            )}
            rows={5}
            {...field}
            {...props}
          />
        ) : (
          <>
            <input
              className={cn(
                'text-input text-dark-blue border rounded-md px-4 py-2 w-full',
                height,
                'disabled:border-soft-blue disabled:text-soft-blue disabled:bg-white disabled:cursor-not-allowed',
                {
                  'border-dark-blue outline-dark-blue': !hasError,
                  'border-bittersweet-shimmer outline-bittersweet-shimmer placeholder-bittersweet-shimmer':
                    hasError,
                },
              )}
              type={showPassword ? 'text' : type}
              {...field}
              {...props}
            />
            {type === 'password' && (
              <div
                className='absolute right-4 cursor-pointer z-2'
                onClick={toggleHidePassword}
              >
                {showPassword ? (
                  <Hide color={hasError ? '#C2514B' : '#084D6E'} />
                ) : (
                  <Show color={hasError ? '#C2514B' : '#084D6E'} />
                )}
              </div>
            )}
          </>
        )}
      </div>
      {hasError ? (
        <div className='error text-bittersweet-shimmer text-xs font-semibold'>
          {meta.error}
        </div>
      ) : null}
    </div>
  );
};

export default LabelledInput;
