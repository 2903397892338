import useTranslation from 'common/hooks/useTranslation';
import { selectCurrentCompany } from 'common/slices/auth.slice';
import { useGetCompanyDetailQuery } from 'common/slices/companyApi.slice';
import React from 'react';
import { useSelector } from 'react-redux';

import TeamTableItem from './TeamTableItem';
import { TeamTableSkeleton } from './TeamTableSkeleton';

const TeamTable = () => {
  const { t } = useTranslation('settings\\index');

  const currentCompany = useSelector(selectCurrentCompany);

  const { data: company, isLoading } = useGetCompanyDetailQuery({
    slug: currentCompany.slug,
  });

  return (
    <div className='w-full h-full relative mt-6'>
      {isLoading ? (
        <TeamTableSkeleton />
      ) : (
        <div className='w-full pb-6'>
          <table className='w-full table-auto'>
            <thead className='text-sm text-weldon-blue font-semibold uppercase border-b border-bright-grey'>
              <tr>
                <th className='pb-5 whitespace-nowrap min-w-fit'>
                  <div className='font-semibold text-left'>{t('name')}</div>
                </th>
                <th className='pb-5 whitespace-nowrap min-w-fit'>
                  <div className='font-semibold text-left'>{t('role')}</div>
                </th>
                <th className='pb-5 whitespace-nowrap min-w-fit'>
                  <div className='font-semibold text-left'>{t('email')}</div>
                </th>
              </tr>
            </thead>
            <tbody>
              {company?.managers?.map((manager) => (
                <TeamTableItem
                  key={manager.id}
                  name={manager.full_name}
                  role={manager.role}
                  email={manager.email}
                />
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default TeamTable;
