import Import from 'assets/icons/Import';
import useTranslation from 'common/hooks/useTranslation';
import { cn } from 'common/utils';
import React, { useRef, useState } from 'react';

const MAX_FILE_SIZE = 5242880;

const DragAndDrop = ({ file, setFile, setCanImport }) => {
  const fileInputRef = useRef(null);
  const [error, setError] = useState('');

  const { t } = useTranslation('offers\\details');

  const handleClick = () => {
    fileInputRef.current.click();
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setError('');

    const dropFile = event.dataTransfer.files[0];
    const splitFilename = dropFile.name.split('.');
    const fileType = splitFilename[splitFilename.length - 1];

    if (dropFile.size > MAX_FILE_SIZE) {
      setError(t('modals.import.errors.file-too-big'));
      setCanImport(false);
    } else if (fileType !== 'xlsx') {
      setError(t('modals.import.errors.wrong-file-type'));
      setCanImport(false);
    } else {
      setCanImport(true);
    }

    setFile(dropFile);
  };

  const handleInputFile = (event) => {
    setError('');

    const dropFile = event.target.files[0];
    const splitFilename = dropFile.name.split('.');
    const fileType = splitFilename[splitFilename.length - 1];

    if (dropFile.size > MAX_FILE_SIZE) {
      setError(t('modals.import.errors.file-too-big'));
      setCanImport(false);
    } else if (fileType !== 'xlsx') {
      setError(t('modals.import.errors.wrong-file-type'));
      setCanImport(false);
    } else {
      setCanImport(true);
    }

    setFile(dropFile);
  };

  return (
    <div
      className={cn(
        'border-dashed border rounded-md h-40 gap-2.5',
        'flex flex-col justify-center items-center',
        'cursor-pointer p-6',
        { 'border-error-red': error },
      )}
      onClick={handleClick}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
    >
      {file ? (
        <>
          <p
            className={cn('text-xs font-semibold', {
              'text-cool-grey': !error,
              'text-error-red': error,
            })}
          >
            {t('modals.import.selected-file')}
          </p>
          <p
            className={cn('text-xs font-semibold truncate', {
              'text-cool-grey': !error,
              'text-error-red': error,
            })}
          >
            {file.name}
          </p>
          {error && (
            <p className='text-xs font-semibold text-error-red'>{error}</p>
          )}
        </>
      ) : (
        <>
          <Import size='24px' color='#8D91A0' />
          <p className='text-sm font-semibold text-cool-grey'>
            {t('modals.import.placeholder')}
          </p>
          <p className='text-xs text-cool-grey'>
            {t('modals.import.valid-formats')}
          </p>
        </>
      )}
      <input
        type='file'
        hidden={true}
        ref={fileInputRef}
        onChange={handleInputFile}
      />
    </div>
  );
};

export default DragAndDrop;
