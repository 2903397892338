import { cn } from 'common/utils';
import React from 'react';

const More = ({ size = 24, color = '#8D91A0', onClick }) => {
  return (
    <div className={cn({ 'cursor-pointer': onClick })} onClick={onClick}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width={size}
        height={size}
        viewBox='0 0 24 24'
        fill='none'
      >
        <g clipPath='url(#clip0_2599_47465)'>
          <path d='M24 0H0V24H24V0Z' fill='white' fillOpacity='0.01' />
          <path
            d='M12 7.5C12.8284 7.5 13.5 6.82843 13.5 6C13.5 5.17157 12.8284 4.5 12 4.5C11.1716 4.5 10.5 5.17157 10.5 6C10.5 6.82843 11.1716 7.5 12 7.5Z'
            fill={color}
          />
          <path
            d='M12 13.5C12.8284 13.5 13.5 12.8284 13.5 12C13.5 11.1716 12.8284 10.5 12 10.5C11.1716 10.5 10.5 11.1716 10.5 12C10.5 12.8284 11.1716 13.5 12 13.5Z'
            fill={color}
          />
          <path
            d='M12 19C12.8284 19 13.5 18.3284 13.5 17.5C13.5 16.6716 12.8284 16 12 16C11.1716 16 10.5 16.6716 10.5 17.5C10.5 18.3284 11.1716 19 12 19Z'
            fill={color}
          />
        </g>
        <defs>
          <clipPath id='clip0_2599_47465'>
            <rect width='24' height='24' fill='white' />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default More;
