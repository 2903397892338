import { cn } from 'common/utils';
import React from 'react';

const Close = ({ size = 24, color = '#8D91A0', onClick }) => {
  return (
    <svg
      className={cn({ 'cursor-pointer': onClick })}
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 32 32'
      fill='none'
      onClick={onClick}
    >
      <path
        d='M17.3432 16L20.7397 12.6035C21.1102 12.2325 21.1098 11.6313 20.7387 11.2608C20.3676 10.8902 19.7664 10.8907 19.3959 11.2617L16.0006 14.6574L12.6049 11.2617C12.2344 10.8909 11.6334 10.8907 11.2626 11.2613C10.8918 11.6318 10.8916 12.2327 11.2621 12.6035L14.6582 16L11.2621 19.3965C10.8916 19.7673 10.8918 20.3683 11.2626 20.7388C11.6334 21.1093 12.2344 21.1091 12.6049 20.7383L16.0006 17.3426L19.3959 20.7383C19.7664 21.1094 20.3676 21.1098 20.7387 20.7393C21.1098 20.3688 21.1102 19.7676 20.7397 19.3965L17.3432 16Z'
        fill={color}
      />
    </svg>
  );
};

export default Close;
