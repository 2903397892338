import LabelledInput from 'common/components/LabelledInput';
import useTranslation from 'common/hooks/useTranslation';
import React from 'react';

export const CompanyDescriptionStep = () => {
  const { t } = useTranslation('onboarding\\index');

  return (
    <div className='w-full mt-1.5 gap-6 flex flex-col'>
      <LabelledInput
        name='about_us'
        label={t('aboutUs')}
        type='textarea'
        height='h-16'
      />
      <LabelledInput
        name='mission'
        label={t('mission')}
        type='textarea'
        height='h-16'
      />
      <LabelledInput
        name='bio'
        label={t('bio')}
        type='textarea'
        height='h-16'
      />
    </div>
  );
};
