import { PlaceholderEmptyState } from 'common/components/PlaceholderEmptyState';
import useTranslation from 'common/hooks/useTranslation';
import React from 'react';

import { STATE as OFFER_STATUS } from '../details/components/StatusCombo';

export const OffersTableEmptyState = ({ currentTab }) => {
  const { t } = useTranslation('offers\\index');

  switch (currentTab) {
    case OFFER_STATUS.DRAFT:
      return (
        <PlaceholderEmptyState
          text={t(`empty-state-${OFFER_STATUS.DRAFT.toLowerCase()}`)}
        />
      );
    case OFFER_STATUS.IN_PROGRESS:
      return (
        <PlaceholderEmptyState
          text={t(`empty-state-${OFFER_STATUS.IN_PROGRESS.toLowerCase()}`)}
        />
      );
    case OFFER_STATUS.PAUSED:
      return (
        <PlaceholderEmptyState
          text={t(`empty-state-${OFFER_STATUS.PAUSED.toLowerCase()}`)}
        />
      );
    case OFFER_STATUS.DONE:
      return (
        <PlaceholderEmptyState
          text={t(`empty-state-${OFFER_STATUS.DONE.toLowerCase()}`)}
        />
      );
    case OFFER_STATUS.CANCELED:
      return (
        <PlaceholderEmptyState
          text={t(`empty-state-${OFFER_STATUS.CANCELED.toLowerCase()}`)}
        />
      );
    default:
      return (
        <PlaceholderEmptyState
          text={t(`empty-state-${OFFER_STATUS.DONE.toLowerCase()}`)}
        />
      );
  }
};
