import { cn } from 'common/utils';
import React from 'react';

const Clock = ({ size = 24, color = '#8D91A0', onClick }) => {
  return (
    <svg
      className={cn({ 'cursor-pointer': onClick })}
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 12 12'
      fill='none'
    >
      <path
        d='M6 1.75757C3.25 1.75757 1 4.00757 1 6.75757C1 9.50757 3.25 11.7576 6 11.7576C8.75 11.7576 11 9.50757 11 6.75757C11 4.00757 8.75 1.75757 6 1.75757ZM6.25 7.25757H3.5V6.50757H5.5V4.25757H6.25V7.25757Z'
        fill={color}
      />
    </svg>
  );
};

export default Clock;
