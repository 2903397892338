import React from 'react';

const FileEditingEmpty = ({ size = 24, color = '#ACACAC' }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
    >
      <path
        d='M20 11.5V7L15.5 2H5C4.44771 2 4 2.44771 4 3V21C4 21.5523 4.44771 22 5 22H11'
        stroke={color}
        strokeWidth='1.2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M16 22L21 17L19 15L14 20V22H16Z'
        stroke={color}
        strokeWidth='1.2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M15 2V7H20'
        stroke={color}
        strokeWidth='1.2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M1.49799 22.5L22.5018 1.5'
        stroke={color}
        strokeWidth='1.2'
        strokeLinecap='round'
      />
    </svg>
  );
};

export default FileEditingEmpty;
