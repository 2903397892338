import { cn } from 'common/utils';
import React from 'react';

const EditProcess = ({ size = 24, color = '#8D91A0', onClick }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 21 20'
      fill='none'
      className={cn({ 'cursor-pointer': onClick })}
      onClick={onClick}
    >
      <path
        d='M13.3608 8.49984L12.5191 9.3415L10.7941 7.64984L11.6358 6.79984C11.8025 6.62484 12.0858 6.6165 12.2858 6.79984L13.3608 7.8415C13.5358 8.0165 13.5441 8.29984 13.3608 8.49984ZM6.82747 11.5915L10.3025 8.09984L12.0275 9.83317L8.56081 13.3332H6.82747V11.5915ZM10.9941 1.6665V3.33317C14.6525 3.78317 17.2441 7.10817 16.7941 10.7665C16.4108 13.7998 14.0275 16.1915 10.9941 16.5665V18.2332C15.5775 17.7332 18.8691 13.6165 18.3691 9.0415C17.9358 5.15817 14.8775 2.08317 10.9941 1.6665ZM9.32747 1.6665C7.69414 1.8165 6.15247 2.45817 4.88581 3.49984L6.07747 4.78317C7.01081 4.03317 8.13581 3.54984 9.32747 3.38317V1.7165M3.71081 4.72484C2.66081 5.9915 2.02747 7.53317 1.86914 9.1665H3.53581C3.69414 7.98317 4.16081 6.85817 4.90247 5.9165L3.71081 4.72484ZM1.87747 10.8332C2.04414 12.4665 2.68581 14.0082 3.71914 15.2748L4.90247 14.0832C4.16914 13.1415 3.69414 12.0165 3.54414 10.8332H1.87747ZM6.04414 15.3082L4.88581 16.4498C6.14414 17.4998 7.69414 18.1582 9.32747 18.3332V16.6665C8.14414 16.5165 7.01914 16.0415 6.07747 15.3082H6.04414Z'
        fill={color}
      />
    </svg>
  );
};

export default EditProcess;
